<div class="mid-con" #mydiv>
  <div class="pro-detail">
    <div class="tab-pane fade show active">
      <div class="pro-right-con">
        <div class="row">
          <div class="col-12 wbaHeadding">
            <div class="hd-width">
              <h2>Submission View</h2>
              <a class="email-btn me-4" (click)="openeEmailTemplateModal()">
                <img src="assets/images/email-s.png" alt="Submissions Email" />Submissions Email
              </a>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12">
            <div class="submissionOuter">
              <div class="submissionLeft">

                <div class="subRow">
                  <h3 class="font-weight-bold">Effective Date</h3>
                  <p *ngIf="wbaDetails?.effectiveDate">{{wbaDetails?.effectiveDate | date:'MM/dd/YYYY'}}</p>
                  <p *ngIf="!wbaDetails?.effectiveDate">{{'N/A'}}</p>
                </div>

                <div class="subRow">
                  <h3 class="font-weight-bold">Line of Business</h3>
                  <p>{{wbaDetails?.masterBusinessLine ? wbaDetails?.masterBusinessLine : 'N/A'}}</p>
                </div>

                <div class="subRow">
                  <h3 class="font-weight-bold">Target Premium</h3>
                  <p>{{wbaDetails?.targetPremium | currency : 'USD' : '$ ' :'1.0-0'}}</p>
                </div>

                <div class="subRow">
                  <h3 class="font-weight-bold">Priority</h3>
                  <p>{{wbaDetails?.masterWBAPriorityName ? wbaDetails?.masterWBAPriorityName : 'N/A'}}</p>
                </div>

                <div class="subRow">
                  <h3 class="font-weight-bold">Expiring Carrier</h3>
                  <p>{{wbaDetails?.expiringName ? wbaDetails?.expiringName : 'N/A'}}</p>
                </div>
                <div class="subRow">
                  <h3 class="font-weight-bold">Industry</h3>
                  <p>{{industry ? industry : 'N/A'}}</p>
                </div>



              </div>
              <div class="submissionRight">
                <h2>{{wbaDetails?.companyName}}</h2>

                <div class="subRightRow">
                  <h3 class="font-weight-bold">Operation Description</h3>
                  <p [ngStyle]="{'white-space': 'pre-line'}">{{policyDetails?.operationDescription ?
                    policyDetails?.operationDescription : 'N/A'}}</p>
                </div>

                <div class="subRightRow">
                  <h3 class="font-weight-bold">Internal Notes</h3>
                  <p [ngStyle]="{'white-space': 'pre-line'}">{{policyDetails?.internalNotes ?
                    policyDetails?.internalNotes : 'N/A'}}</p>
                </div>

                <div class="subRightRow">
                  <h3 class="font-weight-bold">Property Limits</h3>
                  <ng-container *ngIf="propertyLimitsParams?.length, else no_prp_data">
                    <table class="table wba-tbl">
                      <thead>
                        <tr>
                          <th width="15%">Limits</th>
                          <th width="15%">Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of propertyLimitsParams">
                          <td>{{item?.parameterName}}</td>
                          <td>{{item?.expiringValue}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-container>
                  <ng-template #no_prp_data>
                    <span>N/A</span>
                  </ng-template>
                </div>

                <div class="subRightRow">
                  <h3 class="font-weight-bold">GL Rating Basis</h3>
                  <ng-container *ngIf="glCovgData?.ratingBasis?.length, else no_gl_data">
                    <table class="table wba-tbl">
                      <thead>
                        <tr>
                          <th width="15%">Exposure Type</th>
                          <th width="15%">Value</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of glCovgData?.ratingBasis">
                          <td>{{item?.exposureType}}</td>
                          <td>{{item?.value}}</td>
                          <td>{{item?.description}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-container>
                  <ng-template #no_gl_data>
                    <span>N/A</span>
                  </ng-template>
                </div>

                <div class="subRightRow">
                  <h3 class="font-weight-bold">Loss Summary</h3>
                  <ng-container *ngIf="policyDetails?.policyTermDetails?.length, else no_loss_run_data">
                    <table class="table wba-tbl">
                      <thead>
                        <tr>
                          <th width="15%">Policy Term</th>
                          <th width="15%">Line of Business</th>
                          <th width="15%">Total Incurred</th>
                          <th>Carrier</th>
                          <th>Valuation Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of policyDetails?.policyTermDetails">
                          <td>{{item?.policyTerm}}</td>
                          <td>{{item?.businessLineName}}</td>
                          <td>{{item?.totalIncured | currency : 'USD' : '$ ' : '1.0-0'}}</td>
                          <td>{{item?.carrierName}}</td>
                          <td>{{item?.valuationDate}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-container>
                  <ng-template #no_loss_run_data>
                    <span>N/A</span>
                  </ng-template>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
