<div class="mid-con">
  <div class="no-round-border">
    <div class="tab-content py-0">
      <div class="tab-pane fade show active">
        <div class="pro-right-con">
          <div class="row">
            <div class="col-12 wbaHeadding">
              <div class="hd-width">
                <h2>Coverage Assessment</h2>
                <a class="email-btn mr-4" *ngIf="isRenewalStatusVisible =='RN'" (click)="showRenewTab()">
                  <img src="assets/images/sync.png" alt="Sync data from EPIC" />Sync data from EPIC
                </a>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12">
              <div class="min-ht-comp" id="assessment-table">
                <div class="card-text" *ngIf="CoverageCheckList.length">
                  <div class="row">
                    <div class="col-12 assessment-block">
                      <div class="flex-div space-between mb-3 hd-p">
                        <div class="flex-div " *ngIf="expiringCarrier!='' && expiringPremium >'0'">
                          <span class="green_lbl pos_lbl" *ngIf="expiringCarrier!=''">
                            <img src="../assets/images/calendar-exclamation.png" alt="Expiring Carrier" width="14px" />
                            <span class="ms-1">Expiring Carrier: {{expiringCarrier}}</span>
                          </span>
                          <span class="orange_lbl pos_lbl ms-4" *ngIf="expiringPremium >'0'">
                            <img src="../assets/images/payroll-calendar.png" alt="Expiring Premium" width="14px" />
                            <span class="ms-1">Expiring Premium: $ {{expiringPremium }}</span>
                          </span>
                        </div>
                        <div *ngIf="wbaDetails?.transType == 'RN'" class="expdv1 epicLine">
                          <label class="me-2">Lines in EPIC</label>
                          <select class="select-cs form-select">

                            <option [ngValue]="null" disabled selected>
                              Lines in EPIC
                            </option>
                            <option *ngFor="let line of lines" [ngValue]="line.id">
                              {{ line.lineTypeDescription }}
                            </option>
                          </select>

                        </div>
                      </div>

                      <!-- <div class="flex-div align-items-start mb-3">
                        <div class="expdv expwd" *ngIf="expiringCarrier!=''">
                          <p><b>Expiring Carrier:</b> {{expiringCarrier}}</p>
                        </div>
                        <div class="expdv" *ngIf="expiringPremium >'0'">
                          <p><b>Expiring Premium:</b>$ {{expiringPremium
                            }}</p>
                        </div>
                      </div> -->
                      <div class="pro-content">
                        <div class="row me-2" id="viewinfo1">
                          <div class="pt-0 pl-0 mx-3">
                            <ul class="nav nav-tabs" id="myTab-bot" role="tablist">
                              <li *ngFor="let item of CoverageCheckList;let i = index" class="nav-item"
                                role="presentation">
                                <button class="nav-link cov-button" (click)="parentTabChange(item)" [ngClass]="
                                  currentParentTab.displayName === item.displayName
                                    ? 'active'
                                    : ''
                                " id="{{ i + '-tab' }}" data-bs-toggle="tab" type="button" role="tab"
                                  aria-selected="true">
                                  <span>
                                    {{ item.displayName }}
                                  </span>
                                  <span class="alertImage" (click)="parentTabChange(item)"><img
                                      [src]="!item.showError ? 'assets/images/success.svg' : 'assets/images/error3.svg'"
                                      alt="" /></span>
                                </button>
                              </li>
                              <!-- <li class="nav-item" role="presentation">
                                <button class="nav-link" data-bs-toggle="tab" type="button" role="tab"
                                  aria-selected="true" (click)="showServiceTab()">Services</button>

                              </li> -->
                            </ul>

                            <div class="sub-tab-content p-0">
                              <div class="sub-tab-content-inner">
                                <ng-container *ngIf="currentParentTab?.displayName !== 'Services' && !showServices">
                                  <div class="table-responsive table-responsive-md dial-table tbld"
                                    style="margin-top:-2px">
                                    <ng-container *ngIf="currentParentTab.displayName !== 'GL Rating Basis' &&
                                    currentParentTab.displayName !== 'WC Rating' &&
                                    currentParentTab.displayName !== 'Cyber Rating Basis';else showRatingBasis">
                                      <table class="table tbl-dial" id="tbl-checkdetails">
                                        <thead class="table-dark">
                                          <tr>
                                            <th width="20%"> Group Name
                                              <span class="star-color"></span>
                                            </th>
                                            <th width="30%"> Coverage
                                              <span class="star-color"></span>
                                            </th>
                                            <th> Expiring
                                              <span class="star-color"></span>
                                            </th>
                                            <th> Recommended
                                              <span class="star-color"></span>
                                            </th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          <ng-container
                                            *ngIf="
                                        currentParentTab.coverageParameters && currentParentTab.coverageParameters.length else NoData">
                                            <ng-container
                                              *ngFor="let parameter of currentParentTab.coverageParameters;let i = index;">

                                              <ng-container *ngIf="!parameter.hideControl">
                                                <tr>
                                                  <td class="cr-wd">
                                                    <span class="grp-heading"
                                                      pTooltip="  {{ getGroupName(currentParentTab, i)}}"
                                                      tooltipPosition="bottom" tooltipStyleClass="tooltipstyle">
                                                      <h3>{{ getGroupName(currentParentTab, i)}}</h3>
                                                    </span>
                                                  </td>
                                                  <td class="chkListText">
                                                    <span *ngIf="!parameter.isRequired"
                                                      pTooltip="{{ parameter.displayName }}" tooltipPosition="bottom"
                                                      tooltipStyleClass="tooltipstyle">{{ parameter.displayName
                                                      }}</span>
                                                    <b *ngIf="parameter.isRequired">{{ parameter.displayName }} </b>
                                                    <label *ngIf="parameter.isRequired" class="form-label">
                                                      <span>*</span></label>
                                                  </td>
                                                  <!-- expiring column starts-->
                                                  <td class="exp-col"
                                                    [ngClass]="{'disabled': wbaDetails?.transType === 'RN'}">
                                                    <ng-container *ngIf="parameter.valueType!=''; else showExpiring">
                                                      <div class="pos-re">
                                                        <ng-container
                                                          *ngIf="parameter.valueType === 'input-currency';else inputNumber">
                                                          <span
                                                            *ngIf="parameter.valueType === 'input-currency' && parameter.expiringValue!=='See SOV'"
                                                            class="dollor">$
                                                          </span>
                                                          <input
                                                            *ngIf="parameter.valueType === 'input-currency' && parameter.expiringValue!=='See SOV'"
                                                            (input)="handleInputExpiring(parameter)"
                                                            [ngClass]="[parameter.expiringAssessmentId ? 'assess_input_' + parameter.expiringAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.expiringValue && expiringCarrier ? 'red-border' : '']"
                                                            class="dollor-input form-control"
                                                            [(ngModel)]="parameter.expiringValue"
                                                            (keypress)=" ValidateKey.integer( $event, 16 ) "
                                                            (focus)="removeCurrencyFormat( $event ) "
                                                            (focusout)=" removeSpaces($event, parameter, 'expiringValue' ) " />

                                                          <input
                                                            *ngIf="parameter.valueType === 'input-currency' && parameter.expiringValue==='See SOV'"
                                                            class=" form-control "
                                                            [(ngModel)]="parameter.expiringValue" />


                                                        </ng-container>
                                                        <ng-template #inputNumber>
                                                          <ng-container *ngIf=" parameter.valueType==='input-number' ; else
                                                            inputText">
                                                            <input *ngIf="!parameter.options.length"
                                                              (input)="handleInputExpiring(parameter)"
                                                              class="form-control "
                                                              [(ngModel)]="parameter.expiringValue"
                                                              [ngClass]="[parameter.expiringAssessmentId ? 'assess_input_' + parameter.expiringAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.expiringValue && expiringCarrier ? 'red-border' : '']"
                                                              (focusout)="addCurrencyFormat($event,parameter,'expiringValue')"
                                                              (keypress)="ValidateKey.integer($event,16)"
                                                              (focus)="removeCurrencyFormat($event)"
                                                              (focusout)="removeSpaces($event,parameter,'expiringValue')" />
                                                          </ng-container>
                                                        </ng-template>
                                                        <ng-template #inputText>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'input-text'; else dropdown">
                                                            <input *ngIf="!parameter.options.length"
                                                              (input)="handleInputExpiring(parameter)"
                                                              class=" form-control "
                                                              [(ngModel)]="parameter.expiringValue"
                                                              [ngClass]="[parameter.expiringAssessmentId ? 'assess_input_' + parameter.expiringAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.expiringValue && expiringCarrier  ? 'red-border' : '']"
                                                              (focusout)="removeSpaces($event,parameter,'expiringValue')" />
                                                          </ng-container>
                                                        </ng-template>
                                                        <ng-template #dropdown>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'dropdown';else dropdown_input_currency">
                                                            <select
                                                              *ngIf="parameter.displayName !== 'Liability - Auto Symbol'"
                                                              (change)="handleDropdownExpiring(parameter)"
                                                              class="select-cs form-select"
                                                              [ngClass]="[parameter.expiringAssessmentId ? 'assess_' + parameter.expiringAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.expiringValue && expiringCarrier  ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.expiringValue"
                                                              [style.display]="parameter.hideControl ? 'none' : 'block'">
                                                              <option value="">
                                                                Select Expiring
                                                              </option>
                                                              <option *ngFor="let option of parameter.options"
                                                                value={{option.displayValue}}>
                                                                {{ option.displayValue }}
                                                              </option>
                                                            </select>

                                                            <kendo-multiselect [checkboxes]="true" [autoClose]="false"
                                                              [valuePrimitive]="true"
                                                              *ngIf="parameter.displayName === 'Liability - Auto Symbol'"
                                                              (valueChange)="handleDropdownExpiring(parameter)"
                                                              [(ngModel)]="expiringValueForAutosymbol"
                                                              [data]="parameter.options" valueField="displayValue"
                                                              textField="displayValue">
                                                            </kendo-multiselect>
                                                          </ng-container>
                                                        </ng-template>

                                                        <ng-template #dropdown_input_currency>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'dropdown,input-currency';else date">
                                                            <select (change)="handleDropdownExpiring(parameter)"
                                                              class="select-cs form-select"
                                                              [ngClass]="[parameter.expiringAssessmentId ? 'assess_' + parameter.expiringAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.expiringValue && expiringCarrier  ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.expiringValue">
                                                              <option value="">
                                                                Select Expiring
                                                              </option>
                                                              <option *ngFor="let option of parameter.options"
                                                                value={{option.displayValue}}>
                                                                {{ option.displayValue }}
                                                              </option>
                                                            </select>
                                                            <input (input)="handleInputExpiring(parameter)"
                                                              class="dollor-input form-control"
                                                              [ngClass]="[parameter.expiringAssessmentId ? 'assess_input_' + parameter.expiringAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.expiringValue && expiringCarrier ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.expiringValue"
                                                              (focusout)="addCurrencyFormat( $event, parameter, 'expiringValue')"
                                                              (keypress)=" ValidateKey.integer( $event, 16 ) "
                                                              (focus)="removeCurrencyFormat( $event ) "
                                                              [disabled]="parameter.disableTextbox" (focusout)=" removeSpaces($event,
                                                        parameter, 'expiringValue' ) " />
                                                          </ng-container>
                                                        </ng-template>

                                                        <ng-template #date>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'input-date'; else dropdown_input_text">
                                                            <div class="date-control has-validation">
                                                              <div class="des">
                                                                <div class="date-control-view wba-date">
                                                                  <p-calendar class="form-control wa-cld"
                                                                    appendTo="body" placeholder="MM/DD/YYYY"
                                                                    [showIcon]="true" [iconDisplay]="'input'" dateFormat="mm/dd/yy"
                                                                    (onSelect)="onDateChange(parameter)"
                                                                    (onInput)="onDateChange(parameter)"
                                                                    [(ngModel)]="parameter.expiringValue"
                                                                    [disabled]="parameter.disableTextbox"
                                                                    [style.display]="parameter.hideControl ? 'none' : 'block'"></p-calendar>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </ng-container>
                                                        </ng-template>
                                                        <ng-template #dropdown_input_text>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'dropdown,input-text'; else dropdown_input_number">
                                                            <select (change)="handleDropdownExpiring(parameter)"
                                                              class="select-cs form-select"
                                                              [ngClass]="[parameter.expiringAssessmentId ? 'assess_input_' + parameter.expiringAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.expiringValue && expiringCarrier  ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.expiringValue">
                                                              <option value="">
                                                                Select Expiring
                                                              </option>
                                                              <option *ngFor="let option of parameter.options"
                                                                value={{option.displayValue}}>
                                                                {{ option.displayValue }}
                                                              </option>
                                                            </select>
                                                            <input (input)="handleInputExpiring(parameter)"
                                                              class="form-control ms-2"
                                                              [ngClass]="[parameter.expiringAssessmentId ? 'assess_input_' + parameter.expiringAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.expiringValue && expiringCarrier  ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.expiringValue"
                                                              [disabled]="parameter.disableTextbox"
                                                              (focusout)="removeSpaces($event,parameter,'expiringValue')" />
                                                          </ng-container>
                                                        </ng-template>
                                                        <ng-template #dropdown_input_number>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'dropdown,input-number'; else input_zip">
                                                            <select (change)="handleDropdownExpiring(parameter)"
                                                              class="select-cs form-select"
                                                              [ngClass]="[parameter.expiringAssessmentId ? 'assess_' + parameter.expiringAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.expiringValue && expiringCarrier  ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.expiringValue">
                                                              <option value="">
                                                                Select Expiring
                                                              </option>
                                                              <option *ngFor="let option of parameter.options"
                                                                value={{option.displayValue}}>
                                                                {{ option.displayValue }}
                                                              </option>
                                                            </select>
                                                            <input (input)="handleInputExpiring(parameter)"
                                                              class="form-control ms-2"
                                                              [ngClass]="[parameter.expiringAssessmentId ? 'assess_input_' + parameter.expiringAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.expiringValue && expiringCarrier  ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.expiringValue"
                                                              [disabled]="parameter.disableTextbox"
                                                              (focusout)="addCurrencyFormat($event,parameter,'expiringValue')"
                                                              (keypress)="ValidateKey.integer($event,16)"
                                                              (focus)="removeCurrencyFormat($event)"
                                                              (focusout)="removeSpaces($event,parameter,'expiringValue')" />
                                                          </ng-container>
                                                        </ng-template>
                                                        <ng-template #input_zip>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'input-zip'; else input_ein">
                                                            <input (input)="handleInputExpiring(parameter)"
                                                              class="form-control" mask="00000-0000||00000"
                                                              [ngClass]="[parameter.expiringAssessmentId ? 'assess_input_' + parameter.expiringAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.expiringValue && expiringCarrier  ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.expiringValue"
                                                              (focusout)="removeSpaces($event,parameter,'expiringValue')" />
                                                          </ng-container>
                                                        </ng-template>
                                                        <ng-template #input_ein>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'input-ein'; else input_expmod">
                                                            <input (input)="handleInputExpiring(parameter)"
                                                              class="form-control" mask="00-0000000"
                                                              [ngClass]="[parameter.expiringAssessmentId ? 'assess_input_' + parameter.expiringAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.expiringValue && expiringCarrier ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.expiringValue"
                                                              (focusout)="removeSpaces($event,parameter,'expiringValue')" />
                                                          </ng-container>
                                                        </ng-template>
                                                        <ng-template #input_expmod>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'input-expmod'; else input_percentage">
                                                            <input (input)="handleInputExpiring(parameter)"
                                                              [ngClass]="[parameter.expiringAssessmentId ? 'assess_input_' + parameter.expiringAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.expiringValue && expiringCarrier  ? 'red-border' : '']"
                                                              (keypress)="ValidateKey.WholeNumbers($event, 4)"
                                                              class="form-control" [(ngModel)]="parameter.expiringValue"
                                                              appTwoDigitDecimaNumber />
                                                          </ng-container>
                                                        </ng-template>
                                                        <ng-template #input_percentage>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'input-percentage';else dropdown_input_percent">
                                                            <span
                                                              [ngClass]="{'dollor':parameter.valueType === 'input-percentage'}">%</span>
                                                            <input *ngIf="!parameter.options.length; "
                                                              (input)="handleInputExpiring(parameter)"
                                                              class="dollor-input form-control"
                                                              [ngClass]="[parameter.expiringAssessmentId ? 'assess_input_' + parameter.expiringAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.expiringValue && expiringCarrier  ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.expiringValue"
                                                              (keypress)="ValidateKey.integer($event,3)"
                                                              (focusout)="removeSpaces($event,parameter,'expiringValue')" />
                                                          </ng-container>
                                                        </ng-template>

                                                        <ng-template #dropdown_input_percent>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'dropdown,input-percent'">
                                                            <select (change)="handleDropdownExpiring(parameter)"
                                                              class="select-cs form-select"
                                                              [ngClass]="[parameter.expiringAssessmentId ? 'assess_' + parameter.expiringAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.expiringValue && expiringCarrier ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.expiringValue"
                                                              [style.display]="parameter.hideControl ? 'none' : 'block'">
                                                              <option value="">
                                                                Select Expiring
                                                              </option>
                                                              <option *ngFor="let option of parameter.options"
                                                                value={{option.displayValue}}>
                                                                {{ option.displayValue }}
                                                              </option>
                                                            </select>

                                                            <div class="percentInput form-control">
                                                              <input (input)="handleInputExpiring(parameter)"
                                                                type="text" name="number" class="num_percent"
                                                                [ngClass]="[parameter.expiringAssessmentId ? 'assess_input_' + parameter.expiringAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.expiringValue && expiringCarrier  ? 'red-border' : '']"
                                                                [(ngModel)]="parameter.expiringValue"
                                                                (keypress)=" ValidateKey.integer( $event, 16 ) "
                                                                [disabled]="parameter.disableTextbox"
                                                                (focusout)=" removeSpaces($event, parameter, 'expiringValue' )">
                                                              <span
                                                                *ngIf="parameter.expiringValue != 'Not Available'">%</span>
                                                            </div>
                                                            <!-- <span
                                                        [ngClass]="
                                                          {'dollor':parameter.valueType==='dropdown,input-percent'
                                                          }">%</span> -->
                                                          </ng-container>
                                                        </ng-template>

                                                        <img *ngIf="parameter.expiringAssessmentId>0"
                                                          pTooltip="{{parameter.expiringConditionDescription}}"
                                                          tooltipPosition="bottom" tooltipStyleClass="tooltipstyle"
                                                          src="assets/images/info.png" alt class="infoImage ms-2" />
                                                      </div>
                                                    </ng-container>
                                                    <ng-template #showExpiring>
                                                      <div class="cr-wd">
                                                        <span pTooltip="{{ parameter.expiringValue }}"
                                                          tooltipPosition="bottom" tooltipStyleClass="tooltipstyle">{{
                                                          parameter.expiringValue }}</span>
                                                      </div>
                                                    </ng-template>
                                                  </td>
                                                  <!-- expiring column ends-->
                                                  <!-- recommended column starts-->
                                                  <td [ngClass]="{'disabled': wbaDetails?.transType === 'RN'}"
                                                    class="rec-col">
                                                    <ng-container *ngIf="parameter.valueType!=''; else showRecomended">
                                                      <div class="pos-re">
                                                        <ng-container
                                                          *ngIf="parameter.valueType === 'input-currency'; else recommendedInputNumber">
                                                          <span
                                                            *ngIf="parameter.valueType === 'input-currency' && parameter.recommendedValue!=='See SOV'"
                                                            class="dollor">$</span>
                                                          <input
                                                            *ngIf="parameter.valueType === 'input-currency' && parameter.recommendedValue!=='See SOV'"
                                                            (input)="handleInputRecommended(parameter)"
                                                            class="dollor-input form-control"
                                                            [ngClass]="[parameter.recommendedAssessmentId ? 'assess_input_' + parameter.recommendedAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.recommendedValue ? 'red-border' : '']"
                                                            [(ngModel)]="parameter.recommendedValue"
                                                            (focusout)="addCurrencyFormat($event,parameter,'recommendedValue')"
                                                            (keypress)="ValidateKey.integer($event,16)"
                                                            (focus)="removeCurrencyFormat($event)"
                                                            (focusout)="removeSpaces($event,parameter,'recommendedValue')" />
                                                          <input
                                                            *ngIf="parameter.valueType === 'input-currency' && parameter.recommendedValue==='See SOV'"
                                                            class=" form-control "
                                                            [(ngModel)]="parameter.recommendedValue" />
                                                        </ng-container>
                                                        <ng-template #recommendedInputNumber>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'input-number';else recommendedInputText">
                                                            <input *ngIf="!parameter.options.length; "
                                                              (input)="handleInputRecommended(parameter)"
                                                              class="form-control "
                                                              [ngClass]="[parameter.recommendedAssessmentId ? 'assess_input_' + parameter.recommendedAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.recommendedValue ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.recommendedValue"
                                                              (focusout)="addCurrencyFormat($event,parameter,'recommendedValue')"
                                                              (keypress)="ValidateKey.integer($event,16)"
                                                              (focus)="removeCurrencyFormat($event)"
                                                              (focusout)="removeSpaces($event,parameter,'recommendedValue')" />
                                                          </ng-container>
                                                        </ng-template>
                                                        <ng-template #recommendedInputText>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'input-text'; else recommendedDropdown">
                                                            <input *ngIf="!parameter.options.length"
                                                              (input)="handleInputRecommended(parameter)"
                                                              [ngClass]="[parameter.recommendedAssessmentId ? 'assess_input_' + parameter.recommendedAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.recommendedValue ? 'red-border' : '']"
                                                              class=" form-control "
                                                              [(ngModel)]="parameter.recommendedValue"
                                                              (focusout)="removeSpaces($event,parameter,'recommendedValue')" />
                                                          </ng-container>
                                                        </ng-template>
                                                        <ng-template #recommendedDropdown>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'dropdown';else recommended_dropdown_input_currency">
                                                            <select
                                                              *ngIf="parameter.displayName !== 'Liability - Auto Symbol'"
                                                              (change)="handleDropdownRecommended(parameter)"
                                                              class="select-cs form-select "
                                                              [ngClass]="[parameter.recommendedAssessmentId ? 'assess_' + parameter.recommendedAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.recommendedValue ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.recommendedValue"
                                                              [style.display]="parameter.hideControl ? 'none' : 'block'">
                                                              <option value="">
                                                                Select Recommended
                                                              </option>
                                                              <option *ngFor="let option of parameter.options"
                                                                value={{option.displayValue}}>
                                                                {{ option.displayValue }}
                                                              </option>
                                                            </select>

                                                            <kendo-multiselect [checkboxes]="true" [autoClose]="false"
                                                              [valuePrimitive]="true"
                                                              *ngIf="parameter.displayName === 'Liability - Auto Symbol'"
                                                              (valueChange)="handleDropdownRecommended(parameter)"
                                                              [data]="parameter.options"
                                                              [(ngModel)]="recommandedValueForAutoSymbol"
                                                              valueField="displayValue" textField="displayValue">

                                                            </kendo-multiselect>

                                                          </ng-container>
                                                        </ng-template>

                                                        <ng-template #recommended_dropdown_input_currency>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'dropdown,input-currency';else recommendedDate">
                                                            <select (change)="handleDropdownRecommended(parameter)"
                                                              class="select-cs form-select"
                                                              [ngClass]="[parameter.recommendedAssessmentId ? 'assess_' + parameter.recommendedAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.recommendedValue ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.recommendedValue">
                                                              <option value="">
                                                                Select Recommended
                                                              </option>
                                                              <option *ngFor="let option of parameter.options"
                                                                value={{option.displayValue}}>
                                                                {{ option.displayValue }}
                                                              </option>
                                                            </select>
                                                            <input (input)="handleInputRecommended(parameter)"
                                                              class="dollor-input form-control"
                                                              [(ngModel)]="parameter.recommendedValue"
                                                              [ngClass]="[parameter.recommendedAssessmentId ? 'assess_input_' + parameter.recommendedAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.recommendedValue ? 'red-border' : '']"
                                                              (focusout)="addCurrencyFormat( $event, parameter, 'recommendedValue')"
                                                              (keypress)=" ValidateKey.integer( $event, 16 ) "
                                                              (focus)="removeCurrencyFormat( $event ) "
                                                              [disabled]="parameter.disableTextbox"
                                                              (focusout)=" removeSpaces($event, parameter, 'recommendedValue' ) " />
                                                          </ng-container>
                                                        </ng-template>

                                                        <ng-template #recommendedDate>
                                                          <ng-container
                                                            *ngIf="parameter.valueType==='input-date'; else recommended_dropdown_input_text">
                                                            <div class="date-control has-validation">
                                                              <div class="des">
                                                                <div class="date-control-view wba-date">
                                                                  <p-calendar class="form-control wa-cld"
                                                                    appendTo="body" placeholder="MM/DD/YYYY"
                                                                    [showIcon]="true" [iconDisplay]="'input'" dateFormat="mm/dd/yy"
                                                                    (onSelect)="onRecommendedDateChange(parameter)"
                                                                    (onInput)="onRecommendedDateChange(parameter)"
                                                                    [(ngModel)]="parameter.recommendedValue"
                                                                    [disabled]="parameter.disableTextbox"
                                                                    [style.display]="parameter.hideControl ? 'none' : 'block'"></p-calendar>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </ng-container>
                                                        </ng-template>
                                                        <!-- dropdown and text -->
                                                        <ng-template #recommended_dropdown_input_text>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'dropdown,input-text'; else recommended_dropdown_input_number">
                                                            <select (change)="handleDropdownRecommended(parameter)"
                                                              class="select-cs form-select"
                                                              [ngClass]="[parameter.recommendedAssessmentId ? 'assess_' + parameter.recommendedAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.recommendedValue ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.recommendedValue">
                                                              <option value="">
                                                                Select Recommended
                                                              </option>
                                                              <option *ngFor="let option of parameter.options"
                                                                value={{option.displayValue}}>
                                                                {{ option.displayValue }}
                                                              </option>
                                                            </select>
                                                            <input (input)="handleInputRecommended(parameter)"
                                                              class="form-control ms-2"
                                                              [ngClass]="[parameter.recommendedAssessmentId ? 'assess_input_' + parameter.recommendedAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.recommendedValue ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.recommendedValue"
                                                              [disabled]="parameter.disableRecommendedTextbox"
                                                              [disabled]="parameter.disableTextbox" (focusout)="
                                                        removeSpaces($event,parameter,'recommendedValue')" />
                                                          </ng-container>
                                                        </ng-template>
                                                        <ng-template #recommended_dropdown_input_number>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'dropdown,input-number'; else recommended_input_zip">
                                                            <select (change)="handleDropdownRecommended(parameter)"
                                                              class="select-cs form-select"
                                                              [ngClass]="[parameter.recommendedAssessmentId ? 'assess_' + parameter.recommendedAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.recommendedValue ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.recommendedValue">
                                                              <option value="">
                                                                Select Recommended
                                                              </option>
                                                              <option *ngFor="let option of parameter.options"
                                                                value={{option.displayValue}}>
                                                                {{ option.displayValue }}
                                                              </option>
                                                            </select>
                                                            <input (input)="handleInputRecommended(parameter)"
                                                              class="form-control ms-2"
                                                              [ngClass]="[parameter.recommendedAssessmentId ? 'assess_input_' + parameter.recommendedAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.recommendedValue ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.recommendedValue"
                                                              [disabled]="parameter.disableRecommendedTextbox"
                                                              [disabled]="parameter.disableTextbox" (focusout)="
                                                        addCurrencyFormat($event,parameter,'recommendedValue')"
                                                              (keypress)="ValidateKey.integer($event,16)"
                                                              (focus)="removeCurrencyFormat($event)"
                                                              (focusout)="removeSpaces($event,parameter,'recommendedValue')" />
                                                          </ng-container>
                                                        </ng-template>
                                                        <ng-template #recommended_input_zip>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'input-zip'; else recommended_input_ein">
                                                            <input (input)="handleInputRecommended(parameter)"
                                                              class="form-control" mask="00000-0000||00000"
                                                              [ngClass]="[parameter.recommendedAssessmentId ? 'assess_input_' + parameter.recommendedAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.recommendedValue ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.recommendedValue"
                                                              [disabled]="parameter.disableRecommendedTextbox"
                                                              (focusout)="removeSpaces($event,parameter,'recommendedValue')" />
                                                          </ng-container>
                                                        </ng-template>
                                                        <ng-template #recommended_input_ein>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'input-ein'; else recommended_input_expmod">
                                                            <input (input)="handleInputRecommended(parameter)"
                                                              class="form-control" mask="00-0000000"
                                                              [ngClass]="[parameter.recommendedAssessmentId ? 'assess_input_' + parameter.recommendedAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.recommendedValue ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.recommendedValue"
                                                              [disabled]="parameter.disableRecommendedTextbox"
                                                              (focusout)="removeSpaces($event,parameter,'recommendedValue')" />
                                                          </ng-container>
                                                        </ng-template>
                                                        <ng-template #recommended_input_expmod>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'input-expmod'; else recommended_input_percentage">
                                                            <input (input)="handleInputRecommended(parameter)"
                                                              (keypress)="ValidateKey.WholeNumbers($event, 4)"
                                                              class="form-control"
                                                              [ngClass]="[parameter.recommendedAssessmentId ? 'assess_input_' + parameter.recommendedAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.recommendedValue ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.recommendedValue"
                                                              appTwoDigitDecimaNumber />
                                                          </ng-container>
                                                        </ng-template>
                                                        <ng-template #recommended_input_percentage>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'input-percentage';else recommended_dropdown_input_percent">
                                                            <span
                                                              [ngClass]="{'dollor':parameter.valueType === 'input-percentage'}">%</span>
                                                            <input *ngIf="!parameter.options.length; "
                                                              (input)="handleInputExpiring(parameter)"
                                                              class="dollor-input form-control"
                                                              [ngClass]="[parameter.recommendedAssessmentId ? 'assess_input_' + parameter.recommendedAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.recommendedValue ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.recommendedValue"
                                                              (keypress)="ValidateKey.integer($event,3)"
                                                              (focusout)="removeSpaces($event,parameter,'recommendedValue')" />
                                                          </ng-container>
                                                        </ng-template>

                                                        <ng-template #recommended_dropdown_input_percent>
                                                          <ng-container
                                                            *ngIf="parameter.valueType === 'dropdown,input-percent'">
                                                            <select (change)="handleDropdownRecommended(parameter)"
                                                              class="select-cs form-select"
                                                              [ngClass]="[parameter.recommendedAssessmentId ? 'assess_' + parameter.recommendedAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.recommendedValue ? 'red-border' : '']"
                                                              [(ngModel)]="parameter.recommendedValue"
                                                              [style.display]="parameter.hideControl ? 'none' : 'block'">
                                                              <option value="">
                                                                Select Recommended
                                                              </option>
                                                              <option *ngFor="let option of parameter.options"
                                                                value={{option.displayValue}}>
                                                                {{ option.displayValue }}
                                                              </option>
                                                            </select>
                                                            <div class="percentInput form-control">
                                                              <input (input)="handleInputRecommended(parameter)"
                                                                class="num_percent"
                                                                [(ngModel)]="parameter.recommendedValue"
                                                                [ngClass]="[parameter.recommendedAssessmentId ? 'assess_input_' + parameter.recommendedAssessmentId : '',  parameter.isRequired && isSubmitted && !parameter.recommendedValue ? 'red-border' : '']"
                                                                (focusout)="addCurrencyFormat( $event, parameter, 'recommendedValue')"
                                                                (keypress)=" ValidateKey.integer( $event, 16 ) "
                                                                (focus)="removeCurrencyFormat( $event ) "
                                                                [disabled]="parameter.disableTextbox"
                                                                (focusout)=" removeSpaces($event, parameter, 'recommendedValue' ) " />
                                                              <span
                                                                *ngIf="parameter.recommendedValue != 'Not Available'">%</span>
                                                            </div>

                                                          </ng-container>
                                                        </ng-template>
                                                        <img *ngIf="parameter.recommendedAssessmentId>0"
                                                          pTooltip="{{parameter.recommendedConditionDescription}}"
                                                          tooltipPosition="bottom" tooltipStyleClass="tooltipstyle"
                                                          src="assets/images/info.png" alt class="infoImage ms-2" />
                                                      </div>
                                                    </ng-container>

                                                    <ng-template #showRecomended>
                                                      <div class="cr-wd">
                                                        <span pTooltip="{{ parameter.recommendedValue }}"
                                                          tooltipPosition="bottom" tooltipStyleClass="tooltipstyle">{{
                                                          parameter.recommendedValue }}</span>
                                                      </div>
                                                    </ng-template>
                                                  </td>
                                                  <!-- recommended column ends-->
                                                </tr>
                                              </ng-container>

                                            </ng-container>
                                          </ng-container>
                                          <ng-template #NoData>
                                            <tr>
                                              <td colSpan="4">
                                                No data found
                                              </td>
                                            </tr>
                                          </ng-template>
                                        </tbody>
                                      </table>
                                    </ng-container>
                                    <ng-template #showRatingBasis>
                                      <table class="table tbl-dial"
                                        *ngIf="currentParentTab.displayName=='GL Rating Basis' || currentParentTab.displayName=='Cyber Rating Basis'">
                                        <thead class="table-dark">
                                          <tr>
                                            <th>
                                              Exposure Type
                                              <span class="star-color"></span>
                                            </th>
                                            <th>
                                              Value
                                              <span class="star-color"></span>
                                            </th>
                                            <th *ngIf="wbaDetails?.transType === 'RN'">
                                              Class Code
                                              <span class="star-color"></span>
                                            </th>
                                            <th width="58%">
                                              Description
                                              <span class="star-color"></span>
                                            </th>
                                            <th width="6%"></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let rating of currentParentTab.ratingBasis; let i = index">
                                            <td>
                                              <span *ngIf="i===editIndex; else exposureTypeString">
                                                <select class="select-cs form-select"
                                                  (change)="onGLRatingExposureTypeChange()"
                                                  [(ngModel)]="editGLRatingBasisForm.exposureId">
                                                  <option disabled value="">
                                                    Select Exposure
                                                  </option>
                                                  <option
                                                    *ngFor=" let option of currentParentTab.coverageExposures; let i=index"
                                                    [selected]="option.id==editGLRatingBasisForm.exposureId"
                                                    value="{{ option.id }}"> {{ option.displayName }}
                                                  </option>
                                                </select>
                                                <div
                                                  *ngIf="addGLRatingBasisForm.exposureType==='' && isEditGLRatingBasisSubmitted"
                                                  class="display-error">
                                                  Required
                                                </div>
                                              </span>
                                              <ng-template #exposureTypeString>
                                                {{rating.exposureType}}</ng-template>
                                            </td>
                                            <td>
                                              <span *ngIf="i===editIndex; else valueString">
                                                <span *ngIf="isDollarVisible" class="dollor-view">$</span>
                                                <input class="form-control me-3 dollor-input-view"
                                                  [(ngModel)]="editGLRatingBasisForm.value"
                                                  (focusout)="addCurrencyFormat($event,rating,'value')"
                                                  (keypress)="ValidateKey.integer($event,16)"
                                                  (focus)="removeCurrencyFormat($event)"
                                                  (focusout)="removeSpaces($event,rating, 'value')" />
                                              </span>
                                              <ng-template #valueString> {{rating.value }}</ng-template>
                                            </td>
                                            <td *ngIf="wbaDetails?.transType === 'RN'">
                                              <div class="c-label"> {{rating.classCode}}
                                              </div>
                                            </td>
                                            <td class="whtspace">
                                              <span *ngIf="i===editIndex; else descriptionString">
                                                <input class="form-control me-3 dollor-input-view"
                                                  [(ngModel)]="editGLRatingBasisForm.description" />
                                              </span>
                                              <ng-template #descriptionString> {{rating.description}}</ng-template>

                                            </td>
                                            <td>
                                              <div class="flex-div">
                                                <img *ngIf="editIndex===-1" class="pointer"
                                                  [ngClass]="{'disabled': wbaDetails?.transType === 'RN'}"
                                                  (click)="handleGLRatingBasis('edit', editGLRatingBasisForm, i)"
                                                  src="assets/images/edit.svg" width="18px" alt="" class="me-2" />

                                                <img *ngIf="editIndex===-1" class="pointer" width="11px"
                                                  [ngClass]="{'disabled': wbaDetails?.transType === 'RN'}"
                                                  (click)="cancelGLRating(i)" src="assets/images/trash.svg" alt="" />

                                                <img *ngIf="editIndex!==-1 && editIndex===i" class="pointer me-2"
                                                  width=" 13px" (click)="handleGLRatingBasis('cross', false, i)"
                                                  src="assets/images/cross.svg" alt="" />

                                                <img *ngIf="editIndex!==-1 && editIndex===i" class="pointer"
                                                  width=" 13px"
                                                  (click)="handleGLRatingBasis('save', editGLRatingBasisForm, i)"
                                                  src="assets/images/save.svg" alt="" />
                                              </div>
                                            </td>
                                          </tr>
                                          <tr *ngIf="editIndex===-1 && wbaDetails?.transType != 'RN'">
                                            <td>
                                              <select class="select-cs form-select"
                                                (change)="onGLRatingExposureTypeChange()"
                                                [(ngModel)]="addGLRatingBasisForm.exposureType">
                                                <option disabled value="">
                                                  Select Exposure
                                                </option>
                                                <option
                                                  *ngFor=" let option of currentParentTab.coverageExposures; let i=index"
                                                  [ngValue]="option"> {{ option.displayName }}
                                                </option>
                                              </select>
                                              <div
                                                *ngIf="addGLRatingBasisForm.exposureType==='' && isAddGLRatingBasisSubmitted"
                                                class="display-error">
                                                Required
                                              </div>
                                            </td>
                                            <td>

                                              <span *ngIf="isDollarVisible" class="dollor-view">$</span>
                                              <input class="form-control dollor-input-view "
                                                [(ngModel)]="addGLRatingBasisForm.value"
                                                (focusout)="addCurrencyFormat($event,rating,'value')"
                                                (keypress)="ValidateKey.integer($event,16)"
                                                (focus)="removeCurrencyFormat($event)"
                                                (focusout)="removeSpaces($event,rating, 'value')" />

                                              <div
                                                *ngIf="addGLRatingBasisForm.value==='' && isAddGLRatingBasisSubmitted"
                                                class="display-error">
                                                Required
                                              </div>
                                            </td>
                                            <td>
                                              <input type="text" class="form-control" name="description"
                                                [(ngModel)]="addGLRatingBasisForm.description" />
                                            </td>
                                            <td>
                                              <div class="flex-div">
                                                <img class="pointer me-2" width=" 18px"
                                                  (click)="handleGLRatingBasis('cross', addGLRatingBasisForm, -1)"
                                                  src="assets/images/cross.svg" alt="" />

                                                <img class="pointer" width=" 18px"
                                                  (click)="handleGLRatingBasis('add',addGLRatingBasisForm, -1)"
                                                  src="assets/images/save.svg" alt="" />
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table class="table tbl-dial" *ngIf="currentParentTab.displayName=='WC Rating'">
                                        <thead class="table-dark">
                                          <tr>
                                            <th width="9%"> Location # </th>
                                            <th width="15%"> Class Code </th>
                                            <th width="15%"> Sub Code </th>
                                            <th width="15%"> FT Employees</th>
                                            <th width="15%"> PT Employees </th>
                                            <th width="10%"> Payroll </th>
                                            <th width="15%"> State of Exposure</th>
                                            <th width="6%">...</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let item of currentParentTab.workercompRating; let i = index">
                                            <td>
                                              <span *ngIf="i===editIndex; else locationString">
                                                <input class="form-control me-3 dollor-input-view"
                                                  [(ngModel)]="editWCRatingForm.location" />
                                                <div *ngIf="editWCRatingForm.location==='' && isEditWCRatingSubmitted"
                                                  class="display-error">
                                                  Required
                                                </div>
                                              </span>
                                              <ng-template #locationString> {{item.location}}</ng-template>
                                            </td>
                                            <td>
                                              <span *ngIf="i===editIndex; else classCodeString">
                                                <input class="form-control me-3 dollor-input-view"
                                                  [(ngModel)]="editWCRatingForm.classCode" />
                                                <div *ngIf="editWCRatingForm.classCode==='' && isEditWCRatingSubmitted"
                                                  class="display-error">
                                                  Required
                                                </div>
                                              </span>
                                              <ng-template #classCodeString> {{item.classCode}}</ng-template>
                                            </td>
                                            <td>
                                              <span *ngIf="i===editIndex; else subCodeString">
                                                <input class="form-control me-3 dollor-input-view"
                                                  [(ngModel)]="editWCRatingForm.subCode" />
                                              </span>
                                              <ng-template #subCodeString> {{item.subCode}}</ng-template>
                                            </td>
                                            <td>
                                              <span *ngIf="i===editIndex; else fullTimeEmployeesString">
                                                <input class="form-control me-3 dollor-input-view"
                                                  [(ngModel)]="editWCRatingForm.fullTimeEmployees" />
                                              </span>
                                              <ng-template #fullTimeEmployeesString>
                                                {{item.fullTimeEmployees}}</ng-template>
                                            </td>
                                            <td>
                                              <span *ngIf="i===editIndex; else partTimeEmployeesString">
                                                <input class="form-control me-3 dollor-input-view"
                                                  [(ngModel)]="editWCRatingForm.partTimeEmployees" />
                                              </span>
                                              <ng-template #partTimeEmployeesString>
                                                {{item.partTimeEmployees}}</ng-template>
                                            </td>
                                            <td>
                                              <span *ngIf="i===editIndex; else payrollString">
                                                <span class="dollor1">$</span>
                                                <input class="form-control me-3 dollor-input"
                                                  [(ngModel)]="editWCRatingForm.payroll" />
                                              </span>
                                              <ng-template #payrollString> $ {{item.payroll}}</ng-template>
                                            </td>
                                            <td>
                                              <span *ngIf="i===editIndex; else stateOfExposureString">
                                                <select class="select-cs form-select"
                                                  [(ngModel)]="editWCRatingForm.stateOfExposure">
                                                  <option disabled value="">
                                                    Select Exposure
                                                  </option>
                                                  <option *ngFor=" let option of stateList; let i=index"
                                                    [selected]="option.stateCode==editWCRatingForm.stateOfExposure"
                                                    value="{{ option.stateCode }}">
                                                    {{ option.stateName }}
                                                  </option>
                                                </select>
                                              </span>
                                              <ng-template #stateOfExposureString>
                                                {{item.stateOfExposure}}</ng-template>
                                            </td>
                                            <td>
                                              <div class="flex-div">
                                                <img *ngIf="editIndex===-1" class="pointer"
                                                  [ngClass]="{'disabled': wbaDetails?.transType === 'RN'}"
                                                  (click)="handleWCRating('edit', false, i)"
                                                  src="assets/images/edit.svg" width="18px" alt="" class="me-2" />

                                                <img *ngIf="editIndex===-1" class="pointer" width="11px"
                                                  [ngClass]="{'disabled': wbaDetails?.transType === 'RN'}"
                                                  (click)="cancel(i)" src="assets/images/trash.svg" alt="" />

                                                <img *ngIf="editIndex!==-1 && editIndex===i" class="pointer me-2"
                                                  width="13px" (click)="handleWCRating('cross', false, i)"
                                                  src="assets/images/cross.svg" alt="" />

                                                <img *ngIf="editIndex!==-1 && editIndex===i" class="pointer"
                                                  width=" 13px" (click)="handleWCRating('save', editWCRatingForm, i)"
                                                  src="assets/images/save.svg" alt="" />
                                              </div>

                                            </td>
                                          </tr>

                                          <tr *ngIf="editIndex===-1 && wbaDetails?.transType != 'RN'">
                                            <td>
                                              <span>
                                                <div class="pos-re">
                                                  <input type="text" class="form-control me-3"
                                                    [(ngModel)]="addWCRatingForm.location"
                                                    [ngModelOptions]="{standalone: true}" />
                                                </div>
                                                <div *ngIf="addWCRatingForm.location==='' && isAddWCRatingSubmitted"
                                                  class="display-error">
                                                  Required
                                                </div>
                                              </span>
                                            </td>
                                            <td class="text-end">
                                              <span>
                                                <div type="text" class="pos-re">
                                                  <input class="form-control me-3"
                                                    [(ngModel)]="addWCRatingForm.classCode"
                                                    [ngModelOptions]="{standalone: true}" />
                                                </div>
                                                <div *ngIf="addWCRatingForm.classCode==='' && isAddWCRatingSubmitted"
                                                  class="display-error">
                                                  Required
                                                </div>
                                              </span>
                                            </td>
                                            <td class="text-end">
                                              <span>
                                                <div class="pos-re">
                                                  <input type="text" class="form-control me-3"
                                                    [(ngModel)]="addWCRatingForm.subCode" />
                                                </div>
                                              </span>
                                            </td>
                                            <td class="text-end">
                                              <span>
                                                <div class="pos-re">
                                                  <input type="text" class="form-control me-3"
                                                    [(ngModel)]="addWCRatingForm.fullTimeEmployees" />
                                                </div>
                                              </span>
                                            </td>
                                            <td class="text-end">
                                              <span>
                                                <div class="pos-re">
                                                  <input type="text" class="form-control me-3"
                                                    [(ngModel)]="addWCRatingForm.partTimeEmployees" />
                                                </div>
                                              </span>
                                            </td>
                                            <td class="text-end">
                                              <span>
                                                <div class="pos-re">
                                                  <span class="dollor">$</span>
                                                  <input class="form-control error dollor-input"
                                                    [(ngModel)]="addWCRatingForm.payroll"
                                                    (keypress)="ValidateKey.integer($event, 16)" />
                                                </div>
                                              </span>
                                            </td>
                                            <td class="text-end">
                                              <span>
                                                <div class="pos-re">
                                                  <select class="select-cs form-select"
                                                    [(ngModel)]="addWCRatingForm.stateOfExposure">
                                                    <option disabled value="">
                                                      Select State
                                                    </option>
                                                    <option *ngFor=" let option of stateList; let i=index"
                                                      [ngValue]="option"> {{ option.stateName }}
                                                    </option>
                                                  </select>
                                                </div>
                                              </span>
                                            </td>
                                            <td>
                                              <img class="pointer me-2" width=" 13px"
                                                (click)="handleWCRating('cross', addWCRatingForm, -1)"
                                                src="assets/images/cross.svg" alt="" />

                                              <img class="pointer" width=" 13px"
                                                (click)="handleWCRating('add', addWCRatingForm, -1)"
                                                src="assets/images/save.svg" alt="" />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </ng-template>
                                  </div>
                                </ng-container>

                                <!-- <ng-template #ServiceBody>
                                  <div style="background: white;" class="px-4">
                                    <div class="row">
                                      <div class="col-12 col-sm-12 col-md-6 servicebox" *ngFor="
                                      let serviceLabel of ServicesKeys">
                                        <h3 class="pt-4 pb-2 mb-2">
                                          {{serviceLabel == "claimManagmentServiceList" ? 'Claim Management Service'
                                          :
                                          (serviceLabel == "riskManagmentServiceList" ? 'Risk Control Service' :
                                          '')}}
                                        </h3>
                                        <div class="p-3 mb-2 serviceBlock"
                                          *ngFor="let item of services[serviceLabel]; let i = index">
                                          <div class="flex-div space-between align-items-center">
                                            <div class="form-check flex-div align-items-center">
                                              <input type="checkbox" (change)="handleService(item)"
                                                name="{{ item.serviceName }}{{ i }}" id="{{ item.serviceName }}{{ i }}"
                                                class="form-check-input" [checked]="item.isChecked" />
                                              <label class="form-check-label m-t-2"
                                                for="{{ item.serviceName }}{{ i }}">{{
                                                item.serviceName }}</label>
                                            </div>
                                            <div class="info">
                                              <p class="sType me-3"><span>{{item.standardOptional}}</span> -
                                                {{item.frequencyOfPerformance}}</p>
                                            </div>
                                            <div class="info-link">
                                              <img src="assets/images/add-info.png" width="16px" alt="">
                                            </div>
                                            <div class="description">
                                              <div class="des-inner" [innerHtml]="item.serviceDescription">
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </ng-template> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>


        <!-- <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="showHideParametersValidationPopUp"
          [breakpoints]="{'960px': '75vw'}" [style]="{width: '43vw'}" [draggable]="false" [resizable]="false"
          [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
          <p>You have not entered the expiring or recommended value for {{missingCoverage}}.</p>
          <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
            <p-button (click)="showHideParametersValidationPopUp = false" label="OK"
              styleClass="btn btn-outline-primary"></p-button>
          </ng-template>
        </p-dialog>

        <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="showRequiredExposureTypePopUp"
          [breakpoints]="{'960px': '75vw'}" [style]="{width: '43vw'}" [draggable]="false" [resizable]="false"
          [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
          <p *ngIf="currentParentTab.displayName !== 'Cyber' && currentParentTab.displayName !== 'Cyber Rating Basis'">
            At least one GL Rating Basis is mandatory. </p>
          <p *ngIf="currentParentTab.displayName == 'Cyber' || currentParentTab.displayName == 'Cyber Rating Basis'">
            At least one Cyber Rating Basis is mandatory. </p>
          <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
            <p-button (click)="showRequiredExposureTypePopUp = false" label="OK"
              styleClass="btn btn-outline-primary"></p-button>
          </ng-template>
        </p-dialog>

        <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="showHideExistingValidationPopUp"
          [breakpoints]="{'960px': '75vw'}" [style]="{width: '43vw'}" [draggable]="false" [resizable]="false"
          [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
          <p>Exposure type already exists.</p>
          <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
            <p-button (click)="showHideExistingValidationPopUp = false" label="OK"
              styleClass="btn btn-outline-primary"></p-button>
          </ng-template>
        </p-dialog>

        <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
          [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
          [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
          <p>{{_confirmBox.message}}</p>
          <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
            <p-button *ngIf="unAthorizesd == ''" (click)="_confirmBox.isVisible = false" label="No"
              styleClass="btn btn-outline-primary">
            </p-button>
            <p-button *ngIf="!deleteGLRatingBasisData" (click)="handleWCRating('trash', false, deleteIndex)" label="Yes"
              styleClass="btn btn-primary">
            </p-button>
            <p-button *ngIf="deleteGLRatingBasisData" (click)="handleGLRatingBasis('trash', false, deleteIndex)"
              label="Yes" styleClass="btn btn-primary">
            </p-button>
          </ng-template>
        </p-dialog> -->
      </div>


      <p-dialog [header]="_confirmBox.header2" [modal]="true" [(visible)]="showHideParametersValidationPopUp"
        [breakpoints]="{'960px': '75vw'}" [style]="{width: '43vw'}" [draggable]="false" [resizable]="false"
        [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
        <p>You have not entered the expiring or recommended value for mandatory coverage(s).</p>
        <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
          <p-button (click)="showHideParametersValidationPopUp = false" label="OK"
            styleClass="btn btn-primary"></p-button>
        </ng-template>
      </p-dialog>

      <p-dialog [header]="_confirmBox.header2" [modal]="true" [(visible)]="showRequiredExposureTypePopUp"
        [breakpoints]="{'960px': '75vw'}" [style]="{width: '43vw'}" [draggable]="false" [resizable]="false"
        [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
        <p
          *ngIf="currentParentTab.displayName !== 'Cyber' && currentParentTab.displayName !== 'Cyber Rating Basis'  && currentParentTab.displayName != 'WC Rating' && currentParentTab.displayName != 'Workers Compensation'">
          At least one GL Rating Basis is mandatory. </p>
        <p *ngIf="currentParentTab.displayName == 'Cyber' || currentParentTab.displayName == 'Cyber Rating Basis'">
          At least one Cyber Rating Basis is mandatory. </p>
        <p
          *ngIf="currentParentTab.displayName == 'WC Rating' || currentParentTab.displayName == 'Workers Compensation'">
          At least one WC Rating Basis is mandatory. </p>
        <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
          <p-button (click)="showRequiredExposureTypePopUp = false" label="OK" styleClass="btn btn-primary"></p-button>
        </ng-template>
      </p-dialog>

      <p-dialog [header]="_confirmBox.header2" [modal]="true" [(visible)]="showHideExistingValidationPopUp"
        [breakpoints]="{'960px': '75vw'}" [style]="{width: '43vw'}" [draggable]="false" [resizable]="false"
        [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
        <p>Exposure type already exists.</p>
        <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
          <p-button (click)="showHideExistingValidationPopUp = false" label="OK"
            styleClass="btn btn-primary"></p-button>
        </ng-template>
      </p-dialog>

      <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
        [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
        [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
        <p>{{_confirmBox.message}}</p>
        <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
          <p-button *ngIf="unAthorizesd == ''" (click)="_confirmBox.isVisible = false" label="No"
            styleClass="btn btn-outline-primary">
          </p-button>
          <p-button *ngIf="!deleteGLRatingBasisData" (click)="handleWCRating('trash', false, deleteIndex)" label="Yes"
            styleClass="btn btn-primary">
          </p-button>
          <p-button *ngIf="deleteGLRatingBasisData" (click)="handleGLRatingBasis('trash', false, deleteIndex)"
            label="Yes" styleClass="btn btn-primary">
          </p-button>
        </ng-template>
      </p-dialog>
    </div>
  </div>
</div>
