<div class="mid-con">
  <div class="tab-content py-0">
    <div class="tab-pane fade show active">
      <div class="pro-right-con">
        <div class="row">
          <div class="col-12 wbaHeadding">
            <div class="hd-width">
              <h2>SD Approval</h2>
              <a class="email-btn me-4" (click)="openeEmailTemplateModal()"
                [ngClass]="{'isDisabled':wbaDetails?.outcome == 'Lost' || wbaDetails?.wbaStatusDescription == 'Won'|| wbaDetails?.wbaStatusDescription =='Lost' || isAssesmentParamsHasMandatoryValues}">
                <img src="assets/images/email-s.png" alt="Submissions Email" />Submissions Email
              </a>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12">
            <div class="row" *ngIf="showError">
              <div class="col-12">
                <div class="alert alert-danger alert-dismissible fade show my-2 " role="alert">
                  <ul class="view-error">
                    <li *ngFor="let err of errorList">{{err}}</li>
                  </ul>
                  <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                    aria-label="Close"></button>
                </div>
              </div>
            </div>
            <div class="card-text row addw" id="tb-h">
              <div class="row frmrow add-wba-form my-2">
                <form>

                  <table class="table wba-tbl" id="response" *ngIf="acordWBA">
                    <thead class="table wba-tbl">
                      <tr>
                        <th>Acord/WBA</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>User</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of AcordResponse ">
                        <td>{{item.requestType}}</td>
                        <td>
                          <div class="flex-div">
                            <img *ngIf="item.requestStatus=='Requested'" src="assets/images/requested.svg" alt=""
                              class="me-2 " />
                            <img *ngIf="item.requestStatus=='Pending'" src="assets/images/pending.svg" alt=""
                              class="me-2 " />
                            <img *ngIf="item.requestStatus=='Incomplete Information'"
                              src="assets/images/incomplete-info.svg" alt="Incomplete Information" class="me-2 " />
                            <img *ngIf="item.requestStatus=='Incomplete Contact'"
                              src="assets/images/incomplete-contact.svg" alt="Incomplete Contact" class="me-2 " />
                            <img *ngIf="item.requestStatus=='Not Needed'" src="assets/images/not-needed.svg"
                              alt="Not Needed" class="me-2 " />
                            <img *ngIf="item.requestStatus=='Completed'" src="assets/images/completed.svg" alt=""
                              class="me-2" />

                            <img *ngIf="item.requestStatus=='Approved'" src="assets/images/approved.svg" alt="Approved"
                              class="me-2 " />
                            <img *ngIf="item.requestStatus=='Denied'" src="assets/images/denied.svg" alt="denied"
                              class="me-2 " />
                            <img *ngIf="item.requestStatus=='Incomplete'" src="assets/images/incomplete.svg"
                              alt="Incomplete" class="me-2 " />
                            {{item.requestStatus}}
                          </div>
                        </td>
                        <td>
                          <div class="flex-div"><img *ngIf="item.requestStatus!='Pending'"
                              src="assets/images/calendar.svg" class="me-2" width="13px" />{{item.requestDate | date}}
                          </div>
                        </td>
                        <td>
                          <div class="flex-div"><img *ngIf="item.requestStatus!='Pending'" src="assets/images/user.svg"
                              alt="" class="me-2" width="12px" />{{item.createdByUserName}}</div>
                        </td>
                      </tr>
                      <tr class="pending" *ngIf="marketingReview">
                        <td>Marketing Review</td>
                        <td>
                          <div class="flex-div"><img src="assets/images/pending.svg" alt="" class="me-2" /> Pending
                          </div>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr *ngIf="SDRSMapproval">
                        <td colspan="4" class="expandRow">
                          <div class="expandRow-con ">
                            <div class="row" *ngIf="rsmApprovalDropdowns">
                              <div class="col-12 col-sm-12 col-md-6" *ngFor="let item of wbaFileResponse">
                                <div class="form-group row align-items-center">
                                  <label class="form-label me-4 col-2" [for]="item?.fileType">{{item?.fileType ==
                                    "Accord" ? 'Acord' : item?.fileType}}</label>
                                  <div class="select-div col-4">
                                    <select class="form-select l-3" disabled>
                                      <option [value]="item?.fileStatus">
                                        {{ item?.fileStatus }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row wba-sec">
                              <div class="col-12 col-sm-12 col-md-6 ">
                                <div class="form-group row ">
                                  <label class="form-label me-4 col-2" for="WBAapproval">WBA Approval</label>
                                  <div class="select-div col-4">
                                    <select class="form-select l-3" #wba id="wba_approval"
                                      (change)="setWbaApproval(wba.value)">
                                      <option value="none" selected disabled hidden>Select</option>
                                      <option *ngFor="let option of wba_approval" [value]="option.label">
                                        {{ option.label }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div class="col-12 col-sm-12 col-md-6 " *ngIf="selectWbaApproval == 'Approved'">
                                <div class="form-group row ">
                                  <label class="form-label me-4 col-2" for="ProfitCenter">Profit Center</label>
                                  <div class="select-div col-4">
                                    <select class="form-select l-3" #ProfitCenter id="wba_profitCenter"
                                      (change)="typeOfEvent($event)">
                                      <option *ngFor="let option of wba_profitCenter" [value]="option.label"
                                        [selected]="SelectedvalueforProfitCenter == option.label">
                                        {{ option.label }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="selectWbaApproval=='Denied'" class="col-12 col-sm-12 col-md-6">
                                <div class="form-group row align-items-center">
                                  <label class="form-label me-4 col-2" for="Review">Comment</label>
                                  <div class="select-div col-4">
                                    <select class="form-select l-3" #review id="Review"
                                      (change)="setComments(review.value)">
                                      <option value="none" selected disabled hidden>Select</option>
                                      <option *ngFor="let item of reasonResponse" [value]="item.reason">
                                        {{ item.reason }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="mt-2 col-12 col-sm-12 col-md-12 text-right">
                                <!-- <button class="btn btn-primary me-2" *ngIf="acordStatus == 'Not Needed'"
                                  (click)="createPolicyInEPIC()"
                                  [disabled]="wbaDetails?.outcome == 'Lost' || wbaDetails?.outcome =='Won' || selectWbaApproval == 'Denied'">Create
                                  Policy In EPIC</button> -->
                                <button class="btn btn-primary" (click)="updateAccessReview()"
                                  [disabled]="wbaDetails?.outcome == 'Lost' || wbaDetails?.outcome =='Bound' || this.wbaDetails?.wbaStatusDescription == 'Won' || this.wbaDetails?.wbaStatusDescription == 'Lost' || isAssesmentParamsHasMandatoryValues">Submit</button>

                              </div>
                            </div>
                          </div>

                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- WBA Table -->
                  <table class="table wba-tbl" id="response" *ngIf="wbaReviewTable">
                    <thead class="table wba-tbl">
                      <tr>
                        <th [width]="this.wbaResponse[0]?.wbaReviewStatus == 'Incomplete' ? '26%' : '100%'">WBA Review
                          Status</th>
                        <th width="74%" *ngIf="this.wbaResponse[0]?.wbaReviewStatus == 'Incomplete'">Reason</th>
                        <!-- <th>Reminder Days</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of wbaResponse ">
                        <td>{{item.wbaReviewStatus}}</td>
                        <td *ngIf="this.wbaResponse[0]?.wbaReviewStatus == 'Incomplete'">
                          <div class="flex-div des-text-div">
                            {{item?.wbaReviewStatusComment ? item?.wbaReviewStatusComment : 'N/A'}}
                          </div>
                        </td>
                        <!-- <td>{{item.reminderDays == 0 ? 'N/A' : item.reminderDays}}</td> -->
                      </tr>
                    </tbody>
                  </table>
                </form>
                <p-dialog [header]="_warningBox.header2" [modal]="true" [(visible)]="_warningBox.isVisible"
                  [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false"
                  [resizable]="false" [styleClass]="_warningBox.borderColor" *ngIf="_warningBox">
                  <p>{{ _warningBox.message }}</p>
                  <ng-template pTemplate="footer" *ngIf="_warningBox.hasFooter">
                    <p-button (click)="warningBoxCancel()" label="OK" styleClass="btn btn-primary"></p-button>
                  </ng-template>
                </p-dialog>
                <p-dialog [header]="_warningBox1.header2" [modal]="true" [(visible)]="_warningBox1.isVisible"
                  [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false"
                  [resizable]="false" [styleClass]="_warningBox1.borderColor" *ngIf="_warningBox1">
                  <p [innerHTML]="errorMessage1"></p>
                  <ng-template pTemplate="footer" *ngIf="_warningBox1.hasFooter">
                    <p-button (click)="_warningBox1.isVisible = false" label="OK"
                      styleClass="btn btn-primary"></p-button>
                  </ng-template>
                </p-dialog>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>