<div class="mid-con">
  <div class="pro-detail">
    <div class="tab-pane fade show active">
      <div class="pro-right-con">
        <div class="row">

          <div class="col-12 wbaHeadding">

            <div class="hd-width">
              <h2>Submissions</h2>

              <div class="flex-div" *ngIf="isSubmissionValidUser">
                <button class="btn btn-text me-4" (click)="uploadEpicFilesOnEfileCabinet()" *ngIf="wbaTransType == 'RN'"
                  [disabled]="(isAssesmentParamsHasMandatoryValues && wbaDetails?.transType == 'NB')|| (!issubmissionRequest && wbaDetails?.transType == 'RN')">
                  Copy files from
                  EPIC to eFile </button>
                <button class="btn btn-text me-4" (click)="openeEmailTemplateModal()"
                  [disabled]="wbaOutcome === 'Won' || wbaOutcome === 'Lost' || wbaOutcome === 'Bind Request' || (wbaReviewStatus != 'Complete' && wbaAcordProgress == true && wbaDetails?.transType == 'NB' ) || (isAssesmentParamsHasMandatoryValues && wbaDetails?.transType == 'NB') || (!issubmissionRequest && wbaDetails?.transType == 'RN')">
                  <img src="../../../../../../../assets/images/email-sub.svg" class="email"
                    alt="Select Email Template" />
                  Select Email Template
                </button>

                <button class="btn btn-text me-2" (click)="openLookupModal('expiringCarrier')"
                  [disabled]="(wbaReviewStatus != 'Complete' && wbaAcordProgress == true && wbaDetails?.transType == 'NB') || wbaOutcome === 'Lost' || wbaOutcome === 'Won' || (isAssesmentParamsHasMandatoryValues && wbaDetails?.transType == 'NB') || (!issubmissionRequest && wbaDetails?.transType == 'RN')">
                  <img src="../../../../../../../assets/images/plus.svg" class="addC" alt="Add Carrier" />
                  Add Carrier
                </button>
                <button class="btn btn-primary me-4" (click)="openSendCarrierConfirmationModal('expiringCarrier')"
                  [disabled]="wbaDetails?.outcome == 'Lost' || wbaDetails?.wbaStatusDescription == 'Won'|| wbaDetails?.wbaStatusDescription =='Lost' || (wbaReviewStatus != 'Complete' && wbaAcordProgress == true && wbaDetails?.transType == 'NB' ) || (wbaDetails?.outcome === 'Bound' || wbaDetails?.outcome === 'Lost' || wbaDetails?.outcome === 'Bind Request') || (isAssesmentParamsHasMandatoryValues && wbaDetails?.transType == 'NB') || (!issubmissionRequest && wbaDetails?.transType == 'RN')">
                  Send to selected
                </button>

                <!-- <button class="btn btn-primary sendtoSel" (click)="sendMailToCarriers('send')">
                  Send Carriers Mail
                </button> -->

              </div>
            </div>

          </div>
          <div class="col-12 col-sm-12 col-md-12">
            <div class="card-text row addw">

              <div class="row frmrow add-wba-form mt-0">
                <div class="col-12 col-sm-12 col-md-12 mb-3">
                  <div class="alert alert-danger alert-dismissible fade show alert-wba" role="alert"
                    *ngIf="!issubmissionRequest && wbaDetails && wbaDetails?.transType == 'RN'">
                    <ul class="view-error">
                      <li>Access denied, your permissions do not allow you to proceed.</li>
                    </ul>
                    <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                      aria-label="Close"></button>
                  </div>
                </div>

                <div class="row frmrow add-wba-form mt-0" *ngIf="showError">
                  <div class="col-12 col-sm-12 col-md-12 mb-3">
                    <div class="alert alert-danger alert-dismissible fade show alert-wba" role="alert">
                      <ul class="view-error">
                        <li *ngFor="let err of errorList">{{err}}</li>
                      </ul>
                      <button type="button" class="btn-close" (click)="closeErrorsubmission()" data-bs-dismiss="alert"
                        aria-label="Close"></button>
                    </div>
                  </div>
                </div>

                <div class="accordion wba-acc-sub" id="accordionPanelsStayOpenExample" [formGroup]="submissionForm">
                  <div class="accordion-item">


                    <h2 class="accordion-header" id="heading2">
                      <div class="sub-check acchdr">
                        <div class="form-check">

                        </div>
                      </div>
                      <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                        Internal Users
                      </button>
                    </h2>
                  </div>
                  <div id="collapse1" class="accordion-collapse show" aria-labelledby="heading1"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div class="sub-row">
                        <div class="sub-check">
                          <div class="form-check">
                            <!-- <input class="form-check-input" type="checkbox" value="" id="check1"> -->
                          </div>
                        </div>
                        <div class="submission-outer">
                          <div class="submission-dtl">
                            <p-multiSelect [options]="userList" formControlName="selectedUserLabel" [filter]="true"
                              optionLabel="display_name" optionValue="userId" placeholder="Select Internal Users"
                              (onChange)="onUserListChange($event)" [showToggleAll]="false"
                              [disabled]="!isSubmissionValidUser" selectedItemsLabel="No. of Selected Users: {0}"
                              [maxSelectedLabels]="0">
                            </p-multiSelect>

                            <div class="multi-select-tags">
                              <ng-container *ngFor="let user of selectedUserList; let i = index">
                                <span class="selected-item">{{user.display_name}}
                                  <img *ngIf="loginUserId != user.id" [ngClass]="{'isDisabled': !isSubmissionValidUser}"
                                    src="assets/images/close-circle.svg" alt="" (click)="removeUser(user, i)" /></span>
                              </ng-container>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <ng-container *ngFor="let tierList of wbaCarriers; let tierIndex = index;">
                    <ng-container *ngIf="tierList.tierName == 'Preferred-1'">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="heading2">
                          <div class="sub-check acchdr">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value=""
                                name="{{tierList.tierName}}|carriers" id="{{tierList.tierName}}|carrier"
                                (change)="onParentCarrierClick($event.target,tierList)" [checked]="tierList.isChecked">
                            </div>
                          </div>
                          <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapsePreferred1" aria-expanded="false"
                            aria-controls="collapsePreferred1">
                            {{tierList.tierName}} - Carriers
                          </button>
                        </h2>
                      </div>
                      <div id="collapsePreferred1" class="accordion-collapse collapse show" aria-labelledby="heading2"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <ng-container
                            *ngFor="let carrier of tierList.wbaCarrierListByTierGroup; let carrierIndex = index;">
                            <div class="sub-row">
                              <div class="sub-check">
                                <div class="form-check">
                                  <input class="form-check-input" type="checkbox" value=""
                                    name="{{carrier.id}}|carriers" id="{{carrier.id}}|carrier"
                                    (change)="onCarrierClick($event.target,carrier, tierList, carrierIndex)"
                                    [disabled]="carrier.isDisplaySentButton" [checked]="carrier.isChecked">

                                </div>
                              </div>
                              <div class="submission-outer">
                                <div class="sub-cont">
                                  <p class="CRName">{{carrier.carrierName}}</p>
                                  <div class="sub-rht-action">
                                    <button *ngIf="isSubmissionValidUser" class="add-rht-btn"
                                      (click)="openContactModal(carrier)">Add
                                      <img src="assets/images/add-user.svg" alt="" /></button>
                                    <ng-container *ngIf="carrier.isDisplaySentButton == true">
                                      <label class="sent-rht-btn">Sent</label>
                                    </ng-container>
                                  </div>
                                </div>
                                <div class="submission-dtl">
                                  <p-multiSelect name="{{carrier.contact.id}}{{ carrierIndex }}"
                                    [options]="carrier.contact" id="{{ carrier.id }}{{ carrierIndex }}"
                                    [formControlName]="getFormControlName(carrier.id)" [filter]="true"
                                    optionLabel="contactNameWithInfo" optionValue="id" optionDisabled="isMailSent"
                                    placeholder="Select Contacts"
                                    (onChange)="onContactClick($event, carrier, carrierIndex)" [showToggleAll]="false"
                                    selectedItemsLabel="No. of Selected Contacts: {0}" [maxSelectedLabels]="0"
                                    [disabled]="!isSubmissionValidUser">
                                  </p-multiSelect>
                                  <div class="multi-select-tags">
                                    <!-- Display sent mail contacts -->
                                    <ng-container
                                      *ngFor="let contactNameRow of carrier.contact; let contactNameIndex = index;">
                                      <ng-container
                                        *ngIf="contactNameRow.isChecked == true && contactNameRow.isMailSent == true">
                                        <span class="selected-item disabled-item"> {{contactNameRow.firstName ?
                                          contactNameRow.firstName + ' ' +
                                          contactNameRow.lastName : contactNameRow.contactNameWithInfo }} <img
                                            src="assets/images/checkmark-circle.svg" alt="" /></span>
                                      </ng-container>
                                    </ng-container>
                                    <!-- Display current selected contacts -->
                                    <ng-container
                                      *ngFor="let contactNameRow of carrier.contact; let contactNameIndex = index;">
                                      <ng-container
                                        *ngIf="contactNameRow.isChecked == true && contactNameRow.isMailSent == false">
                                        <span class="selected-item"> {{contactNameRow.firstName ?
                                          contactNameRow.firstName + ' ' +
                                          contactNameRow.lastName : contactNameRow.contactNameWithInfo }} <img
                                            src="assets/images/close-circle.svg" alt=""
                                            (click)="removeContact(contactNameRow, tierList.tierName, carrier, carrierIndex)" /></span>
                                      </ng-container>
                                    </ng-container>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-container>

                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="tierList.tierName == 'Preferred-2'">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="heading3">
                          <div class="sub-check acchdr">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value=""
                                name="{{tierList.tierName}}|carriers" id="{{tierList.tierName}}|carrier"
                                (change)="onParentCarrierClick($event.target,tierList)" [checked]="tierList.isChecked">

                            </div>
                          </div>
                          <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                            {{tierList.tierName}} - Carriers
                          </button>
                        </h2>
                      </div>
                      <div id="collapse3" class="accordion-collapse collapse show" aria-labelledby="heading3"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <ng-container
                            *ngFor="let carrier of tierList.wbaCarrierListByTierGroup; let carrierIndex = index;">

                            <div class="sub-row">
                              <div class="sub-check">
                                <div class="form-check">
                                  <input class="form-check-input" type="checkbox" value=""
                                    name="{{carrier.id}}|carriers" id="{{carrier.id}}|carrier"
                                    (change)="onCarrierClick($event.target,carrier, tierList, carrierIndex)"
                                    [disabled]="carrier.isDisplaySentButton" [checked]="carrier.isChecked">
                                </div>
                              </div>
                              <div class="submission-outer">
                                <div class="sub-cont">
                                  <p class="CRName"> {{carrier.carrierName}} </p>
                                  <div class="sub-rht-action">
                                    <button *ngIf="isSubmissionValidUser" class="add-rht-btn"
                                      (click)="openContactModal(carrier)">Add
                                      <img src="assets/images/add-user.svg" alt="" /></button>
                                    <ng-container *ngIf="carrier.isDisplaySentButton == true">
                                      <label class="sent-rht-btn">Sent</label>
                                    </ng-container>
                                  </div>
                                </div>
                                <div class="submission-dtl">
                                  <p-multiSelect [options]="carrier.contact"
                                    [formControlName]="getFormControlName(carrier.id)" [filter]="true"
                                    optionLabel="contactNameWithInfo" optionValue="id" optionDisabled="isMailSent"
                                    placeholder="Select Contacts"
                                    (onChange)="onContactClick($event, carrier, carrierIndex)" [showToggleAll]="false"
                                    selectedItemsLabel="No. of Selected Contacts: {0}" [maxSelectedLabels]="0"
                                    [disabled]="!isSubmissionValidUser">
                                  </p-multiSelect>
                                  <div class="multi-select-tags">
                                    <ng-container
                                      *ngFor="let contactNameRow of carrier.contact; let contactNameIndex = index;">
                                      <ng-container
                                        *ngIf="contactNameRow.isChecked == true && contactNameRow.isMailSent == true">
                                        <span class="selected-item disabled-item"> {{contactNameRow.firstName ?
                                          contactNameRow.firstName + ' ' +
                                          contactNameRow.lastName : contactNameRow.contactNameWithInfo }} <img
                                            src="assets/images/checkmark-circle.svg" alt="" /></span>
                                      </ng-container>
                                    </ng-container>

                                    <ng-container
                                      *ngFor="let contactNameRow of carrier.contact; let contactNameIndex = index;">
                                      <ng-container
                                        *ngIf="contactNameRow.isChecked == true && contactNameRow.isMailSent == false">
                                        <span class="selected-item"> {{contactNameRow.firstName ?
                                          contactNameRow.firstName + ' ' +
                                          contactNameRow.lastName : contactNameRow.contactNameWithInfo }} <img
                                            src="assets/images/close-circle.svg" alt=""
                                            (click)="removeContact(contactNameRow, tierList.tierName, carrier, carrierIndex)" /></span>
                                      </ng-container>
                                    </ng-container>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-container>

                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="tierList.tierName == 'Preferred-3'">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="heading4">
                          <div class="sub-check acchdr">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value=""
                                name="{{tierList.tierName}}|carriers" id="{{tierList.tierName}}|carrier"
                                (change)="onParentCarrierClick($event.target,tierList)" [checked]="tierList.isChecked">

                            </div>
                          </div>
                          <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapsePreferred4" aria-expanded="false"
                            aria-controls="collapsePreferred4">
                            {{tierList.tierName}} - Carriers
                          </button>
                        </h2>
                      </div>
                      <div id="collapsePreferred4" class="accordion-collapse collapse show" aria-labelledby="heading4"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <ng-container
                            *ngFor="let carrier of tierList.wbaCarrierListByTierGroup; let carrierIndex = index;">

                            <div class="sub-row">
                              <div class="sub-check">
                                <div class="form-check">
                                  <input class="form-check-input" type="checkbox" value=""
                                    name="{{carrier.id}}|carriers" id="{{carrier.id}}|carrier"
                                    (change)="onCarrierClick($event.target,carrier, tierList, carrierIndex)"
                                    [disabled]="carrier.isDisplaySentButton" [checked]="carrier.isChecked">
                                </div>
                              </div>
                              <div class="submission-outer">
                                <div class="sub-cont">
                                  <p class="CRName">{{carrier.carrierName}}</p>
                                  <div class="sub-rht-action">
                                    <button *ngIf="isSubmissionValidUser" class="add-rht-btn"
                                      (click)="openContactModal(carrier)">Add
                                      <img src="assets/images/add-user.svg" alt="" /></button>
                                    <ng-container *ngIf="carrier.isDisplaySentButton == true">
                                      <label class="sent-rht-btn">Sent</label>
                                    </ng-container>
                                  </div>
                                </div>
                                <div class="submission-dtl">
                                  <p-multiSelect [options]="carrier.contact" id="{{ carrier.id }}{{ carrierIndex }}"
                                    [formControlName]="getFormControlName(carrier.id)" [filter]="true"
                                    optionLabel="contactNameWithInfo" optionValue="id" optionDisabled="isMailSent"
                                    placeholder="Select Contacts"
                                    (onChange)="onContactClick($event, carrier, carrierIndex)" [showToggleAll]="false"
                                    selectedItemsLabel="No. of Selected Contacts: {0}" [maxSelectedLabels]="0"
                                    [disabled]="!isSubmissionValidUser">
                                  </p-multiSelect>

                                  <div class="multi-select-tags">
                                    <ng-container
                                      *ngFor="let contactNameRow of carrier.contact; let contactNameIndex = index;">
                                      <ng-container
                                        *ngIf="contactNameRow.isChecked == true && contactNameRow.isMailSent == true">
                                        <span class="selected-item disabled-item"> {{contactNameRow.firstName ?
                                          contactNameRow.firstName + ' ' +
                                          contactNameRow.lastName : contactNameRow.contactNameWithInfo }} <img
                                            src="assets/images/checkmark-circle.svg" alt="" /></span>
                                      </ng-container>
                                    </ng-container>

                                    <ng-container
                                      *ngFor="let contactNameRow of carrier.contact; let contactNameIndex = index;">
                                      <ng-container
                                        *ngIf="contactNameRow.isChecked == true && contactNameRow.isMailSent == false">
                                        <span class="selected-item"> {{contactNameRow.firstName ?
                                          contactNameRow.firstName + ' ' +
                                          contactNameRow.lastName : contactNameRow.contactNameWithInfo }} <img
                                            src="assets/images/close-circle.svg" alt=""
                                            (click)="removeContact(contactNameRow, tierList.tierName, carrier, carrierIndex)" /></span>
                                      </ng-container>
                                    </ng-container>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="tierList.tierName == 'Preferred-4'">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="heading5">
                          <div class="sub-check acchdr">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value=""
                                name="{{tierList.tierName}}|carriers" id="{{tierList.tierName}}|carrier"
                                (change)="onParentCarrierClick($event.target,tierList)" [checked]="tierList.isChecked">

                            </div>
                          </div>
                          <button class="accordion-button " type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                            {{tierList.tierName}} - Carriers
                          </button>
                        </h2>
                      </div>
                      <div id="collapse5" class="accordion-collapse collapse show" aria-labelledby="heading5"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <ng-container
                            *ngFor="let carrier of tierList.wbaCarrierListByTierGroup; let carrierIndex = index;">

                            <div class="sub-row">
                              <div class="sub-check">
                                <div class="form-check">
                                  <input class="form-check-input" type="checkbox" value=""
                                    name="{{carrier.id}}|carriers" id="{{carrier.id}}|carrier"
                                    (change)="onCarrierClick($event.target,carrier, tierList, carrierIndex)"
                                    [disabled]="carrier.isDisplaySentButton" [checked]="carrier.isChecked">
                                </div>
                              </div>
                              <div class="submission-outer">
                                <div class="sub-cont">
                                  <p class="CRName">{{carrier.carrierName}}</p>
                                  <div class="sub-rht-action">
                                    <button *ngIf="isSubmissionValidUser" class="add-rht-btn"
                                      (click)="openContactModal(carrier)">Add
                                      <img src="assets/images/add-user.svg" alt="" /></button>
                                    <ng-container *ngIf="carrier.isDisplaySentButton == true">
                                      <label class="sent-rht-btn">Sent</label>
                                    </ng-container>
                                  </div>
                                </div>
                                <div class="submission-dtl">
                                  <p-multiSelect [options]="carrier.contact" id="{{ carrier.id }}{{ carrierIndex }}"
                                    [formControlName]="getFormControlName(carrier.id)" [filter]="true"
                                    optionLabel="contactNameWithInfo" optionValue="id" optionDisabled="isMailSent"
                                    placeholder="Select Contacts"
                                    (onChange)="onContactClick($event, carrier, carrierIndex)" [showToggleAll]="false"
                                    selectedItemsLabel="No. of Selected Contacts: {0}" [maxSelectedLabels]="0"
                                    [disabled]="!isSubmissionValidUser">
                                  </p-multiSelect>
                                  <div class="multi-select-tags">

                                    <ng-container
                                      *ngFor="let contactNameRow of carrier.contact; let contactNameIndex = index;">
                                      <ng-container
                                        *ngIf="contactNameRow.isChecked == true && contactNameRow.isMailSent == true">
                                        <span class="selected-item disabled-item"> {{contactNameRow.firstName ?
                                          contactNameRow.firstName + ' ' +
                                          contactNameRow.lastName : contactNameRow.contactNameWithInfo }} <img
                                            src="assets/images/checkmark-circle.svg" alt="" /></span>
                                      </ng-container>
                                    </ng-container>

                                    <ng-container
                                      *ngFor="let contactNameRow of carrier.contact; let contactNameIndex = index;">
                                      <ng-container
                                        *ngIf="contactNameRow.isChecked == true && contactNameRow.isMailSent == false">
                                        <span class="selected-item"> {{contactNameRow.firstName ?
                                          contactNameRow.firstName + ' ' +
                                          contactNameRow.lastName : contactNameRow.contactNameWithInfo }} <img
                                            src="assets/images/close-circle.svg" alt=""
                                            (click)="removeContact(contactNameRow, tierList.tierName, carrier, carrierIndex)" /></span>
                                      </ng-container>
                                    </ng-container>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="tierList.tierName == 'Others'">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="heading6">
                          <div class="sub-check acchdr">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value=""
                                name="{{tierList.tierName}}|carriers" id="{{tierList.tierName}}|carrier"
                                (change)="onParentCarrierClick($event.target,tierList)" [checked]="tierList.isChecked">

                            </div>
                          </div>
                          <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                            {{tierList.tierName}} - Carriers
                          </button>
                        </h2>
                      </div>
                      <div id="collapse6" class="accordion-collapse collapse show" aria-labelledby="heading6"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <ng-container
                            *ngFor="let carrier of tierList.wbaCarrierListByTierGroup; let carrierIndex = index;">

                            <div class="sub-row">
                              <div class="sub-check">
                                <div class="form-check">
                                  <input class="form-check-input" type="checkbox" value=""
                                    name="{{carrier.id}}|carriers" id="{{carrier.id}}|carrier"
                                    (change)="onCarrierClick($event.target,carrier, tierList, carrierIndex)"
                                    [disabled]="carrier.isDisplaySentButton" [checked]="carrier.isChecked">
                                </div>
                              </div>
                              <div class="submission-outer">
                                <div class="sub-cont">
                                  <p class="CRName">{{carrier.carrierName}}</p>
                                  <div class="sub-rht-action">
                                    <button *ngIf="isSubmissionValidUser" class="add-rht-btn"
                                      (click)="openContactModal(carrier)">Add
                                      <img src="assets/images/add-user.svg" alt="" /></button>
                                    <ng-container *ngIf="carrier.isDisplaySentButton == true">
                                      <label class="sent-rht-btn">Sent</label>
                                    </ng-container>
                                  </div>
                                </div>
                                <div class="submission-dtl">
                                  <p-multiSelect [options]="carrier.contact" id="{{ carrier.id }}{{ carrierIndex }}"
                                    [formControlName]="getFormControlName(carrier.id)" [filter]="true"
                                    optionLabel="contactNameWithInfo" optionValue="id" optionDisabled="isMailSent"
                                    placeholder="Select Contacts"
                                    (onChange)="onContactClick($event, carrier, carrierIndex)" [showToggleAll]="false"
                                    selectedItemsLabel="No. of Selected Contacts: {0}" [maxSelectedLabels]="0"
                                    [disabled]="!isSubmissionValidUser">
                                  </p-multiSelect>

                                  <div class="multi-select-tags">
                                    <ng-container
                                      *ngFor="let contactNameRow of carrier.contact; let contactNameIndex = index;">
                                      <ng-container
                                        *ngIf="contactNameRow.isChecked == true && contactNameRow.isMailSent == true">
                                        <span class="selected-item disabled-item"> {{contactNameRow.firstName ?
                                          contactNameRow.firstName + ' ' +
                                          contactNameRow.lastName : contactNameRow.contactNameWithInfo }} <img
                                            src="assets/images/checkmark-circle.svg" alt="" /></span>
                                      </ng-container>
                                    </ng-container>

                                    <ng-container
                                      *ngFor="let contactNameRow of carrier.contact; let contactNameIndex = index;">
                                      <ng-container
                                        *ngIf="contactNameRow.isChecked == true && contactNameRow.isMailSent == false">
                                        <span class="selected-item"> {{contactNameRow.firstName ?
                                          contactNameRow.firstName + ' ' +
                                          contactNameRow.lastName : contactNameRow.contactNameWithInfo }} <img
                                            src="assets/images/close-circle.svg" alt=""
                                            (click)="removeContact(contactNameRow, tierList.tierName, carrier, carrierIndex)" /></span>
                                      </ng-container>
                                    </ng-container>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>

                  </ng-container>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

      <!-- Dialog for error -->
      <p-dialog [header]="_errorBox.header" [modal]="true" [(visible)]="_errorBox.isVisible"
        [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
        [styleClass]="_errorBox.borderColor" *ngIf="_errorBox">
        <p>{{_errorBox.message}}</p>
        <ng-template pTemplate="footer">
          <p-button (click)="errorBoxCancel()" label="OK" styleClass="btn btn-primary"></p-button>
        </ng-template>
      </p-dialog>

      <p-dialog [header]="_confirmBox.header2" [modal]="true" [(visible)]="_confirmBox.isVisible"
        [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
        [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
        <p>{{ _confirmBox.message }}</p>
        <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
          <p-button (click)="warningBoxCancel()" label="OK" styleClass="btn btn-primary"></p-button>
        </ng-template>
      </p-dialog>
    </div>
  </div>
</div>