<div class="mid-con">
  <div class="tab-content py-0">
    <div class="tab-pane fade show active">
      <div class="pro-right-con">
        <div class="pro-right-con">
          <div class="row">
            <div class="col-12 wbaHeadding">
              <div class="hd-width">
                <h2>General Policy Details</h2>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12">
              <div class="card-text row addw">
                <div class="row frmrow add-wba-form">
                  <div class="col-12 col-sm-12 col-md-12 has-validation frm-mrg">
                    <label class="form-label" for="policyDescription">Policy Description <span>*</span></label>
                    <textarea class="policy-textarea" id="policyDescription"
                      [(ngModel)]="whiteBoardForm.policyDescription" rows="1" #description
                      placeholder="Policy Description" (change)="ChangeDescription(description.value)"
                      resize></textarea>
                    <div *ngIf="!whiteBoardForm.policyDescription && isSubmitted" class="display-error">
                      Required
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12  has-validation frm-mrg">
                    <label class="form-label" for="operationDescription">Operation
                      Description<span></span></label>
                    <textarea placeholder="Operation Description" id="operationDescription" class="policy-textarea"
                      [(ngModel)]="whiteBoardForm.operationDescription" rows="6"> </textarea>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 has-validation frm-mrg">
                    <label class="form-label" for="internalNotes">Internal Notes<span></span></label>
                    <textarea placeholder="Internal Notes" id="internalNotes" class="policy-textarea"
                      [(ngModel)]="whiteBoardForm.internalNotes" rows="4"></textarea>
                    <div class="display-error">
                      <span *ngIf="whiteBoardForm.internalNotes?.length > 6000">
                        {{_AddNoteScreen.InternalNotes.maxlength}}
                      </span>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 has-validation frm-mrg">
                    <div>
                      <label class="form-label" for="businessReason">Reason Insured will do business with
                        Lamb </label>
                    </div>
                    <textarea placeholder="Reason Insured will do business with Lamb " id="businessReason"
                      class="policy-textarea" [(ngModel)]="whiteBoardForm.businessReason" rows="1"></textarea>
                  </div>
                </div>
              </div>
              <div class="card-text row addw">
                <div class="row frmrow add-wba-form mt-0">
                  <div class="flex-div space-between mb-2 hd-p">
                    <div class="flex-div ">
                      <span class="green_lbl pos_lbl">
                        <img src="../assets/images/calendar-exclamation.png" alt="" width="14px" />
                        <span class="ms-1">Expiring Premium: ${{ApplicationData.expiringPremium}}</span>
                      </span>
                      <span class="orange_lbl pos_lbl ms-4">
                        <img src="../assets/images/chart-pie.png" alt="" width="14px" />
                        <span class="ms-1">Loss Ratio: {{whiteBoardForm.lossRatio | number : '1.2-2'
                          }}</span>
                      </span>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table wba-tbl">
                      <thead>
                        <tr>
                          <th width="15%">Policy Term</th>
                          <th width="18%">Line of Business</th>
                          <th width="15%">
                            Total Incurred
                          </th>
                          <th width="30%">Carrier <img tooltipPosition="bottom" width="14px"
                              tooltipstyleclass="tooltipstyle" src="assets/images/info.png" alt=""
                              pTooltip="{{_userMessages.lookUpInfo}}" class="infoImage ms-2 ts-info"></th>
                          <th>Valuation Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor=" let loss of whiteBoardForm.policyTermDetails; let index=index">
                          <td>
                            <span *ngIf="index===editIndex; else policyString">
                              <select class="form-select" [(ngModel)]="editLossRunForm.policyTerm"
                                (change)="handleData()">
                                <option disabled value="">
                                  Select Policy Term
                                </option>
                                <option *ngFor="let term of policyTermOptions" [value]="term">
                                  {{ term }}
                                </option>
                              </select>
                              <div *ngIf="editLossRunForm.policyTerm==='' && isEditSubmitted" class="display-error">
                                Required
                              </div>
                            </span>
                            <ng-template #policyString> {{loss.policyTerm}}</ng-template>
                          </td>
                          <td>
                            <span *ngIf="index===editIndex; else packageString">
                              <select class="form-select" [(ngModel)]="editLossRunForm.businessLineName"
                                (change)="handleData()">
                                <option disabled value="">
                                  Select Business Line
                                </option>
                                <option *ngFor="let lob of businessLineList" [value]="lob.businessLineName">
                                  {{ lob.businessLineName }}
                                </option>
                              </select>
                              <div *ngIf="editLossRunForm.policyTerm==='' && isEditSubmitted" class="display-error">
                                Required
                              </div>
                            </span>
                            <ng-template #packageString> {{loss.businessLineName}}</ng-template>
                          </td>
                          <td class="text-end">
                            <span *ngIf="index===editIndex; else IncurredString">
                              <div class="pos-re">
                                <span class="dollor-view">$</span>
                                <input class="form-control me-3 dollor-input-view"
                                  [(ngModel)]="editLossRunForm.totalIncured"
                                  (focusout)="addNumberFormat(editLossRunForm)"
                                  (keypress)="ValidateKey.integer($event, 16)"
                                  (focus)="removeNumberFormat(editLossRunForm)"
                                  (focusout)="removeSpaces(editLossRunForm)" (change)="handleData()" />
                              </div>
                              <div *ngIf="!editLossRunForm.totalIncured && isEditSubmitted" class="display-error">
                                Required
                              </div>
                            </span>
                            <ng-template #IncurredString> {{loss.totalIncured |currency:"USD":"symbol":'1.0'}}
                            </ng-template>
                          </td>
                          <td>
                            <span *ngIf="index===editIndex; else CarrierString">
                              <div class="flex-div table-autocomp">
                                <p-autoComplete placeholder="Carrier" [(ngModel)]="editCarrier"
                                  [showEmptyMessage]="true" [suggestions]="expiringCarrierlist" [minLength]="1"
                                  (onSelect)="selectItem($event.value)"
                                  (completeMethod)="getCarriers('expiringCarrier',$event)"
                                  (onClear)="lookUpDataFilterChanges('editCarrier')"
                                  (onDropdownClick)="getCarriers('expiringCarrier',$event)" field="value"
                                  (change)="handleData()">
                                  <ng-template let-carrier pTemplate="item">
                                    <div class="company-item">
                                      <div>{{ carrier.value }}</div>
                                    </div>
                                  </ng-template>
                                </p-autoComplete>
                                <img (click)="
                        openLookupModal('Carrier')
                      " src="assets/images/search.svg" alt="" class="ms-2" />
                              </div>
                              <div
                                *ngIf="(editLossRunForm?.carrierName == '' || editLossRunForm?.carrierName?.value == '') && isEditSubmitted"
                                class="display-error">
                                Required
                              </div>
                            </span>
                            <ng-template #CarrierString> {{loss.carrierName}}</ng-template>
                          </td>
                          <td>
                            <span *ngIf="index===editIndex; else ValuationDateString">
                              <p-calendar class="policyDetail" [(ngModel)]="editLossRunForm.valuationDate"
                                placeholder="MM/DD/YYYY" [showIcon]="true" [iconDisplay]="'input'" dateFormat="mm/dd/yy" inputId="valuationDate"
                                (onSelect)="handleData()"> </p-calendar>
                              <div *ngIf="!editLossRunForm.valuationDate && isEditSubmitted" class="display-error">
                                Required
                              </div>
                            </span>
                            <ng-template #ValuationDateString>
                              {{loss.valuationDate !='01/01/1900' ? loss.valuationDate : ''}}</ng-template>
                          </td>
                          <td>
                            <img *ngIf="editIndex===-1" class="pointer"
                              (click)="handleLossRun('edit',editLossRunForm, index)" src="assets/images/edit.svg"
                              width="13px" alt="" class="me-2" />
                            <img *ngIf="editIndex===-1" class="pointer" (click)="handleLossRun('trash', false, index)"
                              src="assets/images/trash.svg" alt="" />
                            <img *ngIf="editIndex!==-1 && editIndex===index" class="pointer me-2" width=" 13px"
                              (click)="handleLossRun('cross', false, index)" src="assets/images/cross.svg" alt="" />
                            <img *ngIf="editIndex!==-1 && editIndex===index" class="pointer" width=" 13px"
                              (click)="handleLossRun('save', editLossRunForm, index)" src="assets/images/save.svg"
                              alt="" />
                          </td>
                        </tr>
                        <tr *ngIf="editIndex===-1">
                          <td>
                            <select class="form-select" [(ngModel)]="lossRunForm.policyTerm" (change)="handleData()">
                              <option disabled value="">
                                Select Policy Term
                              </option>
                              <option *ngFor="let term of policyTermOptions" [value]="term">
                                {{ term }}
                              </option>
                            </select>
                            <div *ngIf="lossRunForm.policyTerm==='' && isAddSubmitted" class="display-error">
                              Required
                            </div>
                          </td>
                          <td>
                            <select class="form-select" [(ngModel)]="lossRunForm.businessLineName"
                              (change)="handleData()">
                              <option disabled value="">
                                Select Business Line
                              </option>
                              <option *ngFor="let lob of businessLineList" [value]="lob.businessLineName">
                                {{ lob.businessLineName }}
                              </option>
                            </select>
                            <div *ngIf="lossRunForm.policyTerm==='' && isAddSubmitted" class="display-error">
                              Required
                            </div>
                          </td>
                          <td>
                            <div class="pos-re">
                              <span class="dollor-view">$</span>
                              <input class="form-control me-3 dollor-input-view" [(ngModel)]="lossRunForm.totalIncured"
                                (focusout)="addNumberFormat(lossRunForm)" (keypress)="ValidateKey.integer($event, 16)"
                                (focus)="removeNumberFormat(lossRunForm)" (focusout)="removeSpaces(lossRunForm)"
                                (change)="handleData()" />
                            </div>
                            <div *ngIf="!lossRunForm.totalIncured && isAddSubmitted" class="display-error">
                              Required
                            </div>
                          </td>
                          <td>
                            <div class="flex-div table-autocomp">
                              <p-autoComplete placeholder="Carrier" [(ngModel)]="Carrier" [showEmptyMessage]="true"
                                [suggestions]="expiringCarrierlist" [minLength]="1" (onSelect)="
                      selectItem($event.value)" (completeMethod)="getCarriers('expiringCarrier',$event)"
                                (onClear)="lookUpDataFilterChanges('Carrier')"
                                (onDropdownClick)="getCarriers('expiringCarrier',$event)" field="value"
                                (change)="handleData()">
                                <ng-template let-carrier pTemplate="item">
                                  <div class="company-item">
                                    <div>{{ carrier.value }}</div>
                                  </div>
                                </ng-template>
                              </p-autoComplete>
                              <img (click)="
                      openLookupModal('Carrier')
                    " src="assets/images/search.svg" alt="" class="ms-2" />
                            </div>
                            <div
                              *ngIf=" (!lossRunForm.carrierName || lossRunForm.carrierName == '' || lossRunForm.carrierName == undefined ) && isAddSubmitted"
                              class="display-error">
                              Required
                            </div>
                          </td>
                          <td class="table-calendar">
                            <p-calendar class="policyDetail" [(ngModel)]="lossRunForm.valuationDate"
                              placeholder="MM/DD/YYYY" [maxDate]="maximumDate" [showIcon]="true" [iconDisplay]="'input'" dateFormat="mm/dd/yy"
                              inputId="valuationDate" (onSelect)="handleData()"> </p-calendar>
                            <div *ngIf="!lossRunForm.valuationDate && isAddSubmitted" class="display-error">
                              Required
                            </div>
                          </td>
                          <td>
                            <img class="pointer me-2" width=" 13px" (click)="handleLossRun('cross', false, -1)"
                              src="assets/images/cross.svg" alt="" />
                            <img class="pointer" width=" 13px" (click)="handleLossRun('add', lossRunForm, -1)"
                              src="assets/images/save.svg" alt="" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Dialog for confirmation starts -->
<p-dialog [header]="_confirmBox.header2" [modal]="true" [(visible)]="_confirmBox.isVisible"
  [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
  [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
  <p>{{ _confirmBox.message }}</p>
  <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
    <p-button (click)="confirmBoxCancel('ok')" label="OK" styleClass="btn btn-primary"></p-button>
  </ng-template>
</p-dialog>
<!-- Dialog for confirmation ends -->
