import { Component, OnInit } from '@angular/core';
import { SubmissionTemplateComponent } from '../submissionTemplate/submissionTemplate.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatacacheService } from '../../../services/datacache.service';
import { ActivatedRoute } from '@angular/router';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';

@Component({
  selector: 'app-submission-view',
  templateUrl: './submission-view.component.html',
  styleUrls: ['./submission-view.component.css']
})
export class SubmissionViewComponent implements OnInit {
  activeWbaId: any;
  prospectId: any;
  epicUniquePolicyId: any;
  wbaTransType: any;
  wbaDetails: any;
  policyDetails: any;
  CoverageAssessmentData: any;
  industry: any;
  industryList: any;
  glCovgData: any;
  propertyLimitsParams: any;
  constructor(private modalService: NgbModal, private _dataCacheService: DatacacheService, private activeRouter: ActivatedRoute, private _addWBAService: WhiteBoardActivityService) { }

  ngOnInit(): void {
    this.activeRouter.queryParams.subscribe(params => {
      if (params) {
        this.activeWbaId = params.wbaId;
      }
    });

    this.initializeData();
  }

  initializeData() {
    this.policyDetails = this._dataCacheService.retrieveData('wba_policy_DetailsInfo');
    this.wbaDetails = this._dataCacheService.retrieveData('wba_wbaDetailsInfo');
    this.industryList = this._dataCacheService.retrieveData('wba_prospectIndustryList');
    this.getCoverageAssessmentDetails();
  }

  getCoverageAssessmentDetails() {
    this._addWBAService.getCoverageAssessmentDetails(this.activeWbaId, false).subscribe(res => {
      if (res) {
        this.CoverageAssessmentData = res;
        this._dataCacheService.compressAndCacheData('wba_CoverageAssessmentDetails', this.CoverageAssessmentData);
        this.setIndustryAndCoverageData();
      }
    })
  }

  setIndustryAndCoverageData() {
    this.industry = this.industryList.find((res: any) => res.id == this.wbaDetails?.industryId)?.prospectIndustryName;
    this.glCovgData = this.CoverageAssessmentData.coverageAssessmentDetail.find((res: any) => res.coverageName == 'General Liability');

    //getting parameters for limits in property line
    const targetIds = [176, 177, 178]; // Property limits parameters IDs
    this.propertyLimitsParams = this.CoverageAssessmentData.coverageAssessmentDetail.find((res: any) => res?.coverageName == 'Property').coverages?.filter((coverage: any) => targetIds.includes(coverage.coverageParameterId));
  }

  openeEmailTemplateModal() {
    const modalRef = this.modalService.open(SubmissionTemplateComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });

    let data = {
      wbaId: this.activeWbaId,
      prospectId: this.wbaDetails?.prospectId,
      epicUniquePolicyId: this.wbaDetails?.epicUniquePolicyId,
      wbaTransType: this.wbaDetails?.transType,
      priorPolicyId: this.wbaDetails?.priorPolicyId
    }
    modalRef.componentInstance.wbaIdData = data
  }
}
