<div class="mid-con">
  <div class="tab-content py-0">
    <div class="tab-pane fade show active">
      <div class="pro-right-con">
        <div class="row">

          <div class="col-12 wbaHeadding">
            <div class="hd-width">
              <h2>Marketing Review</h2>
              <a class="email-btn me-4" (click)="openeEmailTemplateModal()"
                [ngClass]="{'isDisabled':wbaDetails?.outcome == 'Lost'|| wbaDetails?.outcome =='Bound' || wbaDetails?.wbaStatusDescription == 'Won'|| wbaDetails?.wbaStatusDescription =='Lost' || isAssesmentParamsHasMandatoryValues}">

                <img src="assets/images/email-s.png" alt="Submissions Email" />Submissions Email
              </a>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-12">
            <div class="card-text row addw" id="tb-h">
              <div class="row frmrow add-wba-form my-2">
                <form>

                  <!-- <div>
                    <h6><label for="Review Status" style="color: #122B74;" class="fw-bold mb-3">Review
                        Status</label>
                    </h6>
                  </div> -->
                  <table class="table wba-tbl" id="response">
                    <thead class="table wba-tbl">
                      <tr>
                        <th>Acord/WBA</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>User</th>
                      </tr>

                    </thead>
                    <tbody>
                      <tr *ngFor="let item of AcordResponse ">
                        <td>{{item.requestType}}</td>
                        <td>
                          <div class="flex-div"><img
                              *ngIf="item?.requestStatus=='Requested' || item?.requestStatus=='Send To Marketing'"
                              src="assets/images/requested.svg" alt="" class="me-2 " />
                            <img *ngIf="item.requestStatus=='Pending'" src="assets/images/pending.svg" alt=""
                              class="me-2 " />
                            <img *ngIf="item.requestStatus=='Completed'" src="assets/images/completed.svg" alt=""
                              class="me-2 " />
                            <img
                              *ngIf="item.requestStatus=='Not Needed' || item.requestStatus=='Send To Account Manager'"
                              src="assets/images/not-needed.svg" alt="Not Needed" class="me-2 " />
                            <img *ngIf="item.requestStatus=='Approved'" src="assets/images/approved.svg" alt="Approved"
                              class="me-2 " />
                            <img *ngIf="item.requestStatus=='Incomplete'" src="assets/images/incomplete.svg"
                              alt="Incomplete" class="me-2 " />
                            <img *ngIf="item.requestStatus=='Denied'" src="assets/images/denied.svg" alt="denied"
                              class="me-2 " />
                            <img *ngIf="item.requestStatus=='Incomplete Information'"
                              src="assets/images/incomplete-info.svg" alt="Incomplete Information" class="me-2 " />
                            <img *ngIf="item.requestStatus=='Incomplete Contact'"
                              src="assets/images/incomplete-contact.svg" alt="Incomplete Contact" class="me-2 " />
                            {{item.requestStatus}}
                          </div>
                        </td>
                        <td><img *ngIf="item.requestStatus!='Pending'" src="assets/images/calendar.svg"
                            class="me-2" />{{item.requestDate | date}}</td>
                        <td><img *ngIf="item.requestStatus!='Pending'" src="assets/images/user.svg" alt=""
                            class="me-2" />{{item.createdByUserName}}</td>

                      </tr>



                      <tr *ngIf="MarketingDropdown">
                        <td colspan="4" class="expandRow">
                          <div class="expandRow-con ">
                            <div class="row">
                              <div class="col-12 col-sm-12 col-md-6">
                                <div class="form-group flex-div">
                                  <label class="form-label me-4" for="Review">Review</label>
                                  <div class="select-div col-12 col-md-6 col-md-3">
                                    <select class="form-select l-3" #review id="Review"
                                      (change)="setAcord(review.value)">
                                      <option value="none" selected disabled hidden>Select</option>
                                      <option *ngFor="let item of acord" [value]="item.value">
                                        {{ item.label }}
                                      </option>
                                    </select>
                                  </div>

                                </div>

                              </div>
                              <div class="col-12 col-sm-12 col-md-6" *ngIf="selectedAcord=='Incomplete'">
                                <div class="form-group flex-div">
                                  <label class="form-label me-4" for="Review">Reason</label>
                                  <div class="select-div col-12 col-md-6 col-md-3">
                                    <select class="form-select l-3" #review id="Review"
                                      (change)="setComments(review.value)">
                                      <option value="none" selected disabled hidden>Select a Reason</option>
                                      <option *ngFor="let item of reasonResponse" [value]="item.reason">
                                        {{ item.reason }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-12 col-sm-12 text-right">
                                <button
                                  [disabled]="wbaDetails?.outcome == 'Lost' || wbaDetails?.outcome =='Bound' || this.wbaDetails?.wbaStatusDescription == 'Won' || this.wbaDetails?.wbaStatusDescription == 'Lost' || isAssesmentParamsHasMandatoryValues"
                                  *ngIf="(acordStatus == 'Requested' || acordStatus == 'Completed') && marketingReviewStatus == 'Pending'"
                                  class="btn btn-primary pdbtn me-1" (click)="CreatePolicyInEPIC()">Create Policy in
                                  EPIC</button>
                                <button class="btn btn-primary pdbtn" (click)="updateAccessReview()"
                                  [disabled]="isDisabled || wbaDetails?.outcome == 'Lost' || wbaDetails?.outcome =='Bound' || this.wbaDetails?.wbaStatusDescription == 'Won' || this.wbaDetails?.wbaStatusDescription == 'Lost' || isAssesmentParamsHasMandatoryValues">Submit</button>
                              </div>
                            </div>

                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <!-- WBA Table -->
                  <table class="table wba-tbl" id="response" *ngIf="wbaReviewTable">
                    <thead class="table wba-tbl">
                      <tr>
                        <th [width]="this.wbaResponse[0]?.wbaReviewStatus == 'Incomplete' ? '26%' : '100%'">WBA Review
                          Status</th>
                        <th width="74%" *ngIf="this.wbaResponse[0]?.wbaReviewStatus == 'Incomplete'">Reason</th>
                        <!-- <th>Reminder Days</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of wbaResponse ">
                        <td>{{item.wbaReviewStatus}}</td>
                        <td *ngIf="this.wbaResponse[0]?.wbaReviewStatus == 'Incomplete'">
                          <div class="flex-div des-text-div">
                            {{item?.wbaReviewStatusComment ? item?.wbaReviewStatusComment : 'N/A'}}
                          </div>
                        </td>
                        <!-- <td>{{item.reminderDays == 0 ? 'N/A' : item.reminderDays}}</td> -->
                      </tr>
                    </tbody>
                  </table>

                  <div *ngIf="wbaDropdown">
                    <div class="expandRow-con">
                      <div class="col-6 col-sm-12 col-md-6">
                        <div class="form-group flex-div">
                          <label class="col-6 form-label" for="Review">WBA Review Status</label>
                          <div class="select-div col-12 col-md-6 col-md-3">
                            <select class="form-select l-3" #review id="Review"
                              (change)="setWbaReviewStatus(review.value)">
                              <option *ngFor="let item of WbaReviewStatus" [value]="item.value">
                                {{ item.label }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="col-6 col-sm-12 col-md-6" *ngIf="wbaReminderDropdown">
                        <div class="form-group flex-div">
                          <label class="col-6 form-label" for="Review">Remind me in
                            {{selectedReminderForWbaReview}} days</label>
                          <div class="select-div col-12 col-md-6 col-md-3">
                            <select class="form-select l-3" #review id="Review"
                              (change)="setReminderForWbaReview(review.value)">
                              <option value="none" selected disabled hidden>Select</option>
                              <option *ngFor="let item of reminder" [value]="item.value"
                                [selected]="item.value == selectedReminderForWbaReview">
                                {{ item.label }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-12 col-md-12" *ngIf="wbaDescriptionField">
                        <label class="form-label" for="reason">Reason<span></span></label>
                        <textarea placeholder="Reason" id="reason" class="policy-textarea mt-2"
                          [(ngModel)]="selectedDescription" rows="4"> </textarea>
                        <div class="display-error">
                          <span *ngIf="selectedDescription.length > 500">
                            Reason should not exceed more than 500 characters.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                  <div class="policy-btn mt-2">
                    <div class="d-flex justify-content-end">
                      <button *ngIf="wbaDropdown" class="btn btn-primary pdbtn" (click)="createWbaReviewStatus()"
                        [disabled]="isDisabled ||wbaDetails?.outcome == 'Lost'  || wbaDetails?.outcome =='Bound' || this.wbaDetails?.wbaStatusDescription == 'Won' || this.wbaDetails?.wbaStatusDescription == 'Lost' || isAssesmentParamsHasMandatoryValues">Save</button>


                    </div>
                  </div>
                </form>
                <p-dialog [header]="_warningBox.header2" [modal]="true" [(visible)]="_warningBox.isVisible"
                  [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false"
                  [resizable]="false" [styleClass]="_warningBox.borderColor" *ngIf="_warningBox">

                  <p>{{ _warningBox.message }}</p>
                  <ng-template pTemplate="footer" *ngIf="_warningBox.hasFooter">
                    <p-button (click)="warningBoxCancel()" label="OK" styleClass="btn btn-primary"></p-button>
                  </ng-template>
                </p-dialog>
                <p-dialog [header]="_warningBox1.header2" [modal]="true" [(visible)]="_warningBox1.isVisible"
                  [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false"
                  [resizable]="false" [styleClass]="_warningBox1.borderColor" *ngIf="_warningBox1">
                  <p [innerHTML]="errorMessage"></p>
                  <ng-template pTemplate="footer" *ngIf="_warningBox1.hasFooter">
                    <p-button (click)="_warningBox1.isVisible = false" label="OK"
                      styleClass="btn btn-primary"></p-button>
                  </ng-template>
                </p-dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>