import { Component, OnInit, OnChanges, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomeNumberPipe } from 'src/app/shared/pipes/number.pipe';
import { CustomeRemoveNumberPipe } from 'src/app/shared/pipes/remove-number.pipe';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { KeyboardValidation } from 'src/app/shared/services/caffeineValidators/keyboard-validation';
import { environment } from 'src/environments/environment';
import { coverageModel, coverageParameterModel, WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { LookupCarrierComponent } from '../lookup-carrier/lookup-carrier.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { forkJoin, Subject } from 'rxjs';
import { DatacacheService } from '../../../services/datacache.service';
import { CarriersService } from 'src/app/features/carrier-management/services/carriers-service';
import { AddprospectScreen } from 'src/app/features/prospect/error-message/addprospect-screen';

@Component({
  selector: 'app-policydetail',
  templateUrl: './policydetail.component.html',
  styleUrls: ['./policydetail.component.css']
})
export class PolicydetailComponent implements OnInit, OnChanges {

  @Input() public ApplicationData: any;
  @Input() public selectedValue: any;
  @Input() public businessLineData: any
  @Input() public policyTypeListData: any
  @Input() public tabName: any;
  @Input() MasterCoverageList: any;
  @Output() public nextTab: any = new EventEmitter<string>();
  @Output() public policyDescriptionValue: any = new EventEmitter<string>();
  @Output() public policyDescriptionchangeValue: any = new EventEmitter<string>();
  @Input() businessLineIdfromApp: any;
  @Input() transType: any;
  @Input() isWbaClosed: any;

  activeWbaId: any;
  carriersLookupDataFilter: string = "";
  editCarriersLookupDataFilter: string = "";

  public isSubmitted: boolean = false;
  public isAddSubmitted: boolean = false;
  public isEditSubmitted: boolean = false;
  private startYear: number = 2000;
  //private PolicystartYear: number = new Date().getFullYear() - 4;
  public policyTermOptions: any[] = [];
  // private wbaId: number;
  private defaultLossRun: any = {
    policyTerm: '',
    businessLineName: '',
    businessLineId: '',
    totalIncured: '',
    carrierName: '',
    valuationDate: '',
  };
  public lossRunForm: any = { ...this.defaultLossRun };
  public editLossRunForm: any = { ...this.defaultLossRun };

  public expiringCarrierlist: any;
  private lookupData: any;
  public Carrier: any;
  public editCarrier: any;
  private _navigationPath: NavigationPath;
  public editIndex: number = -1;
  maximumDate = new Date();
  public _confirmBox: ConfirmBox;
  public _userMessages: UserMessages;
  pageNo: number = 1;
  public pageSize = 10;
  public totalCount = 0;

  public whiteBoardForm: any = {
    policyDescription: "",
    operationDescription: "",
    internalNotes: "",
    businessReason: "",
    policyTermDetails: [],
    expiringPremium: 0,
    lossRatio: 0
  }

  originalWhiteBoardForm: any;
  // policyDescriptionValue: any

  public CoverageCheckList: any = [];
  CoverageAssessmentData: any;
  lossDirty: boolean = false;
  businessLineList: any;
  selectedLOBName: any;
  policyTermDetailsLength: any
  masterCarrierList: any[] = [];
  _AddNoteScreen: AddprospectScreen;

  constructor(
    public ValidateKey: KeyboardValidation,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    private _numberPipe: CustomeNumberPipe,
    private _removeNumberPipe: CustomeRemoveNumberPipe,
    private _addWBAService: WhiteBoardActivityService,
    private modalService: NgbModal,
    private datepipe: DatePipe,
    private _router: Router,
    private activeRouter: ActivatedRoute,
    private _dataCacheService: DatacacheService,
    private _carriersService: CarriersService,
  ) {
    this._navigationPath = new NavigationPath();
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
    this._AddNoteScreen = new AddprospectScreen();
  }

  ngOnInit(): void {
    this.activeRouter.queryParams
      .subscribe(params => {
        if (params)
          this.activeWbaId = params.wbaId
      });
    window.scroll(0, 0);
    this.intializeData();
    this.createPolicyTermOptions();
  }

  intializeData() {
    this.whiteBoardForm = this._dataCacheService.retrieveData('wba_policy_DetailsInfo');
    if (!this.whiteBoardForm) this.GetDataAndBusinessLineList();
    else this.retrieveData();
  }

  retrieveData() {
    this.policyDescriptionValue.emit(this.whiteBoardForm.policyDescription);
    this.policyDescriptionchangeValue.emit(this.whiteBoardForm.policyDescription);
    this.getData();
    this.getbusinessLineList();
    this.calculateLossRatio()
  }

  GetDataAndBusinessLineList() {
    var whiteBoardFormResp = this._addWBAService.getPolicyDetails(this.activeWbaId);
    forkJoin([whiteBoardFormResp]).subscribe(resp => {
      this.whiteBoardForm = resp[0];
      this.policyDescriptionValue.emit(resp[0].policyDescription);
      this.policyDescriptionchangeValue.emit(resp[0].policyDescription);
      this._dataCacheService.compressAndCacheData('wba_policy_DetailsInfo', this.whiteBoardForm);
      this.getData();
      this.getbusinessLineList();
    })

  }

  //businessLine list dropdown
  getbusinessLineList() {
    this.selectedLOBName = this.businessLineData.find((x: any) => x.businessLineId == this.selectedValue).businessLineName;
    if (this.selectedLOBName == "Specialty") {
      this.businessLineList = this.policyTypeListData;
    } else {
      this.businessLineList = this.businessLineData

    }
    if (!this.policyTermDetailsLength && this.transType == 'RN') {
      let effectiveDateYear = new Date(this.ApplicationData.effectiveDate).getFullYear();
      let policyStartYear = new Date(this.ApplicationData.effectiveDate).getFullYear() - 5;

      while (effectiveDateYear > policyStartYear) {
        let value = (effectiveDateYear - 1) + '-' + effectiveDateYear;
        let policyTermData =
        {
          "policyTerm": value,
          "businessLineId": this.selectedValue,
          "businessLineName": this.selectedLOBName,
          "totalIncured": 0,
          "carrierId": 0,
          "carrierName": "",
          "valuationDate": ""
        }
        this.whiteBoardForm.policyTermDetails.push(policyTermData);
        this.originalWhiteBoardForm = JSON.parse(JSON.stringify(this.whiteBoardForm));
        effectiveDateYear--;
      }
      // //sorting by policyTerm in asc order
      // this.whiteBoardForm.policyTermDetails.sort(function (a: { policyTerm: string; }, b: { policyTerm: any; }) {
      //   return a.policyTerm.localeCompare(b.policyTerm);
      // });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let expiringPremium = changes.ApplicationData.currentValue.expiringPremium;
    if (expiringPremium) {
      this.whiteBoardForm.expiringPremium = parseInt(expiringPremium);
      this.originalWhiteBoardForm = JSON.parse(JSON.stringify(this.whiteBoardForm));
      //this.calculateLossRatio();
    }
  }

  getData() {
    if (this.whiteBoardForm) {
      //setting default loss ration equals to 1
      this.policyTermDetailsLength = this.whiteBoardForm.policyTermDetails.length
      if (!this.whiteBoardForm.policyTermDetails.length) {
        this.whiteBoardForm.lossRatio = 0;
      }

      if (this.whiteBoardForm && this.whiteBoardForm.policyDescription == null) {
        this.whiteBoardForm.policyDescription = ""
      }
      this.originalWhiteBoardForm = JSON.parse(JSON.stringify(this.whiteBoardForm));
    }

  }


  createPolicyTermOptions() {
    let year = new Date(this.ApplicationData.effectiveDate).getFullYear();
    while (year > this.startYear) {
      let value = (year - 1) + '-' + year;
      this.policyTermOptions.push(value);
      year--;
    }
  }

  handleLossRun(action: string, policyTermDetails: any, index: number) {
    this.lossDirty = true
    let selectedLOBId
    if (action === 'add') {

      if (this.selectedLOBName == "Specialty") {
        selectedLOBId = this.policyTypeListData.find((x: any) => x.businessLineName == policyTermDetails?.businessLineName)?.businessLineId;
      }
      else {
        selectedLOBId = this.businessLineData.find((x: any) => x.businessLineName == policyTermDetails?.businessLineName)?.businessLineId;
      };
      if (selectedLOBId) {
        policyTermDetails.businessLineId = selectedLOBId
      }
      if (policyTermDetails.carrierName == "" || policyTermDetails.carrierName == undefined) {
        policyTermDetails.carrierName = this.Carrier
        policyTermDetails.carrierId = 0;
      }
      let { policyTerm, totalIncured, carrierName, valuationDate, businessLineName, businessLineId } = policyTermDetails;
      this.isAddSubmitted = true;

      if (policyTerm && businessLineName && businessLineId && totalIncured && carrierName && valuationDate) {
        policyTermDetails.valuationDate = this.datepipe.transform(valuationDate, 'MM/dd/yyyy');
        policyTermDetails.totalIncured = parseInt(this._removeNumberPipe.transform(policyTermDetails.totalIncured));

        this.whiteBoardForm.policyTermDetails.push(policyTermDetails);

        this.lossRunForm = { ...this.defaultLossRun };
        // this.Carrier = {};
        this.Carrier = '';
        this.isAddSubmitted = false;
        this.calculateLossRatio();

      }
    } else if (action === "trash") {
      this.whiteBoardForm.policyTermDetails.splice(index, 1)
      this.calculateLossRatio();
    } else if (action === "edit") {
      this.lossDirty = false;
      if (JSON.stringify(this.lossRunForm) === JSON.stringify(this.defaultLossRun)) {

        let record = this.whiteBoardForm.policyTermDetails.find((obj: any, index1: number) => index === index1);

        this.editLossRunForm = { ...record };
        this.lossRunForm = { ...this.defaultLossRun };
        this.addNumberFormat(this.editLossRunForm);
        this.editIndex = index;
        this.Carrier = {
          id: 0,
          value: ''
        }
        this.selectItem({ id: record.carrierId, value: record.carrierName });
      } else {
        this.showAlert();
      }

    } else if (action === "cross") {
      this.editIndex = -1;

      this.isEditSubmitted = false;
      this.isAddSubmitted = false;

      this.editLossRunForm = { ...this.defaultLossRun };
      this.lossRunForm = { ...this.defaultLossRun };

      let obj = { value: '' };

      this.editCarrier = { ...obj };
      this.Carrier = { ...obj };

    } else if (action === "save") {

      if (this.selectedLOBName == "Specialty") {
        selectedLOBId = this.policyTypeListData.find((x: any) => x.businessLineName == policyTermDetails.businessLineName).businessLineId;
      }
      else {
        selectedLOBId = this.businessLineData.find((x: any) => x.businessLineName == policyTermDetails.businessLineName).businessLineId;
      };
      if (selectedLOBId) {
        policyTermDetails.businessLineId = selectedLOBId
      }
      if (!this.editCarrier.value || this.editCarrier.value == undefined) {
        policyTermDetails.carrierName = this.editCarrier;
        policyTermDetails.carrierId = 0;
      }
      let { policyTerm,
        businessLineName, totalIncured, carrierName, valuationDate, businessLineId } = policyTermDetails;
      if (carrierName?.value == '') {
        this.isEditSubmitted = true;
        return
      }

      if (policyTerm && businessLineName && businessLineId && totalIncured && carrierName && valuationDate) {
        policyTermDetails.valuationDate = this.datepipe.transform(valuationDate, 'MM/dd/yyyy');
        policyTermDetails.totalIncured = parseInt(this._removeNumberPipe.transform(policyTermDetails.totalIncured));

        this.whiteBoardForm.policyTermDetails[this.editIndex] = { ...policyTermDetails };

        this.editLossRunForm = { ...this.defaultLossRun };
        this.editCarrier = {};
        this.calculateLossRatio();

        this.editIndex = -1;
        this.isEditSubmitted = false;
      }
      else {
        this.isEditSubmitted = true;
      }
    }
  }

  calculateLossRatio() {
    let sum = 0;
    let count = 0;

    this.whiteBoardForm.policyTermDetails.forEach((obj: any) => {
      sum = sum + obj.totalIncured;
      count++
    });

    if (!sum) sum = 1;
    if (!count) count = 1;

    let expiringPremium = this._removeNumberPipe.transform(this.ApplicationData.expiringPremium);
    if (expiringPremium != 0)
      this.whiteBoardForm.lossRatio = (sum / count) / expiringPremium;
    else
      this.whiteBoardForm.lossRatio = 0;
    this.originalWhiteBoardForm = JSON.parse(JSON.stringify(this.whiteBoardForm));

  }

  saveWBA(action: string) {
    if (JSON.stringify(this.lossRunForm) === JSON.stringify(this.defaultLossRun) && this.editIndex === -1) {

      this.isSubmitted = true
      if (this.whiteBoardForm.internalNotes?.length > 6000) {
        return
      }
      if (this.whiteBoardForm.policyDescription) {
        //getting only valid rows and replacing existing array
        //let validDataInpolicyTermDetails = this.whiteBoardForm.policyTermDetails.filter((ele: any) => ele.totalIncured !== 0);
        let validDataInpolicyTermDetails = this.whiteBoardForm.policyTermDetails;
        this.whiteBoardForm.policyTermDetails = validDataInpolicyTermDetails;

        if (!this.whiteBoardForm.wbaId) this.whiteBoardForm.wbaId = this.activeWbaId;

        this._addWBAService.createPolicyDetails(this.whiteBoardForm).subscribe((response: any) => {
          if (action === 'next') this.nextTab.emit(this.tabName)
          else this._router.navigate([this._navigationPath.getProspectsWhiteboardsUrl()])
          this.policyDescriptionValue.emit(this.whiteBoardForm.policyDescription);
          this.GetDataAndBusinessLineList();
          //this._dataCacheService.compressAndCacheData('wba_policy_DetailsInfo', this.whiteBoardForm);
        });
      }
    } else {
      this.showAlert();
    }
  }

  getCarriers(fieldName: any, val: any) {
    if (this.editIndex === -1) {
      this.carriersLookupDataFilter = val.query;
    }
    else {
      this.editCarriersLookupDataFilter = val.query;
    }
    if (fieldName && val.query) {
      this._addWBAService.getTypeheadList(fieldName, val.query).subscribe(resp => {
        if (fieldName == 'expiringCarrier') {
          this.expiringCarrierlist = resp.expiringCarrierList;
        }
      });
    }
  }
  lookUpDataFilterChanges(val: any) {
    if (val == 'Carrier') {
      this.carriersLookupDataFilter = "";
    }
    if (val == 'editCarrier') {
      this.editCarriersLookupDataFilter = "";
    }
  }

  ChangeDescription(value: any) {
    this.policyDescriptionchangeValue.emit(this.whiteBoardForm.policyDescription)
  }

  selectItem(item: any) {
    if (this.editIndex === -1) {
      this.lossRunForm.carrierId = item.id;
      this.lossRunForm.carrierName = item.value;
      this.Carrier = {
        id: item.id,
        value: item.value
      }
      this.carriersLookupDataFilter = item.value;
    } else {
      this.editLossRunForm.carrierId = item.id;
      this.editLossRunForm.carrierName = item.value;
      this.editCarrier = {
        id: item.id,
        value: item.value
      }
      this.editCarriersLookupDataFilter = item.value;
    }
  }

  //Loading prospect List.
  getCarriersListInfoforBound(searchType: string) {
    let data = {
      "pageNumber": this.pageNo,
      "pageSize": this.pageSize,
      "totalCount": this.totalCount,
      "sortDir": "asc",
      "sortCol": "carrierShortName",
      "searchName": "",
      "userId": "",
      "filterJson": "",
      "gridFilterJson": "",
      "searchType": searchType
    }

    this._carriersService.getCarriersList(data).subscribe(
      (resp: any) => {
        if (resp) {
          this.masterCarrierList = resp.items[0].carrierList;

          this.lookupData = {
            data: this.masterCarrierList,
            total: resp.totalRecord
          };

          const modalRef = this.modalService.open(LookupCarrierComponent, {
            keyboard: false,
            backdrop: 'static',
            modalDialogClass: 'customeDialog1'
          });

          modalRef.closed.subscribe(resp => {
          })

          if (modalRef.componentInstance) {
            if (this.editIndex === -1) {
              modalRef.componentInstance.carrierLookupFilterData = this.carriersLookupDataFilter;
            }
            else {
              modalRef.componentInstance.carrierLookupFilterData = this.editCarriersLookupDataFilter;
            }
            modalRef.componentInstance.lookupData = this.lookupData;
            modalRef.componentInstance.searchType = searchType;
            modalRef.componentInstance.messageEvent.subscribe((data: any) => {
              this.selectItem(data);
            });
          }
        }
      },
      (error: any) => {

      }
    );
  }

  openLookupModal(value: any) {
    let data = {
      "lookUpType": value,
      "rowCount": 50
    }
    if (value === "Carrier") {
      this.getCarriersListInfoforBound(value);
    }

    // this._addWBAService.getWhiteBoardActivityLookUp(data).subscribe((response: any) => {
    //   if (response) {
    //     this.lookupData = response;
    //     const modalRef = this.modalService.open(LookupCarrierComponent, {
    //       keyboard: false,
    //       backdrop: 'static',
    //       modalDialogClass: 'customeDialog1'
    //     });
    //     modalRef.closed.subscribe((resp: any) => {
    //     })

    //     if (modalRef.componentInstance) {
    //       if (this.editIndex === -1) {
    //         modalRef.componentInstance.carrierLookupFilterData = this.carriersLookupDataFilter;
    //       }
    //       else {
    //         modalRef.componentInstance.carrierLookupFilterData = this.editCarriersLookupDataFilter;
    //       }
    //       modalRef.componentInstance.lookupData = this.lookupData;

    //       modalRef.componentInstance.messageEvent.subscribe((data: any) => {
    //         this.selectItem(data);
    //       });
    //     }
    //   }
    // });
  }

  addNumberFormat(variable: policyDetailModel) {
    var formattedAmount;
    formattedAmount = variable.totalIncured.toString().replace(/,/g, '');
    variable.totalIncured = this._numberPipe.transform(formattedAmount, 'USD', '1.0-0');
  }

  removeNumberFormat(variable: policyDetailModel) {
    variable.totalIncured = this._removeNumberPipe.transform(variable.totalIncured);
  }

  removeSpaces(variable: policyDetailModel) {
    variable.totalIncured = this._removeWhiteSpacesPipe.transform(variable.totalIncured, '');
  }

  showAlert() {
    this._confirmBox.message = this._userMessages.movingWithoutSavingLossRun;
    this._confirmBox.isVisible = true;
  }

  confirmBoxCancel(value: string) {
    this._confirmBox.isVisible = false;
  }

  handleData() {
    this.lossDirty = true;
  }
}

export interface policyDetailModel {
  id: number;
  policyTerm: string;
  totalIncured: number;
  carrierId: number;
  carrierName: string;
  valuationDate: string;
}
