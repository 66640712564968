import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { DeleteWhiteboardActivityComponent } from './components/delete-whiteboardactivity/delete-whiteboardactivity.component';
import { WhiteBoardActivityDetailComponent } from './components/view-whiteboardactivity/view-whiteboardactivity.component';
import { WhiteboardListComponent } from './components/whiteboard-list/whiteboard-list.component';
import { AddWhiteboardComponent } from './components/add-whiteboard/add-whiteboard.component';
import { ApplicationComponent } from './components/add-whiteboard/application/application.component';
import { PolicydetailComponent } from './components/add-whiteboard/policydetail/policydetail.component';
import { LookupProspectComponent } from './components/add-whiteboard/lookup-prospect/lookup-prospect.component';
import { CoverageChecklistComponent } from './components/add-whiteboard/coverage-checklist/coverage-checklist.component';
import { AdditionalInformationComponent } from './components/add-whiteboard/additional-information/additional-information.component'; './component/add-whiteboard/additional-information/AdditionalInformationComponent';
import { SubmissionComponent } from './components/add-whiteboard/submission/submission.component';
import { AuditReportComponent } from './components/add-whiteboard/audit-report/audit-report.component';
import { QuoteComponent } from './components/add-whiteboard/quote/quote.component';
import { LostDetailComponent } from './components/add-whiteboard/lost-detail/lost-detail.component';
import { WbaBoundComponent } from './components/add-whiteboard/wba-bound/wba-bound.component';
import { ExecutiveSummaryComponent } from './components/executive-summary/executive-summary.component';
import { RenewComponent } from './components/renew/renew.component';
import { FormGuradGuard } from 'src/app/shared/services/form-gurad.guard';
import { ProposalDetailsComponent } from './components/add-whiteboard/proposalDetails/proposalDetails.component';
import { CloneBorComponent } from './components/add-whiteboard/clone-bor/clone-bor.component';

const routes: Routes = [
  { path: '', component: WhiteboardListComponent },
  { path: NavigationPath.whiteboardsDelete, component: DeleteWhiteboardActivityComponent },
  { path: NavigationPath.whiteboardsView, component: WhiteBoardActivityDetailComponent },
  { path: NavigationPath.whiteboardAddwhiteboard, canDeactivate: [FormGuradGuard], component: AddWhiteboardComponent },
  { path: NavigationPath.whiteboardApplication, canDeactivate: [FormGuradGuard], component: ApplicationComponent },
  { path: NavigationPath.whiteboardPolicyDetail, component: PolicydetailComponent },
  { path: NavigationPath.whiteboardLookupProspect, component: LookupProspectComponent },
  { path: NavigationPath.whiteboardCoverageChecklist, component: CoverageChecklistComponent },
  { path: NavigationPath.whiteboardAdditionalInformation, component: AdditionalInformationComponent },
  { path: NavigationPath.whiteboardMarketing, component: SubmissionComponent },
  { path: NavigationPath.whiteboardAuditReport, component: AuditReportComponent },
  { path: NavigationPath.whiteboardQuote, component: QuoteComponent },
  { path: NavigationPath.whiteboardLostDetail, component: LostDetailComponent },
  { path: NavigationPath.whiteboardBoundDetail, component: WbaBoundComponent },
  { path: NavigationPath.whiteboardsExecutiveSummary, component: ExecutiveSummaryComponent },
  { path: NavigationPath.whiteboardsRenew, component: RenewComponent },
  { path: NavigationPath.whiteboardProposalDetails, component: ProposalDetailsComponent },
  { path: NavigationPath.whiteboardLostDetail, component: CloneBorComponent },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WhiteBoardRoutingModule {
}
