import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { ErrorBox } from 'src/app/shared/class/error-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { AddWBAScreen } from '../../../error-message/addprospect-screen';
import { CustomeAmountPipe } from 'src/app/shared/pipes/amount.pipe';
import { CustomeRemoveAmountPipe } from 'src/app/shared/pipes/remove-amount.pipe';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { coverageModel, coverageParameterModel, WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { WBANotifierService } from '../../../services/wba-notifier.service';
import { LookupProspectComponent } from '../lookup-prospect/lookup-prospect.component';
import { LookupUsersComponent } from '../lookup-users/lookup-users.component';
import { LookupLeadGeneratorComponent } from '../lookup-lead-generator/lookup-lead-generator.component';
import { LookupCarrierComponent } from '../lookup-carrier/lookup-carrier.component';
import { LookupManagerComponent } from '../lookup-manager/lookup-manager.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LookupService } from 'src/app/shared/services/lookupService';
import { CustomNotifireService } from 'src/app/shared/services/view-prospect-notifire.service';
import { RegxConstant } from 'src/app/shared/class/regx';
import { DatePipe } from '@angular/common';
import { KeyboardValidation } from 'src/app/shared/services/caffeineValidators/keyboard-validation';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { EFileCabinateComponent } from '../../e-file-cabinate/e-file-cabinate.component';
import { ProspectListService } from 'src/app/features/prospect/services/prospect-list.service';
import { CarriersService } from 'src/app/features/carrier-management/services/carriers-service';
import { PolicydetailComponent } from '../policydetail/policydetail.component';
import { AdditionalInformationComponent } from '../additional-information/additional-information.component';
import { CoverageChecklistComponent } from '../coverage-checklist/coverage-checklist.component';
import { WbaNoteComponent } from '../wba-note/wba-note.component';
import { WbaEditNoteComponent } from '../wba-edit-note/wba-edit-note.component';
import { DialsComponent } from 'src/app/core/components/dials/dials.component';
import { RingCentralLoginComponent } from 'src/app/core/components/ring-central-login/ring-central-login.component';
import { NotificationService } from "@progress/kendo-angular-notification";
import { CoverageAssessmentComponent } from '../coverage-assessment/coverage-assessment.component';
import { AuditReportComponent } from '../audit-report/audit-report.component';
import { AcordComponent } from '../acord/acord.component';
import { SubmissionComponent } from 'src/app/features/white-board/components/add-whiteboard/submission/submission.component'
import { LookupMarketingmanagerComponent } from '../lookup-marketingmanager/lookup-marketingmanager.component';
import { PreventBackButtonService } from 'src/app/shared/services/prevent-back-button.service';
import { QuoteComponent } from 'src/app/features/white-board/components/add-whiteboard/quote/quote.component'
import { SubmitToMarketingComponent } from '../submit-to-marketing/submit-to-marketing.component';
import { RsmApprovalComponent } from '../rsm-approval/rsm-approval.component';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { AcmeProductService } from 'src/app/features/acme/service/acme-product.service';
import { AcmeEmailDetailComponent } from 'src/app/features/acme/acme-product/acme-email-detail/acme-email-detail.component';
import { AcmeEmailSendComponent } from 'src/app/features/acme/acme-product/acme-email-send/acme-email-send.component';
import { ProfileInfoService } from 'src/app/shared/services/profile-info.service';
import { LostDetailComponent } from '../lost-detail/lost-detail.component';
import { WbaBoundComponent } from '../wba-bound/wba-bound.component';
import { WbaBindRequestComponent } from '../wba-bind-request/wba-bind-request.component';
import { BindingComponent } from '../binding/binding.component';
import { EpicpolicydDetailsComponent } from '../epicpolicyd-details/epicpolicyd-details.component';
import { BorComponent } from '../bor/bor.component';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountManagementServicesComponent } from '../account-management-services/account-management-services.component';
import { AccountExecutiveComponent } from '../account-executive/account-executive.component';
import { AccountManagerComponent } from '../account-manager/account-manager.component';
import { CaffeineEmailDetailComponent } from 'src/app/features/white-board/components/add-whiteboard/caffeine-email-detail/caffeine-email-detail.component';
import { UserManagementService } from 'src/app/features/user-management/services/user-management.service';
import { DialsService } from 'src/app/features/prospect/components/prospect-tab/services/dials.service';
import { CompositeFilterDescriptor, SortDescriptor, filterBy, orderBy } from '@progress/kendo-data-query';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { EditContactComponent } from 'src/app/features/prospect/components/prospect-tab/components/contacts/edit-contact/edit-contact.component';
import { ContactProviderService } from 'src/app/features/prospect/services/contact-provider.service';
import { CoverageAssessmentNBComponent } from '../coverage-assessment-nb/coverage-assessment-nb.component';
import { CloneBorComponent } from '../clone-bor/clone-bor.component';
import { AddContactComponent } from 'src/app/features/contact/components/add-contact/add-contact.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { ViewProposalComponent } from 'src/app/view-proposal/view-proposal.component';
import { DatacacheService } from '../../../services/datacache.service';
import { AddprospectService } from 'src/app/features/prospect/services/addprospect.service';
import { SubmissionViewComponent } from '../submission-view/submission-view.component';
import {stringEmpty} from "../../../../../shared/utils";

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit {
  [x: string]: any;

  @ViewChild(PolicydetailComponent) PolicydetailComponent: any;
  @ViewChild(AdditionalInformationComponent) AdditionalInformationComponent: any;
  @ViewChild(CoverageChecklistComponent) CoverageChecklistComponent: any;
  @ViewChild(CoverageAssessmentComponent) CoverageAssessmentComponent: any;
  @ViewChild(AcordComponent) AcordComponent: any;
  @ViewChild(AccountExecutiveComponent) AccountExecutive: any;
  @ViewChild(SubmitToMarketingComponent) MaketingComponent: any;
  @ViewChild(RsmApprovalComponent) sdRsmComponent: any;
  @ViewChild(AccountManagerComponent) AccountManager: any;
  @ViewChild(SubmissionComponent) SubmissionComponent: any;
  @ViewChild(QuoteComponent) QuoteComponent: any;
  @ViewChild(AccountManagementServicesComponent) AccountMgmtServices: any;
  @ViewChild(BindingComponent) BindingComponent: any;
  @ViewChild(EpicpolicydDetailsComponent) EpicpolicydDetailsComponent: any;
  @ViewChild(CoverageAssessmentNBComponent) CoverageAssessmentNBComponent: any;
  @ViewChild(SubmissionViewComponent) submissionView: any;

  @ViewChild("msg") el: ElementRef | any;
  @ViewChild("template", { read: TemplateRef, static: false })
  public notificationTemplate!: TemplateRef<unknown>;
  public hideAfter = 2000;
  isExecutiveSummaryClicked: boolean = false;
  office1Email: any;
  isPrimaryForEmail: boolean = false;
  isExpand: boolean = false;
  anchor: string = 'Read More';

  tabs: any[] = [
    {
      label: 'Policy Detail & Loss Runs',
      labelImg: 'assets/images/w0.png', tb: 1, isActive: true, status: ''
    },
    {
      label: 'Additional Information',
      labelImg: 'assets/images/w1.png', tb: 2, isActive: false, status: ''
    },

    {
      label: 'Coverage Checklist',
      labelImg: 'assets/images/w2.png', tb: 3, isActive: false, status: ''
    },
    {
      label: 'Coverage Assessment',
      labelImg: 'assets/images/w3.png', tb: 4, isActive: false, status: '',
      required: true
    },
    {
      label: 'Coverage Assessment ',
      labelImg: 'assets/images/w3.png', tb: 4, isActive: false, status: '',
      required: true
    },

    {
      label: 'CE Status',
      labelImg: 'assets/images/w4.png', tb: 5, isActive: false, status: ''
    },
    {
      label: 'AE Status',
      labelImg: 'assets/images/w4.png', tb: 5, isActive: false, status: ''
    },
    {
      label: 'Marketing Review',
      labelImg: 'assets/images/w7.png', tb: 6, isActive: false, status: ''
    },
    {
      label: 'SD Approval',
      labelImg: 'assets/images/w5.png', tb: 7, isActive: false, status: ''
    },
    // {
    //   label: 'Account Manager',
    //   labelImg: 'assets/images/w5.png', tb: 7, isActive: false, status: ''
    // },

    {
      label: 'Submissions',
      labelImg: 'assets/images/w6.png', tb: 8, isActive: false, status: ''
    },

    // {
    //   label: 'Account Management Services',
    //   labelImg: 'assets/images/w14.png', tb: 11, isActive: false, status: ''
    // },
    // {
    //   label: 'Binding',
    //   labelImg: 'assets/images/w12.png', tb: 12, isActive: false
    // },
    // {
    //   label: 'EPIC Policy Details',
    //   labelImg: 'assets/images/w13.png', tb: 13, isActive: false
    // }
  ]

  onSubmit(tabIndex: number) {
    // Replace this with your actual submission logic
    const isSuccess = Math.random() > 0.5;

    if (isSuccess) {
      this.tabs[tabIndex].status = 'success';
    } else {
      this.tabs[tabIndex].status = 'error';
    }
  }

  whTabsList: any[] =
    [
      {
        tabName: 'Insured Details',
        tabIcon: 'assets/images/wb1.png', isActive: true
      },
      {
        tabName: 'WBA Details',
        tabIcon: 'assets/images/wb2.png', isActive: false
      },

      {
        tabName: 'Email',
        tabIcon: 'assets/images/wb3.png', isActive: false
      },
      {
        tabName: 'Internal Notes',
        tabIcon: 'assets/images/wb4.png', isActive: false
      },
      {
        tabName: 'Marketing Summary',
        tabIcon: 'assets/images/wb6.png', isActive: false
      },
      {
        tabName: 'Binding',
        tabIcon: 'assets/images/binding.png', isActive: false
      },
      {
        tabName: 'Epic Policy Details',
        tabIcon: 'assets/images/policy-detail.png', isActive: false
      },
      {
        tabName: 'Submission View',
        tabIcon: 'assets/images/submissionView.png', isActive: false
      },
    ]


  activeTab: string = this.whTabsList[0].tabName;
  isReadMore: boolean = false;
  _navigationPath: NavigationPath;
  whiteBoardForm: any;
  isDisabled: boolean = false;
  isBtnDisabled: boolean = false;
  isSubmitted: boolean = false;
  lookupData: any;
  value: any = "";
  selectedAccount: any;
  keyword = 'name';
  accountList: any;
  _confirmBox: ConfirmBox;
  _warningBox: ConfirmBox;
  _warningBoxTabChange: ConfirmBox;
  _warningBox1: ConfirmBox;
  isAssesmentPopUp: any;
  _errorBox: ErrorBox;
  _userMessages: UserMessages;
  userList: any = [];
  businessLine: any = [];
  businessStatus: any = [];
  _AddWhiteBoardScreen: AddWBAScreen;
  epicStatus: any = [];
  progress: any = [];
  prospectTierList: any = [];
  prospectIndustryList: any = [];
  WBAPriority: any = [];
  leadGenerator: any = [];
  expiringCarrier: any = [];
  contactlist: any;
  WBAid: any = 0;
  consumerValue: any;
  _regxConstant: RegxConstant;
  showaddressChoice: boolean = true;
  leadGenratorlist: any;
  expiringCarrierlist: any;
  ownerlist: any;
  accountManagerlist: any;
  bussinesslinevalue: any;
  url: string = "";
  ShowIframe: boolean = false;
  urlSafe!: SafeResourceUrl;
  expRevenue: any;
  targetRevenue: any;
  selectedValue: any = ""
  selectedIndustryValue: any = ""
  selectedTierValue: any = ""
  selectedCompanyName = "";
  selectedLineOfBusiness = "";
  selectedEffectiveDate: any;
  selectedWhiteboardName: string = '';
  expiringPrimium: any;
  targetPremium: any;
  expiringRevenue: any;
  prospectContact: any;
  wbalistcopy: any;
  pId: any;
  checkprospect: any;
  contactExisted: boolean = false;
  showError: boolean = false;
  errorMessage: any;
  offece1EXT: any;
  office2Ext: any;
  finalExpValue: any;
  errorList: any[] = [];
  currentTab: string = this.tabs[0].label; // selecting tab by default in Side Navigation
  auditDone: boolean = false;
  whiteBoardActivityName: string = 'White Board Activity Name';
  SelectedWbaPriorityName: string = '';
  modalRef: any;
  lookupcomponent: any;
  masterProspectList: any;
  masterCarrierList: any;
  pageNo: number = 1;
  public pageSize = 10;
  public totalCount = 0;
  masterUserList: any;
  masterCoverageListByLineTierAndIndustry: any[] = [];
  wbaView: any;
  notesList: any = []
  wbaNoteId: any;
  wbaIDValue: any;
  wbaHeaderValue: any = {
    effectiveDate: '',
    accountManager: '',
    businessStatus: '',
    progress: '',
    lineOfBusiness: '',
  }
  accountManager: any;
  isMaxMin: boolean = true;
  public CoverageCheckList: any = [];
  CoverageAssessmentData: any;
  roles: any;
  @Output() roleName = new EventEmitter<any>();
  RoleuserId: any;
  acordId: number = 0;
  policyInEPIC: boolean = false;
  marketingManagerlist: any;
  isSubmissionsButtonsDisable: boolean = true;
  sdRsmStatus: string = '';
  wbaEmailList: any;
  emailurl: string = "https://www.linkedin.com/jobs/";
  isRunAudit: any;
  coverageAssessmentResponse: any;
  policyTypeList: any;
  unAthorizesd: any = '';
  emittedbusinessLineId: number = 0;
  wbaSaveLostReqNotifire: string = '0';
  disableAllExceptLost: boolean = false;
  showNonZeroMessageForPremium: boolean = false;
  isExpiringCarrierIdRequired: boolean = false;
  showNonZeroMessageForTargetPremium: boolean = false;
  expectBound: any;
  tierId: any;
  assessmentPayload: any;
  closedModalType: string = '';
  _confirmBoxForWba: ConfirmBox;
  _confirmBoxForApplication: ConfirmBox;
  _confirmBoxForClone: ConfirmBox;
  serviceResponse$!: Observable<any>;
  unsubscribe$: Subject<boolean> = new Subject();
  isFormClosedfromCross: boolean = false;
  businessStatusChange: any;
  emittedlobIdForSpeciality: number = 0;
  isMoveToNextTab: boolean = true;
  shouldMoveToNextTab: boolean = false;
  clickOnTabName: string = ''
  coverageCheckListPayload: any;
  progressStatusChange: any;
  progressStatusChangeId: any;
  clickTabName: string = '';
  detailsTabChanges: boolean = false;
  policyTypeName: any;
  selectedPolicyTypeId: any;
  renewalStatusList: any;
  isRenewalStatusVisible: any;
  epicUniquePolicyId: any;
  isDirty: boolean = false;
  action: any;
  invalidowner: any;
  invalidAccountManager: boolean = false;
  invalidLeadGerator: any;
  invalidMarketingManager: boolean = false;
  listTitle: string = 'All Prospects';
  selectedListId: number = 0;
  submissionTab: any;
  isWbaTabDisabled: boolean = true;
  invalidExpiringCarrier: boolean = false;
  utcTime: any;
  NotesUtcTime: any;
  userId: any;
  userDetailes: any;
  isDialActive: boolean = false;
  isCallOnGoing: any;
  userRoleName: any;
  valuesForOutcome: any;
  selectedData: any
  routeOnProspect: boolean = false;
  wbaListId: any = 0;
  wbaAcordProgress: boolean = false;
  wbaReviewStatusArray: any;
  progressIdUpdatedFromAccord: number = 0;
  marketingSummaryProgress: boolean = false;
  public gridFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  public buttonCount = 5;
  public sizes = [10, 25, 50];
  public wbaEmailListSize = 10;
  public skip = 0;
  isFilter: boolean = true;
  public sizesEmailList = [10, 25, 50];
  wbaEmailListFilterData: any;
  public sort: SortDescriptor[] = [];
  public multiple = false;
  public gridData: any = [];
  public gridMasterData: any = [];
  pageChangeEmailFlag: boolean = false;
  sortChangeFlag: boolean = false;
  filterEmailFlag: boolean = false;
  isMarketingManager: boolean = false;
  isAccountManager: boolean = false;
  ownerDatalookupFilterData: string = "";
  AccountManagerLookupFilterData: string = "";
  MarketingManagerLookupFilterData: string = "";
  LeadGeneratorLookupFilterData: string = "";
  ExpiringCarrierLookupFilterData: string = "";
  contactList: any;
  contactDetails: any;
  prospectDetail: any;
  editIcon: boolean = true;
  isAssesmentParamsHasMandatoryValues: boolean = false;
  isAssesmentParamsHasMandatoryValuesPopup: boolean = false;
  doNotCheckRequiredAssessment: boolean = false;
  noMasterCov: boolean = false;
  doNotCheckMasterCovData: boolean = false;
  uniqueId: string = '';
  selectedBor: string = 'No';
  progressIdAfterBORinDiscuss: any;
  SelectedvalueforProfit: any;
  isTierVisible: boolean = false;
  leadGeneratorNotBlankButInvalid: boolean = false;
  searchGrid: any;
  FilterBlankcheck: boolean = false;
  acordProgressSubmitted: boolean = false;
  isEffectiveDateInvalid: boolean = false;
  policyDescriptionValue: any
  policyDescriptionchangeValue: any;
  calledFromClone: boolean = false;
  isBorRenuewshow: boolean = false;
  isHideEpicBindingTab: boolean = false;
  isDisplaySubmissionSendButton: boolean = true;
  initator: any;
  isWbaClosed: boolean = false;
  istargetRevenueUpdated: boolean = false;
  prospectId: any;
  isProspectId: boolean = true;

  industryName: any
  statusName: any;
  selectedContactId: any;
  DisableLostFlag: boolean = false;
  isExpand1: boolean = true;
  policydetailswba: any;
  notNeedToCallContactProspect: boolean = false;
  AcordPermissionResponse: any;
  issubmissionRequest: boolean = false;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public sanitizer: DomSanitizer,
    public _amountPipe: CustomeAmountPipe,
    private _removeAmountPipe: CustomeRemoveAmountPipe,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    private _addWBAService: WhiteBoardActivityService,
    private router: Router,
    private _customNotifireService: CustomNotifireService,
    private _datepipe: DatePipe,
    private _notifierService: WBANotifierService,
    private lookupService: LookupService,
    public ValidateKey: KeyboardValidation,
    private _ProspectListService: ProspectListService,
    private _carriersService: CarriersService,
    private notificationService: NotificationService,
    private _preventBack: PreventBackButtonService,
    private _loaderService: LoaderService,
    private _AcmeProductService: AcmeProductService,
    public _activeModal: NgbActiveModal,
    public profileInfoService: ProfileInfoService,
    private _router: Router,
    private activeRouter: ActivatedRoute,
    private userManagementService: UserManagementService,
    private _dialservices: DialsService,
    private _contactService: ContactProviderService,
    private _viewProspectNotifireService: CustomNotifireService,
    private _AddprospectService: AddprospectService,
    private titleService: Title,
    private clipboard: Clipboard,
    private _dataCacheService: DatacacheService
  ) {

    this._confirmBoxForWba = new ConfirmBox();
    this._confirmBox = new ConfirmBox()
    this._confirmBoxForApplication = new ConfirmBox();
    this._confirmBoxForClone = new ConfirmBox();
    this._warningBox = new ConfirmBox()
    this._warningBoxTabChange = new ConfirmBox()
    this._warningBox1 = new ConfirmBox();
    this._errorBox = new ErrorBox();
    this._userMessages = new UserMessages();
    this._navigationPath = new NavigationPath();
    this._AddWhiteBoardScreen = new AddWBAScreen();
    this._regxConstant = new RegxConstant();
    this._notifierService.getValue().subscribe((res: any) => {
      this.wbaIDValue = res;
      this._notifierService.getExpectBoundValue().subscribe((res: any) => {
        this.expectBound = res;
      })
    })
    // this._notifierService._isBusinessStatusChange.subscribe(resp => {
    //   if (resp) {
    //     this.businessStatusChange = resp
    //   }
    // });
    this._notifierService._isProgressStatusChange.subscribe(resp => {
      if (resp) {
        this.progressStatusChange = resp;
      }
    });
    // making bor in discussion disabled after accord = requested
    this._notifierService._isAccordRequested.subscribe(resp => {
      if (resp) {
        this.wbaView.borInDiscussionEnable = resp;
      }
    });
    this._notifierService._setUpdatedProgressId.subscribe(resp => {
      if (resp) {
        this.progressStatusChangeId = resp
        this.whiteBoardForm?.get('progressId').patchValue(this.progressStatusChangeId);
      }
    });
    this._notifierService._isprofitCenterChange.subscribe(resp => {
      if (resp) {
        this.whiteBoardForm.get('profitCenter').disable();
        this.whiteBoardForm.get('profitCenter').patchValue(resp);
      }
    });
    sessionStorage.setItem(environment.storageVariables.CoverageCheckListStorage, '[]');

    this._notifierService._isSubmissionsButtonsDisable.subscribe(resp => {
      this.isSubmissionsButtonsDisable = resp;
    });

    this._notifierService._isDisplaySubmissionSendButton.subscribe(resp => {
      this.isDisplaySubmissionSendButton = resp;
    });

    this._notifierService._isAuditRun.subscribe(resp => {
      if (resp) {
        //this.whiteBoardForm.get('targetPremium').disable();
        //this.whiteBoardForm.get('expiringPremium').disable();
        this.whiteBoardForm.get('businessLineId').disable();
        //this.whiteBoardForm.get('industryId').disable();
        //this.whiteBoardForm.get('tierId').disable();
        this.whiteBoardForm.get('policyTypeId').disable();
        this.whiteBoardForm.get('progressId').disable();
        this._notifierService._isAuditRun.next(false);
      }
    });
    // For during dail case
    let dialActive = sessionStorage.getItem('dialActive')!;
    this._customNotifireService.isDialActive.subscribe((res: any) => {
      this.isDialActive = res == 'callOnGoing' || dialActive == 'callOnGoing' ? true : false;
    });
  }

  copyText(value: string): void {
    this.clipboard.copy(value);
    // alert('Text copied to clipboard');
  }


  initializeMasterData(): void {
    this.prospectIndustryList = this._dataCacheService.retrieveData('wba_prospectIndustryList');
    //this.businessStatus = this._dataCacheService.retrieveData('wba_businessStatusList');
    this.businessLine = this._dataCacheService.retrieveData('wba_businessLine');
    this.policyTypeList = this._dataCacheService.retrieveData('wba_policyTypeList');
    this.progress = this._dataCacheService.retrieveData('wba_progress');
    this.epicStatus = this._dataCacheService.retrieveData('wba_resultList');
    console.log("prgid", this.progress)
    this.WBAPriority = this._dataCacheService.retrieveData('wba_detail_WBAPriority');
    this.renewalStatusList = this._dataCacheService.retrieveData('wba_detail_renewalStatusList');
    this.prospectTierList = this._dataCacheService.retrieveData('wba_detail_prospectTierList');
    this.valuesForOutcome = this._dataCacheService.retrieveData('wba_detail_valuesForOutcome');


    if (this.businessLine.length == 0) this.getAllMastersDropDown();
    if (this.WBAPriority.length == 0 || this.renewalStatusList.length == 0 || this.prospectTierList.length == 0 || !this.valuesForOutcome) this.getAllMasterNotInWbaList();
    this.getAllMasters();
  }

  getAllMastersDropDown() {
    //var businessStatus = this._addWBAService.getBusinessStatusList(); //to getBusinessStatusList
    var epicStatus = this._addWBAService.getResultList(); //to get state list
    var progress = this._addWBAService.getprogressList(); //to get progress status list
    var prospectIndustryList = this._addWBAService.getProspectIndustryList();
    var businessLine = this._addWBAService.getBusinessLineList(); // to get business line details
    var policyTypeList = this._addWBAService.getPolicyTypeList(5); // to get policy list details
    var stateList = this._AddprospectService.getProspectStates();
    forkJoin([epicStatus, progress, prospectIndustryList,
      businessLine, policyTypeList, stateList
    ]).subscribe((resp) => {
      if (resp) {
        //this.businessStatus = resp[0].businessStatusList;
        this.epicStatus = resp[0]?.epicStatusList;
        this.progress = resp[1].progressList;
        this.prospectIndustryList = resp[2].prospectIndustryList;
        this.businessLine = resp[3].businessLineList;
        this.policyTypeList = resp[4].businessLineList;
        this._dataCacheService.compressAndCacheData('wba_statesList', resp[5].statesList);
        this._dataCacheService.compressAndCacheData('wba_prospectIndustryList', this.prospectIndustryList);
        // this._dataCacheService.compressAndCacheData('wba_businessStatusList', this.businessStatus);
        this._dataCacheService.compressAndCacheData('wba_businessLine', this.businessLine);
        this._dataCacheService.compressAndCacheData('wba_policyTypeList', this.policyTypeList);
        this._dataCacheService.compressAndCacheData('wba_progress', this.progress);
        this._dataCacheService.compressAndCacheData('wba_resultList', this.epicStatus);
      }
    },

      (err: any) => {
        this.errorList = []
        this.showError = true;
        this.isBtnDisabled = false;
        if (err.status == 403) {
          this.showError = true;
          this.errorList.push(this.unAthorizesd)
          return
        }
        if (err.status == 400) {
          this.errorMessage = "Error : " + err.error.errors.ProspectId[0];
          this.errorList.push(this.errorMessage);
        }
        if (err.status == 500) {
          this.errorMessage = "Error : " + err.message + ", Status: " + err.status;;
          this.errorList.push(this.errorMessage);
        }
      });
  }

  getAllMasterNotInWbaList() {
    var prospectTierList = this._addWBAService.getProspectTierList(); // to get prospect details
    var renewalStatusList = this._addWBAService.getRenewalStatusList(); // to get prospect details
    var outComeList = this._addWBAService.getOutcomeList(this.userRoleName); // to get outcome detail
    var masterCVlist = this._addWBAService.getMasterCovList();
    var WBAPriority = this._addWBAService.getWBAPriorityList()
    forkJoin([
      prospectTierList, renewalStatusList, outComeList,
      masterCVlist, WBAPriority
    ]).subscribe((resp) => {
      if (resp) {
        this.prospectTierList = resp[0].prospectTierList;
        this.renewalStatusList = resp[1];
        this.valuesForOutcome = resp[2].masteroutComeList;
        this._dataCacheService.compressAndCacheData('wba_Mrktsmry_masterCovList', resp[3]);
        this.WBAPriority = resp[4];
        this._dataCacheService.compressAndCacheData('wba_detail_WBAPriority', this.WBAPriority);
        this._dataCacheService.compressAndCacheData('wba_detail_renewalStatusList', this.renewalStatusList);
        this._dataCacheService.compressAndCacheData('wba_detail_prospectTierList', this.prospectTierList);
        this._dataCacheService.compressAndCacheData('wba_detail_valuesForOutcome', this.valuesForOutcome);
        this.getoutcomeList();
      }
    },

      (err: any) => {
        this.errorList = []
        this.showError = true;
        this.isBtnDisabled = false;
        if (err.status == 403) {
          this.showError = true;
          this.errorList.push(this.unAthorizesd)
          return
        }
        if (err.status == 400) {
          this.errorMessage = "Error : " + err.error.errors.ProspectId[0];
          this.errorList.push(this.errorMessage);
        }
        if (err.status == 500) {
          this.errorMessage = "Error : " + err.message + ", Status: " + err.status;;
          this.errorList.push(this.errorMessage);
        }
      });
  }

  getAllMasters() {
    let requestBody = {
      wbaId: this.WBAid
    }
    var wbaView = this._addWBAService.getWBAById(this.WBAid);
    // var coverageAssessmentDetails = this._addWBAService.getCoverageAssessmentDetails(this.WBAid); //to get saved coverages with assessment
    var policydetails = this._addWBAService.getPolicyDetails(this.WBAid);
    var WbaSubmissionCarrierListById = this._addWBAService.getWbaSubmissionCarrierListById(this.WBAid);
    var marketingSummaryList = this._addWBAService.getMarketingSummaryList(this.WBAid);
    var WbaBindingList = this._addWBAService.getWbaBindingList(requestBody);
    var epicPolicydata = this._addWBAService.GetEpicPolicyDetails(this.WBAid);
    var getAccordPermissionByWbaId = this._addWBAService.getAccordPermissionByWbaId(requestBody);
    forkJoin([
      wbaView,
      policydetails, WbaSubmissionCarrierListById, marketingSummaryList, WbaBindingList, epicPolicydata, getAccordPermissionByWbaId
    ]).subscribe((resp) => {
      if (resp) {
        this.wbaView = resp[0].whiteBoardActivityInfo;
        // this.CoverageAssessmentData = resp[1];
        // this._dataCacheService.compressAndCacheData('wba_CoverageAssessmentDetails', this.CoverageAssessmentData);
        this._dataCacheService.compressAndCacheData('wba_policy_DetailsInfo', resp[1]);
        this._dataCacheService.compressAndCacheData('wba_Mrktsmry_carrierList', resp[2].wbaSubmissionCarrierList);
        this._dataCacheService.compressAndCacheData('wba_Mrktsmry_marketingSummary', resp[3].marketingSummary);
        this._dataCacheService.compressAndCacheData('WbaSubmissionCarrierList', resp[3].wbaSubmissionCarrierList);
        this._dataCacheService.compressAndCacheData('wba_bindingResponse', resp[4].wbaOutcomeDetails);
        this._dataCacheService.compressAndCacheData('wba_epicPolicyDetails', resp[5].wbaEpicPolicyDetailList[0]);
        this.AcordPermissionResponse = resp[6].acordPermissions;
        this._dataCacheService.compressAndCacheData('wba_AcordPermissionResponse', this.AcordPermissionResponse);//setting resp in cache

        // this.getContactListResponse(resp[13]);
        // let tabRefresh = [{ label: 'Policy Detail & Loss Runs', refresh: true }, { label: 'Additional Information', refresh: true }, { label: 'Coverage Checklist', refresh: true }, { label: 'Coverage Assessment', refresh: true }, { label: 'Coverage Assessment ', refresh: true },
        // { label: 'CE Status', refresh: true }, { label: 'Account Executive', refresh: true }, { label: 'Marketing Review', refresh: true }, { label: 'SD Approval', refresh: true }, { label: 'Account Manager', refresh: true }, { label: 'Submissions', refresh: true },
        // ]
        let tabRefresh = {
          refresh: true
        }
        this._dataCacheService.compressAndCacheData('detailstabRefresh', tabRefresh);
        if (this.CoverageAssessmentData?.coverageAssessmentDetail.length) {
          this.isAssesmentPopUp = true;
          this.handleDetailsTabErrorIcon('Coverage Checklist', false);
        }
        this.getWhiteBoardDetails(resp[0]);
        this.getWBAEmailList(this.WBAid);
        this.getWbaNoteList(this.WBAid);
        this.setDataInCache();
        this.getAccessReviewList(this.currentTab);
        this.getBusinessStatus(this.wbaView?.transType, this.wbaView?.masterBusinessStatusId);
      }
    },

      (err: any) => {
        this.errorList = []
        this.showError = true;
        this.isBtnDisabled = false;
        if (err.status == 403) {
          this.showError = true;
          this.errorList.push(this.unAthorizesd)
          return
        }
        if (err.status == 400) {
          this.errorMessage = "Error : " + err.error.errors.ProspectId[0];
          this.errorList.push(this.errorMessage);
        }
        if (err.status == 500) {
          this.errorMessage = "Error : " + err.message + ", Status: " + err.status;;
          this.errorList.push(this.errorMessage);
        }
      });

  }

  getContactListResponse(resp: any) {
    this.contactList = resp?.contactList;
    if (this.contactList) {
      for (let i = 0; i < this.contactList.length; i++) {
        if (this.contactList[i].id == this.wbaView.contactId) {
          this.contactDetails = this.contactList[i];
          break;
        }
      }
    }
  }

  setDataInCache() {
    this._dataCacheService.compressAndCacheData('wba_detail_wbaView', this.wbaView);
  }

  ngOnInit(): void {
    this.activeRouter.queryParams.subscribe(params => {
      if (params) {
        this.WBAid = params.wbaId;
        this.wbaListId = params.listId;
        this.prospectId = params.prospectId;
        if (params.initiated) this.initator = params.initiated;
        if (params.via) this.viaEmail = params.via;
      }
    });
    sessionStorage.setItem('selectWBAIDForsubmission', this.wbaListId)
    this.userRoleName = localStorage.getItem("userRole")!;
    console.log("policyDescriptionValue1", this.policyDescriptionValue)
    sessionStorage.setItem('isFormDirty', '')
    window.scroll(0, 0);
    this.isFilter = true;
    this.profileInfoService.statusCode.subscribe((res: any) => {
      this.unAthorizesd = res ? res : '';
    })
    this.tabs.forEach((x: any) => x.showError = true);
    this._preventBack.preventBackButton();
    this.getFormControls();
    // this.getoutcomeList(this.userRoleName);
    // this.getPolicyDetails(this.WBAid)
    let notificationEmailData = JSON.parse(localStorage.getItem('NotificationEmail')!);
    if (notificationEmailData) {
      if (this.viaEmail == 'ae-email') {
        this.tabs = this.tabs.filter((res: any) => res.label !== "CE Status" && res.label !== "SD Approval" && res.label !== "Coverage Checklist" && res.label !== "Coverage Assessment " && res.label !== "Marketing Review" && res.label !== "Additional Information");
        this.activeTab = 'WBA Details';
        this.currentTab = 'AE Status';
        this.whTabsList.forEach((item: any) => {
          item.isActive = false
          if (item.tabName == "WBA Details") item.isActive = true;
        })
        this.tabs.forEach((item: any) => {
          item.isActive = false
          if (item.label == "AE Status") item.isActive = true;
        })
        this.setActiveTabOnNotificationEmail();
      }
      else {
        this.initializeMasterData();
      }
    } else {
      this.initializeMasterData();
    }
    // this.getAllMasters();
    this.getResponse(this.WBAid);
    //this.getacordResponse();

    this._customNotifireService.setActiveComponent('Whiteboard');

    // setTimeout(() => {
    //   this._loaderService.hide();
    // }, 5000);
    // this._loaderService.show();
    this.userDetailes = JSON.parse(localStorage.getItem('userDetails')!);
    if (this.userDetailes) {
      this.userDetailes?.user?.roles.forEach((x: any) => {
        if (x.roleName == 'Admin' || x.roleName == 'System Manager' || x.roleName == 'Sales Director' || x.roleName == "Production Specialist" || x.roleName == 'Client Service Level 4') {
          this.whiteBoardForm.get('opportunityApproved').enable();
        }
        if (x.roleName == 'Admin' || x.roleName == 'Marketing Manager' || x.roleName == 'Client Service Level 4') {
          this.isMarketingManager = true;
        }
        if (x.roleName == 'Admin' || x.roleName == 'Client Service Level 2' || x.roleName == 'Client Service Level 4') {
          this.isAccountManager = true;
        }
        if (x.roleName == 'Marketing Manager') {
          this.isBorRenuewshow = true;
        }

        if (x.roleName == "Admin" || x.roleName == "Client Service Level 4") {
          this.isHideEpicBindingTab = true;
        }
      })
      this.userId = this.userDetailes.user.userId
    }

    // this._notifierService._acordSubmitted.subscribe(res => {
    //   if (res) this.getWhiteBoardDetailsFromApi(this.wbaView?.id);
    //   this.this._notifierService._acordSubmitted.next(false);
    // });

    this._dataCacheService.getrefreshaccord().subscribe((resp: any) => {
      if (resp.value === 'accord') {
        this.notNeedToCallContactProspect = true;
        this.getWhiteBoardDetailsFromApi(resp.wbaId);
      }
    })

    this._notifierService._submissionEmailRefresh.subscribe((resp: any) => {

      if (resp.value === 'refresh') {
        this.getWBAEmailList(resp.wbaId);
      }
    })
    this._dataCacheService.removeDataStartsWith('wba_AcordPermissionResponse')
  }




  maxWindow(val: any) {
    this.isMaxMin = !val;
  }


  expand(val: any) {
    this.isExpand = !val;
    console.log('', this.isExpand)
  }


  executiveSummaryById() {
    this.isExecutiveSummaryClicked = true;
    sessionStorage.setItem('setUrlOnClick', 'whiteboard-activities/whiteboard-executive-summary')
    this.getWhiteBoardDetailsFromApi(this.WBAid);
  }

  showIframe() {
    this.ShowIframe = !this.ShowIframe;
    if (this.ShowIframe) {
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }
  }

  classAppliedLeft = true;
  classAppliedRight = true;
  btnclass2 = true;
  toggleClassLeft() {
    this.classAppliedLeft = !this.classAppliedLeft;
    this.classAppliedRight = !this.classAppliedRight;
    this.btnclass2 = !this.btnclass2;
  }

  //Loading prospect list for open pop up model
  getProspectList() {
    let data = {
      "pageNumber": this.pageNo,
      "pageSize": this.pageSize,
      "totalCount": this.totalCount,
      "sortDir": "asc",
      "sortCol": "prospectName",
      "searchText": "",
      "filterJson": "",
      "gridFilterJson": ""
    }

    this._ProspectListService.prospectPagination(data).subscribe(
      (resp: any) => {
        if (resp) {
          this.masterProspectList = resp.items[0].prospectList;

          this.lookupData = {
            data: this.masterProspectList,
            total: resp.totalRecord
          };

          const modalRef = this.modalService.open(LookupProspectComponent, {
            keyboard: false,
            backdrop: 'static',
            modalDialogClass: 'customeDialog1'
          });

          modalRef.closed.subscribe(resp => {
          })

          modalRef.componentInstance.lookupData = this.lookupData;

          modalRef.componentInstance.messageEvent.subscribe((data: any) => {
            if (data) {
              this.whiteBoardForm.controls['companyName'].patchValue({
                id: data.id,
                prospectName: data.prospectName
              });
              this.selectItem(data, 'CompanyName');
            }
          });
        }
      },
      (error: any) => {

      }
    );
  }

  getCarriersListInfo(searchType: string) {
    let data = {
      "pageNumber": this.pageNo,
      "pageSize": this.pageSize,
      "totalCount": this.totalCount,
      "sortDir": "asc",
      "sortCol": "carrierShortName",
      "searchName": "",
      "userId": "",
      "filterJson": "",
      "gridFilterJson": "",
      "searchType": searchType
    }

    const modalRef = this.modalService.open(LookupCarrierComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog1'
    });

    modalRef.closed.subscribe(resp => {
    })
    modalRef.componentInstance.carrierLookupFilterData = this.ExpiringCarrierLookupFilterData;
    modalRef.componentInstance.lookupData = this.lookupData;
    modalRef.componentInstance.searchType = searchType;
    modalRef.componentInstance.passEntry.subscribe((resp: any) => {
      this.selectedData = resp
    }
    )

    modalRef.componentInstance.messageEvent.subscribe((data: any) => {
      if (data) {
        this.isExpiringCarrierIdRequired = false;
        this.whiteBoardForm.controls['expiringCarrierId'].patchValue({
          id: data.id,
          value: data.value
        });
        this.ExpiringCarrierLookupFilterData = data.value;
      }
    });
  }

  getUsersListPaginate(userType: any) {
    var data = {
      pageNo: 1,
      pageSize: 10,
      lookUpType: userType

    }
    if (data) {

      this.serviceResponse$ = this._addWBAService.lookupPagination(data);
      this.serviceResponse$.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
        if (resp) {
          this.lookupData = {
            data: [...resp.items[0].userList],
            total: resp.totalRecord
          };
          let lookupcomponent: any;
          if (userType === "Owner") {
            lookupcomponent = LookupManagerComponent;
          } else if (userType === "AccountManager") {
            lookupcomponent = LookupManagerComponent;
          }

          const modalRef = this.modalService.open(lookupcomponent, {
            keyboard: false,
            backdrop: 'static',
            modalDialogClass: 'customeDialog1'
          });
          modalRef.closed.subscribe(resp => {
          })
          if (userType === "Owner") {
            modalRef.componentInstance.ownerlookupFilterData = this.ownerDatalookupFilterData;
          }
          if (userType === "AccountManager") {
            modalRef.componentInstance.ownerlookupFilterData = this.AccountManagerLookupFilterData;
          }
          modalRef.componentInstance.lookupData = this.lookupData;
          modalRef.componentInstance.passEntry.subscribe((resp: any) => {
            this.selectedData = resp
          }
          )
          modalRef.componentInstance.messageEvent.subscribe((data: any) => {
            if (data) {
              if (userType == "Owner") {
                this.whiteBoardForm.controls['ownerName'].patchValue({
                  id: data.userId,
                  value: data.userName
                });
                this.ownerDatalookupFilterData = data.userName;
                this.invalidowner = this.whiteBoardForm.get('ownerName').value;
                this.saveInsuredDetails(false);
              }
              if (userType == "AccountManager") {
                this.whiteBoardForm.controls['accountManagerUserId'].patchValue({
                  id: data.userId,
                  value: data.userName
                });
                this.AccountManagerLookupFilterData = data.userName;
                this.invalidAccountManager = false;
                this.saveInsuredDetails(false);
              }
            }
          });
        }
        else {

        }
      },
        (error: any) => {

        })
    }

  }

  //outcomeList dropdown
  getoutcomeList() {
    // this._addWBAService.getOutcomeList(role).subscribe(resp => {
    // if (resp) {
    //   this.valuesForOutcome = resp.masteroutComeList;
    this.valuesForOutcome?.forEach((x: any) => {
      if (x == 'Bind Request' || x == 'Bound') {
        this.DisableLostFlag = true;
      }
      else
        this.DisableLostFlag = false;

    })
    // }

    // })
  }

  openLookupModal(value: any) {
    let data = {
      "lookUpType": value,
      "rowCount": 10
    }
    let lookupcomponent: any;
    if (value === "CompanyName") {
      this.getProspectList()
    } else if (value === "Carrier") {
      this.getCarriersListInfo(value)
    }
    else if (value === "Owner") {
      this.getUsersListPaginate(value)
    }
    else if (value === "AccountManager") {
      this.getUsersListPaginate(value)
    }
    else {
      this._addWBAService.getWhiteBoardActivityLookUp(data).subscribe((response: any) => {
        if (response) {
          this.lookupData = response;
          if (value === "LeadGenerator") {
            lookupcomponent = LookupLeadGeneratorComponent;
          }
          else if (value === "MarketingManager") {
            lookupcomponent = LookupManagerComponent;
          }
          const modalRef = this.modalService.open(lookupcomponent, {
            keyboard: false,
            backdrop: 'static',
            modalDialogClass: 'customeDialog1'
          });
          modalRef.closed.subscribe(resp => {
          })
          if (value === "LeadGenerator") {
            modalRef.componentInstance.LeadlookupFilterData = this.LeadGeneratorLookupFilterData;
          }

          if (value === "MarketingManager") {
            modalRef.componentInstance.ownerlookupFilterData = this.MarketingManagerLookupFilterData;
          }

          modalRef.componentInstance.lookupData = this.lookupData;
          modalRef.componentInstance.searchType = value;
          modalRef.componentInstance.passEntry.subscribe((resp: any) => {
            this.selectedData = resp
          }
          )

          modalRef.componentInstance.messageEvent.subscribe((data: any) => {
            if (value === "LeadGenerator") {
              this.whiteBoardForm.controls['leadGeneratorId'].patchValue({
                id: data.id,
                value: data.name
              });
              this.LeadGeneratorLookupFilterData = data.name;
            }
            else if (value === "MarketingManager") {
              this.whiteBoardForm.controls['marketingManager'].patchValue({
                id: data.userId,
                value: data.userName
              });
              this.MarketingManagerLookupFilterData = data.userName;
              this.saveInsuredDetails(false);
            }
            this.invalidMarketingManager = false
          });
        }
      });
    }
  }

  onDropdownChange(item: any) {
    this.changeWhiteboardName('businssLine');
  }

  get f() {
    return this.whiteBoardForm.controls;
  }

  get FirstName(): any {
    return this.whiteBoardForm.get('contact.firstName');
  }

  get LastName(): any {
    return this.whiteBoardForm.get('contact.lastName');
  }

  get JobTitle(): any {
    return this.whiteBoardForm.get('contact.jobTitle');
  }

  get PhoneNumber(): any {
    return this.whiteBoardForm.get('contact.phoneNumber');
  }

  get Extension(): any {
    return this.whiteBoardForm.get('contact.extension');
  }

  get EmailAddress(): any {
    return this.whiteBoardForm.get('contact.emailAddress');
  }

  closeModal() {
    if (this.action !== '') {
      this.setPolicyDetailsDirty();
    }
    if (this.selectedData) {
      this.whiteBoardForm.markAsDirty();
    }
    if (this.whiteBoardForm.dirty || this.isDirty) {
      this._confirmBoxForWba.message = this._userMessages.cancelButtonMessage;
      this._confirmBoxForWba.isVisible = true;
      this.closedModalType = 'closeModal';
    } else {
      this.isFormClosedfromCross = true
      if (!this.initator) {
        if (!this.wbaListId || this.wbaListId == undefined) this.wbaListId = sessionStorage.getItem('selectWBAIDForsubmission')

        sessionStorage.setItem('selectWBAID', this.wbaListId)
        this._router.navigateByUrl('/' + this._navigationPath.getWhiteboardsUrl());
      } else {
        if (this.initator == 'whiteboard') this._router.navigateByUrl('/' + this._navigationPath.getWhiteboardsUrl());
        if (this.initator == 'prospect') this._router.navigateByUrl('/' + this._navigationPath.getProspectsUrl());
      }
    }
  }

  getFormControls() {
    this.whiteBoardForm = this._formBuilder.group({
      id: [{ value: '', disabled: this.isDisabled }, [Validators.required]],
      companyName: [{ value: '', disabled: this.isDisabled }, [Validators.required, Validators.maxLength(60)]],
      prospectId: [{ value: '', disabled: this.isDisabled }, [Validators.required, Validators.maxLength(60)]],
      targetPremium: [{ value: null, disabled: this.isDisabled }, [Validators.required]],
      businessStatusId: [{ value: '', disabled: this.isDisabled }, [Validators.required]],
      businessLineId: [{ value: '', disabled: this.isDisabled }, Validators.required],
      effectiveDate: [{ value: '', disabled: this.isDisabled }, Validators.required],
      contactId: [{ value: '', disabled: this.isDisabled }, [Validators.required, Validators.maxLength(60)]],
      ownerName: [{ value: '' }, [Validators.required]],
      epicStatusId: [{ value: '', disabled: true }],
      industryId: [{ value: '', disabled: this.isDisabled }, Validators.required],
      tierId: [{ value: '', disabled: this.isDisabled }, Validators.required],
      progressId: [{ value: '', disabled: this.isDisabled }],
      expiringPremium: [{ value: null, disabled: this.isDisabled }],
      expiringRevenue: [{ value: null, disabled: this.isDisabled }],
      targetRevenue: [{ value: null, disabled: this.isDisabled }, [Validators.required]],
      wbaPriorityId: [{ value: '', disabled: this.isDisabled }, [Validators.required]],
      leadGeneratorId: [{ value: '', disabled: this.isDisabled }, [Validators.required]],
      expiringCarrierId: [{ value: '', disabled: this.isDisabled }],
      ein: [{ value: '', disabled: this.isDisabled }, [Validators.required]],
      accountManagerUserId: [{ value: '', disabled: this.isDisabled }],
      profitCenter: [{ value: '', disabled: this.isDisabled }],
      borInDiscussion: [false],
      marketingManager: [{ value: '', disabled: this.isDisabled }],
      isExisting: ['true'],
      contact: this._formBuilder.group({
        id: [{ value: '', disabled: this.isDisabled }, [Validators.required, Validators.maxLength(60)]],
        prospectId: [{ value: '', disabled: this.isDisabled }, [Validators.required, Validators.maxLength(60)]],
        firstName: [{ value: '', disabled: this.isDisabled }, [Validators.required, Validators.maxLength(200)]],
        lastName: [{ value: '', disabled: this.isDisabled }, [Validators.maxLength(200)]],
        jobTitle: [{ value: '', disabled: this.isDisabled }, [Validators.maxLength(200)]],
        phoneNumber: [{ value: '', disabled: this.isDisabled }, [Validators.required]],
        extension: [{ value: '', disabled: this.isDisabled }],
        emailAddress: [{ value: '', disabled: this.isDisabled }, [Validators.maxLength(60), Validators.pattern(this._regxConstant.Email())]],
        isPrimary: ['false'],
      }),
      policyTypeId: [{ value: '', disabled: this.isDisabled }],
      renewalStatusId: [{ value: '', disabled: this.isDisabled }],
      opportunityApproved: [{ value: '', disabled: true }]
    })
    this.whiteBoardForm.get('policyTypeId').disable();
  }

  // getWhiteBoardDetails called direclty from ngoninit
  getResponse(res: any) {
    this.whiteBoardForm.get('id').setValue(this.WBAid);
    this.isExecutiveSummaryClicked = false;
  }

  NotesTabClick() {
    this.getWbaNoteList(this.WBAid)
  }

  getWhiteBoardDetailsFromApi(Id: any, enableLoader: boolean = true) {
    this._addWBAService.getWBAById(Id, enableLoader).subscribe(resp => {
      if (resp && resp.whiteBoardActivityInfo) {
        if (!enableLoader) this.notNeedToCallContactProspect = true;
        this.getWhiteBoardDetails(resp);
        // this.getContactlist(Id)
      }
    })
  }

  //patching form data
  getWhiteBoardDetails(resp: any) {
    if (resp && resp.whiteBoardActivityInfo) {
      this.wbaView = resp.whiteBoardActivityInfo;
      this._dataCacheService.compressAndCacheData('wba_wbaDetailsInfo', resp.whiteBoardActivityInfo);
      this._notifierService._mrktngSmryRefresh.next('refresh');
      this.titleService.setTitle(this.wbaView?.companyName);
      if (!this.notNeedToCallContactProspect) {
        this.getContactlist(resp.whiteBoardActivityInfo.prospectId);
        this.getProspectDetailTemp(resp.whiteBoardActivityInfo.prospectId);
      }
      this.selectedData = '';
      this.isPrimaryForEmail = (resp.whiteBoardActivityInfo?.office1Email != null && resp.whiteBoardActivityInfo?.office1Email != "") ? true : false
      this.emittedbusinessLineId = resp.whiteBoardActivityInfo?.masterBusinessLineId == 5 ? resp.whiteBoardActivityInfo.policyTypeid : resp.whiteBoardActivityInfo.masterBusinessLineId;
      this.selectedValue = resp.whiteBoardActivityInfo.masterBusinessLineId;
      this.tierId = resp.whiteBoardActivityInfo.prospectSizeId ? parseInt(resp.whiteBoardActivityInfo.prospectSizeId) : 0;
      this.epicUniquePolicyId = resp.whiteBoardActivityInfo.epicUniquePolicyId;
      this.notNeedToCallContactProspect = false;
      if (this.wbaView?.outcome == 'Lost' || this.wbaView?.outcome == 'Bound' || this.wbaView?.wbaStatusDescription == 'Won' || this.wbaView?.wbaStatusDescription == 'Lost') this.isWbaClosed = true;
      else this.isWbaClosed = false;

      if (this.wbaView?.transType == 'RN') {
        this.isTierVisible = true;
        this.tabs = this.tabs.filter((res: any) => res.label !== "CE Status" && res.label !== "SD Approval" && res.label !== "Coverage Checklist" && res.label !== "Coverage Assessment " && res.label !== "Marketing Review" && res.label !== "Additional Information")
      }
      if (this.wbaView?.transType == 'NB') {
        this.tabs = this.tabs.filter((res: any) => res.label !== "AE Status" && res.label !== "Coverage Assessment" && res.label !== "Additional Information")
      }

      if (!this.isHideEpicBindingTab) {
        this.whTabsList.forEach((x: any) => {
          this.whTabsList = this.whTabsList.filter((x: any) => x.tabName != 'Binding' && x.tabName != 'Epic Policy Details');
        })
      }

      if (this.wbaView?.transType == 'RN') {
        this.whiteBoardForm.get('profitCenter').disable();
        this.whiteBoardForm.get('wbaPriorityId')?.setValidators(null);
        this.whiteBoardForm.get('wbaPriorityId')?.updateValueAndValidity();
        this.whiteBoardForm.get('renewalStatusId')?.setValidators([Validators.required]);
        this.whiteBoardForm.get('renewalStatusId')?.updateValueAndValidity();
        //contact form not required for RN case
        this.whiteBoardForm.get('contact.id')?.setValidators(null);
        this.whiteBoardForm.get('contact.id')?.updateValueAndValidity();
        this.whiteBoardForm.get('contact.prospectId')?.setValidators(null);
        this.whiteBoardForm.get('contact.prospectId')?.updateValueAndValidity();
        this.whiteBoardForm.get('contact.firstName')?.setValidators(null);
        this.whiteBoardForm.get('contact.firstName')?.updateValueAndValidity();
        this.whiteBoardForm.get('contact.phoneNumber')?.setValidators(null);
        this.whiteBoardForm.get('contact.phoneNumber')?.updateValueAndValidity();
      }

      if (this.wbaView.borInDiscussion) this.selectedBor = 'Yes'
      else this.selectedBor = 'No'

      this.office1Email = resp.whiteBoardActivityInfo.office1Email
      this.accountManager = this.wbaView?.accountManagerUserName
      this.businessStatusChange = this.wbaView.masterBusinessStatus
      this.progressStatusChange = this.wbaView.progressName
      this.emittedlobIdForSpeciality = resp.whiteBoardActivityInfo.masterBusinessLineId;
      this.whiteBoardActivityName = this.wbaView.companyName + ' - ' + this._datepipe.transform(this.wbaView.effectiveDate, 'MM/dd/yyyy');
      // sending data for run audit wizard via notifier service
      this._notifierService.setWBADetailsValue(resp.whiteBoardActivityInfo);

      this.wbalistcopy = resp.whiteBoardActivityInfo;
      if (resp.whiteBoardActivityInfo.nodeId) {

        if (resp.whiteBoardActivityInfo.wbaNodeId) {
          this.url = "https://account.efilecabinet.net/#/home/state/data/node/" + resp.whiteBoardActivityInfo.nodeId + "/" +
            resp.whiteBoardActivityInfo.wbaNodeId + "/details/" + resp.whiteBoardActivityInfo.wbaNodeId;
        } else {
          this.url = "https://account.efilecabinet.net/#/home/state/data/node/" + resp.whiteBoardActivityInfo.nodeId + "/" +
            resp.whiteBoardActivityInfo.nodeId + "/details/" + resp.whiteBoardActivityInfo.nodeId;
        }
      } else {
        this.url = "https://account.efilecabinet.net/#/home/state/data/node";
      }
      this.checkprospect = this.wbaView.prospectId;

      this.whiteBoardForm.get('prospectId').patchValue(this.wbaView.prospectId);
      this.whiteBoardForm.get('contactId').patchValue(this.wbaView.contactId);
      var expPri = this.wbaView.expiringPremium;
      if (expPri > 0)
        this.expiringPrimium = this._amountPipe.transform((expPri), 'USD', '', '1.0-0');
      else
        this.expiringPrimium = expPri;
      this.whiteBoardForm.get('expiringPremium').patchValue(this.expiringPrimium);
      var tarPrimium = this.wbaView.targetPremium;
      this.targetPremium = this._amountPipe.transform((tarPrimium), 'USD', '', '1.0-0');
      this.whiteBoardForm.get('targetPremium').patchValue(this.targetPremium);

      var expRevenue = this.wbaView.expiringRevenue;
      if (expRevenue > 0)
        this.expiringRevenue = this._amountPipe.transform((expRevenue), 'USD', '', '1.0-0');
      else
        this.expiringRevenue = expRevenue;
      this.whiteBoardForm.get('expiringRevenue').patchValue(this.expiringRevenue);
      var targetRevenue = this.wbaView.expectedBoundRevenue;
      this.targetRevenue = this._amountPipe.transform((targetRevenue), 'USD', '', '1.0-0');
      this.whiteBoardForm.get('targetRevenue').patchValue(this.targetRevenue);
      this.selectedIndustryValue = resp.whiteBoardActivityInfo.industryId;
      this.selectedTierValue = parseInt(resp.whiteBoardActivityInfo.prospectSizeId);
      this.progressIdAfterBORinDiscuss = parseInt(resp.whiteBoardActivityInfo.progressId);
      this.isRenewalStatusVisible = resp.whiteBoardActivityInfo.transType;

      this.whiteBoardForm.patchValue({
        businessLineId: resp.whiteBoardActivityInfo.masterBusinessLineId,
        industryId: resp.whiteBoardActivityInfo.industryId == '0' ? '' : resp.whiteBoardActivityInfo.industryId,
        tierId: resp.whiteBoardActivityInfo.prospectSizeId == '0' ? '' : resp.whiteBoardActivityInfo.prospectSizeId,
        progressId: resp.whiteBoardActivityInfo.progressId == '0' ? '' : resp.whiteBoardActivityInfo.progressId,
        epicStatusId: resp.whiteBoardActivityInfo.epicStatusId == '0' ? '' : resp.whiteBoardActivityInfo.epicStatusId,
        renewalStatusId: resp.whiteBoardActivityInfo.renewalStatusId == '0' ? '' : resp.whiteBoardActivityInfo.renewalStatusId,
        businessStatusId: resp.whiteBoardActivityInfo.masterBusinessStatusId,
        effectiveDate: resp.whiteBoardActivityInfo.effectiveDate == 'Mon Jan 01 0001 00:00:00 GMT+0553 (India Standard Time)' || resp.whiteBoardActivityInfo.effectiveDate == '0001-01-01T00:00:00' ? '' : new Date(resp.whiteBoardActivityInfo.effectiveDate),
        wbaPriorityId: resp.whiteBoardActivityInfo.masterWBAPriorityId,
        policyTypeId: resp.whiteBoardActivityInfo.policyTypeid,
        profitCenter: resp.whiteBoardActivityInfo.profitCenter,
        opportunityApproved: resp.whiteBoardActivityInfo.opportunityApproved == null ? '' : resp.whiteBoardActivityInfo.opportunityApproved,
        borInDiscussion: resp.whiteBoardActivityInfo?.borInDiscussion,
        companyName: {
          id: resp.whiteBoardActivityInfo.prospectId,
          prospectName: resp.whiteBoardActivityInfo.companyName ?? stringEmpty
        },
        leadGeneratorId: {
          id: resp.whiteBoardActivityInfo.masterLeadGeneratorId,
          value: resp.whiteBoardActivityInfo.masterLeadGeneratorName ?? stringEmpty
        },
        expiringCarrierId: {
          id: resp.whiteBoardActivityInfo.masterExpiringCarrierId,
          value: resp.whiteBoardActivityInfo.expiringName ?? stringEmpty
        },
        accountManagerUserId: {
          id: resp.whiteBoardActivityInfo.accountManagerUserId,
          value: resp.whiteBoardActivityInfo.accountManagerUserName ?? stringEmpty
        },
        marketingManager: {
          id: resp.whiteBoardActivityInfo.marketingManagerId,
          value: resp.whiteBoardActivityInfo.marketingManager ?? stringEmpty
        },
        ownerName: {
          id: resp.whiteBoardActivityInfo.ownerUserId,
          value: resp.whiteBoardActivityInfo.ownerUserName ?? stringEmpty
        }
      });
      this.AccountManagerLookupFilterData = resp.whiteBoardActivityInfo.accountManagerUserName ?? stringEmpty;
      this.ownerDatalookupFilterData = resp.whiteBoardActivityInfo.ownerUserName ?? stringEmpty;
      this.MarketingManagerLookupFilterData = resp.whiteBoardActivityInfo.marketingManager ?? stringEmpty;
      this.LeadGeneratorLookupFilterData = resp.whiteBoardActivityInfo.masterLeadGeneratorName ?? stringEmpty;
      this.ExpiringCarrierLookupFilterData = resp.whiteBoardActivityInfo.expiringName ?? stringEmpty;

      this.selectedPolicyTypeId = resp.whiteBoardActivityInfo.policyTypeid;
      if (this.selectedPolicyTypeId > 0) {
        this.policyTypeName = this.policyTypeList.find((x: any) => x.businessLineId == this.selectedPolicyTypeId)?.businessLineName
      } else {
        this.policyTypeName = ''
      }
      this.checkPolicyTypeValidation();

      //let SelectedWbaPriority = this.WBAPriority.wbaPriorityList.find((obj: any) => obj.id === resp.whiteBoardActivityInfo?.masterWBAPriorityId);
      //if (SelectedWbaPriority) this.SelectedWbaPriorityName = SelectedWbaPriority.value;
      this.FirstName.patchValue(resp.whiteBoardActivityInfo.firstName);
      this.LastName.patchValue(resp.whiteBoardActivityInfo.lastName);
      this.JobTitle.patchValue(resp.whiteBoardActivityInfo.jobTitle);
      this.PhoneNumber.patchValue(resp.whiteBoardActivityInfo.office1PhoneNumber);
      this.EmailAddress.patchValue(resp.whiteBoardActivityInfo.office1Email);
      this.selectedContactId = resp.whiteBoardActivityInfo.contactId;

      if (resp.whiteBoardActivityInfo.office1Extension) {
        if (resp.whiteBoardActivityInfo.office1Extension) {
          this.offece1EXT = parseInt(resp.whiteBoardActivityInfo?.office1Extension) ? parseInt(resp.whiteBoardActivityInfo?.office1Extension) : null;
          this.whiteBoardForm.get('contact.extension').setValue(this.offece1EXT);
        }
        else {
          this.whiteBoardForm.get('contact.extension').setValue('');
        }
      }
      if (resp.whiteBoardActivityInfo.contactId) {
        this.contactExisted = true;
        this.whiteBoardForm.get('contact').disable();
        this.whiteBoardForm.get('contact.firstName').enable();
        this.selectedContactId = resp.whiteBoardActivityInfo.contactId;
      }
      if (resp.whiteBoardActivityInfo.ein) {
        if (resp.whiteBoardActivityInfo.ein) {
          this.whiteBoardForm.get('ein').setValue(resp.whiteBoardActivityInfo.ein);
        }
        else {
          this.whiteBoardForm.get('ein').setValue('');
        }
      }
      if (this.isAssesmentPopUp) {
        //this.whiteBoardForm.get('targetPremium').disable();
        //this.whiteBoardForm.get('expiringPremium').disable();
        this.whiteBoardForm.get('businessLineId').disable();
        //this.whiteBoardForm.get('industryId').disable();
        // this.whiteBoardForm.get('tierId').disable();
        this.whiteBoardForm.get('policyTypeId').disable();
        this.whiteBoardForm.get('progressId').disable();
      }

      //enabling progress field in case Renewed WBA
      if (this.wbaView.transType == 'RN') {
        this.whiteBoardForm.get('progressId').enable();
        // if (this.wbaView.masterExpiringCarrierId)
        //   this.whiteBoardForm.get('expiringCarrierId').disable();
        // else
        //   this.whiteBoardForm.get('expiringCarrierId').enable();
        // if (this.wbaView.expiringPremium)
        //   this.whiteBoardForm.get('expiringPremium').disable();
        // else
        //   this.whiteBoardForm.get('expiringPremium').enable();
      }
      if (this.wbaView.transType == 'NB' || this.wbaView.wbaStatusDescription == 'Won' || this.wbaView.wbaStatusDescription == 'Lost') {
        this.whiteBoardForm.get('progressId').disable();
        this.whiteBoardForm.get('expiringPremium').enable();
      }

      // When click on execute summary
      if (this.isExecutiveSummaryClicked) {
        sessionStorage.setItem(environment.storageVariables.wbaId, this.WBAid);
        this.router.navigateByUrl('/' + this._navigationPath.getWhiteboardsUrl() + '/' + this._navigationPath.getexecutiveSummarysUrl() + '?listId=' + this.wbaListId)
      }
      this.isWbaTabDisabled = false;
      this.tierName = this.prospectTierList.find((tierItem: any) => (tierItem.id == resp.whiteBoardActivityInfo.prospectSizeId));
      this.checkExpectedBoundRevuneChange();
    }
    else {
    }
    this.isProspectId = false;
    // })
  }

  //saving of WBA Vertical tabs starts
  saveWBA(action: string) {
    this.isSubmitted = true;
    this.action = action
    this.isDirty = false
    if (action == 'exit' || action == 'SaveOnly' || action == 'send') {
      this.action = ''
      this.isMoveToNextTab = true
      this.shouldMoveToNextTab = true;
    } else {
      this.shouldMoveToNextTab = false;
    }
    let currentTabIndex = this.tabs.findIndex((tab: any) => tab.label === this.currentTab);
    if (this.wbaView?.transType == 'NB') {
      if (currentTabIndex === 0) this.PolicydetailComponent.saveWBA(action);
      // if (currentTabIndex === 1) this.AdditionalInformationComponent.saveAdditionalInformation(action);
      if (currentTabIndex === 1) this.CoverageChecklistComponent.saveWBA(action);
      if (currentTabIndex === 2) {
        if (action == 'ShowAuditWizard') {
          this.CoverageAssessmentNBComponent.GetAssessmentIdByCoverageParameterIdAndValue(action);
          this.openRunAuditWizard();
          return;
        }
        if (action == 'RunAuditWizard' || action == 'SaveOnly') {
          this.CoverageAssessmentNBComponent.GetAssessmentIdByCoverageParameterIdAndValue(action);
          return;
        }
        if (action == 'next') {
          this.CoverageAssessmentNBComponent.GetAssessmentIdByCoverageParameterIdAndValue(action);
        }
      }
      // if (this.wbaView?.transType == 'RN') {
      //   if (currentTabIndex === 2) {
      //     if (action == 'ShowAuditWizard') {
      //       this.CoverageAssessmentComponent.GetAssessmentIdByCoverageParameterIdAndValue(action);
      //       this.openRunAuditWizard();
      //       return;
      //     }
      //     if (action == 'RunAuditWizard' || action == 'SaveOnly') {
      //       this.CoverageAssessmentComponent.GetAssessmentIdByCoverageParameterIdAndValue(action);
      //       return;
      //     }
      //     if (action == 'next') {
      //       this.CoverageAssessmentComponent.GetAssessmentIdByCoverageParameterIdAndValue(action);
      //     }
      //   }
      // }

      if (currentTabIndex === 3) this.AcordComponent?.saveWBA(action);
      if (currentTabIndex === 4) this.MaketingComponent?.saveWBA(action);
      if (currentTabIndex === 5) this.sdRsmComponent?.saveWBA(action);
      // if (currentTabIndex === 6) this.SubmissionComponent.sendMailToCarriers(action);
      if (currentTabIndex === 6) this.SubmissionComponent.openSendCarrierConfirmationModal(action);
      if (currentTabIndex === 7) this.QuoteComponent.saveQuote(action);

      // if (currentTabIndex === 9) this.AccountMgmtServices.saveWBA(action);
      //if (currentTabIndex === 8) this.BindingComponent.saveWBA(action);
      //if (currentTabIndex === 9) this.EpicpolicydDetailsComponent.epicPolicyDetails(action);
      // }
    }
    //for RN tabIndex
    if (this.wbaView.transType == 'RN') {
      if (currentTabIndex === 0) this.PolicydetailComponent.saveWBA(action);
      if (currentTabIndex === 1) {
        if (action == 'ShowAuditWizard') {
          this.CoverageAssessmentComponent.GetAssessmentIdByCoverageParameterIdAndValue(action);
          this.openRunAuditWizard();
          return;
        }
        if (action == 'RunAuditWizard' || action == 'SaveOnly') {
          this.CoverageAssessmentComponent.GetAssessmentIdByCoverageParameterIdAndValue(action);
          return;
        }
        if (action == 'next') {
          this.CoverageAssessmentComponent.GetAssessmentIdByCoverageParameterIdAndValue(action);
        }
      }
      if (currentTabIndex === 2) this.AccountExecutive?.saveWBA(action);
      if (currentTabIndex === 3) this.SubmissionComponent.openSendCarrierConfirmationModal(action);
      // if (currentTabIndex === 4) this.AccountMgmtServices.saveWBA(action);
      // if (currentTabIndex === 5) this.BindingComponent.saveWBA(action);
      // if (currentTabIndex === 6) this.EpicpolicydDetailsComponent.epicPolicyDetails(action);
    }
  }

  setValidator() {
    this.whiteBoardForm.get('expiringCarrierId')?.setValidators([Validators.required]);
    this.whiteBoardForm.get('expiringCarrierId')?.updateValueAndValidity();
    this.whiteBoardForm.get('expiringPremium')?.setValidators([Validators.required]);
    this.whiteBoardForm.get('expiringPremium')?.updateValueAndValidity();
  }

  openEditContactModel() {
    const modalRef = this.modalService.open(EditContactComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    let data = {
      item: this.contactDetails ? this.contactDetails : this.prospectDetail?.prospect?.contact,
      contactList: this.contactList,
      prospectInfo: this.prospectDetail
    };
    modalRef.componentInstance.fromContactList = data;
    modalRef.componentInstance.passEntry.subscribe((resp: any) => {
      if (resp.editFrom == 'Edit Prospect Contact') {
        this.getWhiteBoardDetailsFromApi(this.WBAid);
      }
    })
    modalRef.closed.subscribe((resp) => {
    });
  }

  getContactlist(prospectid: any, enableLoader: boolean = true) {
    if (prospectid) {
      this.contactList = [];
      this._contactService
        .getContactByProspectId(prospectid, enableLoader)
        .subscribe((resp) => {
          this.contactList = resp.contactList;
          this.contactList.forEach((x: any) => {
            x.fullNames = x.jobTitle ? x.fullName + ' | ' + x.jobTitle : x.fullName
          })
          // if (this.contactList) {
          //   for (let i = 0; i < this.contactList.length; i++) {
          //     if (this.contactList[i].id == this.wbaView.contactId) {
          //       this.contactDetails = this.contactList[i];
          //       break;
          //     }
          //   }
          // }
          this.getContactListResponse(resp);
        });
    }
  }

  openAddContactModal() {
    const modalRef = this.modalService.open(AddContactComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    modalRef.componentInstance.activeProspectId = this.pId;
    modalRef.componentInstance.passEntry.subscribe((resp: any) => {

      if (resp == 'Add Contact') {

        this.getWhiteBoardDetailsFromApi(this.WBAid);
        // this._notifierService._AddContactId.subscribe((resp: any) => {

        // });
      }
    })
    modalRef.closed.subscribe((resp) => {
    });
  }

  //saving insured details tab only
  saveInsuredDetails(loadderdisable: boolean = true) {
    //adding validation for Umbrella
    // let isWBABelongs2Umbrella = this.businessLine.businessLineList.find((x: any) => x.businessLineId == this.f.businessLineId.value);
    // if (!isWBABelongs2Umbrella.newBusiness && this.wbaView?.transType == 'NB') {

    //   this._warningBox1.message = '"Umbrella" is not a valid line, please select "Package" in order to create an "Umbrella" policy.';
    //   this._warningBox1.isVisible = true;
    //   return
    // }

    this.isSubmitted = true;
    this.whiteBoardForm.markAsPristine();
    this.selectedData = '';
    if (!this.whiteBoardForm.get('expiringCarrierId')?.value.value && parseInt(this.whiteBoardForm.get('expiringPremium')?.value) > 0) {
      this.isExpiringCarrierIdRequired = true;
      this.whiteBoardForm.get('expiringCarrierId')?.setValue('')
      return;
    }


    if ((this.whiteBoardForm.get('expiringCarrierId').value == '' || this.whiteBoardForm.get('expiringCarrierId').value.value == '') && this.whiteBoardForm.get('expiringPremium').value == '') {
      this.isExpiringCarrierIdRequired = false;
      this.whiteBoardForm.get('expiringCarrierId')?.setValidators(null);
      this.whiteBoardForm.get('expiringCarrierId')?.updateValueAndValidity();
      this.whiteBoardForm.get('expiringPremium')?.setValidators(null);
      this.whiteBoardForm.get('expiringPremium')?.updateValueAndValidity();
    }

    if (this.wbaView?.transType == 'RN' && this.LeadGeneratorLookupFilterData == '') {
      this.whiteBoardForm.get('leadGeneratorId')?.setValidators(null);
      this.whiteBoardForm.get('leadGeneratorId')?.updateValueAndValidity();
    }
    if (this.wbaView?.transType == 'RN' && this.leadGenratorlist?.length == 0 && this.LeadGeneratorLookupFilterData != '') {
      this.leadGeneratorNotBlankButInvalid = true;
      return;
    }

    if (this.wbaView?.masterBusinessLine !== 'Workers Compensation') {
      this.whiteBoardForm.get('ein')?.setValidators(null);
      this.whiteBoardForm.get('ein')?.updateValueAndValidity();
    }

    this.isBtnDisabled = true;
    if (this.whiteBoardForm && this.whiteBoardForm.valid) {
      let data = this.whiteBoardForm.value;
      if (!data.borInDiscussion && !this.wbaView.borInDiscussionEnable) {
        this.progressIdAfterBORinDiscuss = 2;
      } else {
        this.progressIdAfterBORinDiscuss = parseInt(this.whiteBoardForm.controls['progressId'].value);

      }
      this.invalidowner = {
        id: data.ownerName.id ? data.ownerName.id : '',
        value: data.ownerName.value ? data.ownerName.value : ''
      };
      if (!data.ownerName.id) {
        this.isBtnDisabled = false;
        return
      }
      this.invalidLeadGerator = {
        id: data.leadGeneratorId.id ? data.leadGeneratorId.id : '',
        value: data.leadGeneratorId.value ? data.leadGeneratorId.value : ''
      };
      if (this.wbaView?.transType == 'NB') {
        if (!data.leadGeneratorId.id) {
          this.isBtnDisabled = false;
          return
        }
      }
      if (this.invalidMarketingManager == true || this.invalidAccountManager == true || this.invalidExpiringCarrier == true) {
        this.isBtnDisabled = false
        return;
      }

      let WBAidfromLS = sessionStorage.getItem(environment.storageVariables.wbaId);
      let saveData = {
        "id": this.WBAid || WBAidfromLS,
        "prospectId": data.companyName.id,
        "targetPremium": this.whiteBoardForm.controls['targetPremium'].value ? parseInt(this._removeAmountPipe.transform(this.whiteBoardForm.controls['targetPremium'].value)) : 0,
        "masterBusinessStatusId": data.businessStatusId,
        "masterBusinessLineId": this.whiteBoardForm.controls['businessLineId'].value, //data.businessLineId,
        "industryId": this.whiteBoardForm.controls['industryId'].value ? this.whiteBoardForm.controls['industryId'].value : 0, // data.industryId ? data.industryId : 0,
        "prospectSizeId": this.whiteBoardForm.controls['tierId'].value ? this.whiteBoardForm.controls['tierId'].value : 0, //data.tierId ? data.tierId : 0,
        "effectiveDate": this._datepipe.transform(data.effectiveDate, 'yyyy-MM-dd'),
        "ein": this.wbaView.ein,
        "contactId": this.contactExisted ? data.contactId : 0,
        "OwnerUserId": data.ownerName.id,
        "masterWBAPriorityId": data.wbaPriorityId ? data.wbaPriorityId : 0,
        "marketingManagerId": data.marketingManager.id,
        "epicStatusId": this.whiteBoardForm.controls['epicStatusId'].value ? parseInt(this.whiteBoardForm.controls['epicStatusId'].value) : 0,
        "masterLeadGeneratorId": data.leadGeneratorId?.id ? data.leadGeneratorId.id : 0,
        "progressId": this.progressIdAfterBORinDiscuss,
        "masterExpiringCarrierId": this.whiteBoardForm.controls['expiringCarrierId'].value.id ? parseInt(this.whiteBoardForm.controls['expiringCarrierId'].value.id) : 0,
        "expiringPremium": this.whiteBoardForm.controls['expiringPremium'].value ? parseInt(this._removeAmountPipe.transform(this.whiteBoardForm.controls['expiringPremium'].value)) : 0,
        "expiringRevenue": data.expiringRevenue ? parseFloat(this._removeAmountPipe.transform(data.expiringRevenue)) : 0,
        "expectedBoundRevenue": data.targetRevenue ? parseInt(this._removeAmountPipe.transform(data.targetRevenue)) : 0,
        "accountManagerUserId": data.accountManagerUserId.id,
        "isExisting": this.contactExisted,
        "contact": {
          "id": this.contactExisted ? data.contactId : 0,
          "prospectId": data.prospectId,
          "firstName": data.contact.firstName,
          "lastName": data.contact.lastName,
          "jobTitle": data.contact.jobTitle,
          "office1PhoneNumber": data.contact.phoneNumber ? data.contact.phoneNumber.toString() : this.PhoneNumber.value.toString(),
          "office1Extension": data.contact.extension,
          "office1Email": data.contact.emailAddress,
        },
        "policyTypeId": this.whiteBoardForm.controls['policyTypeId'].value ? parseInt(this.whiteBoardForm.controls['policyTypeId'].value) : 0,
        "renewalStatusId": data.renewalStatusId ? data.renewalStatusId : 0,
        "opportunityApproved": this.whiteBoardForm.controls['opportunityApproved'].value ? this.whiteBoardForm.controls['opportunityApproved'].value : null,
        "opportunityApprovedBy": this.whiteBoardForm.controls['opportunityApproved'].value == 'Yes' ? this.userId : null,
        "opportunityDeclinedBy": this.whiteBoardForm.controls['opportunityApproved'].value == 'No' ? this.userId : null,
        "borInDiscussion": data.borInDiscussion ? data.borInDiscussion : false,
        "profitCenter": this.whiteBoardForm.controls['profitCenter'].value,
      }
      this._addWBAService.updateWBActivity(saveData, loadderdisable).subscribe(resp => {
        if (resp) {
          // updating storage, businessLineId,industryId and tierId may be change
          sessionStorage.setItem('paylpadForMasterCoveragesAPI', JSON.stringify(saveData));
          //updating lob id for additional and checklist tabs
          this.emittedbusinessLineId = this.whiteBoardForm.controls['businessLineId'].value == 5 ? this.whiteBoardForm.controls['policyTypeId'].value : this.whiteBoardForm.controls['businessLineId'].value;
          this.emittedlobIdForSpeciality = this.whiteBoardForm.controls['businessLineId'].value
          this.isBtnDisabled = false;
          this.isSubmitted = false;
          if (loadderdisable) this.show();
          this.isExecutiveSummaryClicked = false;
          this.selectedContactId = data.contactId;
          this.getWhiteBoardDetailsFromApi(saveData.id, false);
          //this.GetMasterCoverageListByBusinessLineTierAndIndustryWbaId();
          this.showError = false;
          this.selectedData = '';
        }
      },
        (err: any) => {
          this.errorList = []
          this.showError = true;
          this.isBtnDisabled = false;
          if (err.status == 403) {
            this.showError = true;
            this.errorList.push(this.unAthorizesd)
            return
          }
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorList = [];
            this.errorMessage = arr[0].value[0];
            this.errorList.push(this.errorMessage);
          }
          if (err.status == 500) {
            this.errorMessage = "Error : " + err.message + ", Status: " + err.status;;
            this.errorList.push(this.errorMessage);
          }
        });
    }
    else {
      this.isBtnDisabled = false;
    }
  }
  getContactByName(checkName: any) {
    this._addWBAService.getContactByName(checkName.query, this.checkprospect,).subscribe(resp => {
      this.contactlist = resp.contactList;
    })
  }

  typeOfEvent(event: any) {
    let value1 = event.target.value;

    if (value1 == 'Lamb') {
      this.SelectedvalueforProfit = 'Lamb'
    } else if (value1 == 'CREIS') {
      this.SelectedvalueforProfit = 'CREIS'
    } else if (value1 == 'TruePartners') {
      this.SelectedvalueforProfit = 'TruePartners'
    }

  }

  checkaddressChoice(val: any) {
    this.showaddressChoice = val;
    if (val == true) {
      this.contactExisted = true;
      this.editIcon = true
      this.whiteBoardForm.get('contact').disable();
      this.whiteBoardForm.get('contact.firstName').enable();
      if (this.wbalistcopy.office1Extension) {
        this.offece1EXT = parseInt(this.wbalistcopy?.office1Extension) ? parseInt(this.wbalistcopy?.office1Extension) : null;
        this.whiteBoardForm.get('contact.extension').setValue(this.offece1EXT);
      }
      else {
        this.whiteBoardForm.get('contact.extension').setValue('');
      }
      this.isPrimaryForEmail = (this.wbalistcopy?.office1Email != null && this.wbalistcopy?.office1Email != "") ? true : false
      this.whiteBoardForm.get('contact').patchValue({
        firstName: this.wbalistcopy.firstName,
        lastName: this.wbalistcopy.lastName,
        jobTitle: this.wbalistcopy.jobTitle,
        phoneNumber: this.wbalistcopy.office1PhoneNumber,
        emailAddress: this.wbalistcopy.office1Email
      });
    }
    else if (val == false) {
      this.contactExisted = false;
      this.editIcon = false
      this.isPrimaryForEmail = false;
      this.whiteBoardForm.get('contact').reset();
      this.whiteBoardForm.get('contact').patchValue({
        firstName: '',
        lastName: '',
        jobTitle: '',
        phoneNumber: '',
        extension: '',
        emailAddress: '',
        id: 0
      });
      this.whiteBoardForm.get('contact').enable();
      if (this.pId) {
        this.whiteBoardForm.get('contact').patchValue({
          prospectId: this.pId
        });
      }
      else {
        this.whiteBoardForm.get('contact').patchValue({
          prospectId: this.wbalistcopy.prospectId
        });
      }
    }
  }

  clearValueForAccount() {
    this.invalidAccountManager = false;
  }
  clearValue() {
    this.invalidMarketingManager = false;
  }

  getCompanyList(fieldName: any, val: any) {
    this.showError = false;

    this.isExpiringCarrierIdRequired = false;
    if (fieldName == 'leadGenerator') {
      this.invalidLeadGerator = {
        id: '',
        value: ''
      }
      this.LeadGeneratorLookupFilterData = val.query;
    }
    else if (fieldName == 'marketingManager' && val.query != '') {
      this.invalidMarketingManager = true;
      this.MarketingManagerLookupFilterData = val.query;
    } else if (fieldName == 'accountManager' && val.query != '') {
      this.invalidAccountManager = true;
      this.AccountManagerLookupFilterData = val.query;
    }
    else if (fieldName == 'carrier' && val.query != '') {
      this.ExpiringCarrierLookupFilterData = val.query;
    }
    if (fieldName && val.query) {
      this._addWBAService.getTypeheadList(fieldName, val.query).subscribe(resp => {
        if (fieldName == 'companyName') {
          this.accountList = resp.prospectList;
        }
        else if (fieldName == 'carrier') {
          this.expiringCarrierlist = resp.expiringCarrierList;
          this.invalidExpiringCarrier = true
        }
        else if (fieldName == 'leadGenerator') {
          this.leadGenratorlist = resp.leadGeneratorList;
        }
        else if (fieldName == 'accountManager') {
          this.accountManagerlist = resp.userList;
        }
        else (fieldName == 'marketingManager')
        this.marketingManagerlist = resp.userList;

      })
    }

  }


  getOwnerlist(result: any) {
    this.invalidowner = {
      id: '',
      value: ''
    };
    let data = {
      searchType: "Owner",
      value: result.query
    };
    this.ownerDatalookupFilterData = result.query;
    this._addWBAService.getOwnerList(data).subscribe(resp => {
      this.ownerlist = resp.userList;
    })
  }

  tabChange(item: any) {
    //checking effective date is valid or not
    if (this.wbaView?.effectiveDate == "Mon Jan 01 0001 00:00:00 GMT+0553 (India Standard Time)" || this.wbaView?.effectiveDate == "0001-01-01T00:00:00" || !this.wbaView?.effectiveDate) {
      this.whiteBoardForm.get('effectiveDate').patchValue('');
      this.whiteBoardForm.controls.effectiveDate.status = "INVALID";
      this.isSubmitted = true;
      return
    }

    sessionStorage.setItem(environment.storageVariables.wbaId, this.WBAid);
    this.detailsTabChanges = false
    this.clickTabName = item.tabName;
    this.checkUnSaveDataforOpenTab();
    if (this.isMoveToNextTab) {
      this.whiteBoardForm.markAsPristine();
      this.shouldMoveToNextTab = false;
      this.isDirty = false
      if (item.tabName == 'Internal Notes') {
        let noteslistData = this._dataCacheService.retrieveData('wba_tab_notesList');
        this.notesList = noteslistData;
        if (!this.notesList) this.NotesTabClick();
      }
      if (item.tabName == 'WBA Details') {
        this.currentTab = this.tabs[0].label;
        this.GetMasterCoverageListByBusinessLineTierAndIndustryWbaId()
        // this.getAccessReviewList(this.currentTab);
        let AcordResponse = this._dataCacheService.retrieveData('wba_AcordResponse_reviewList');
        if (!AcordResponse || AcordResponse == undefined || AcordResponse != null) this.mapAccessReviewList(this.currentTab);
        else this.getAccessReviewList(this.currentTab);
        this.AcordPermissionResponse = this._dataCacheService.retrieveData('wba_AcordPermissionResponse');
        if (!this.AcordPermissionResponse.length) this.getAccordPermissionByWbaId();

      }

      if (item.tabName == 'Email') {
        let emailListData = this._dataCacheService.retrieveData('wba_tab_emailList');
        if (!emailListData) {
          this.getWBAEmailList(this.WBAid);
        }
        else {
          this.wbaEmailList = emailListData;
          this.gridData = {
            data: emailListData,
            total: emailListData.length,
          };

          this.gridMasterData = {
            data: emailListData,
            total: emailListData.length,
          };

          this.wbaEmailListFilterData = emailListData;
          this.pageChangeEmailList()
          this.sort = [{ field: "emailDate", dir: "desc" }, { field: 'emailTime', dir: "desc" }];
          this.sortChange(this.sort);
        }
      }

      //setting horizontal active tab
      this.activeTab = item.tabName;
      //setting wba details default value for vertical tabs
      this.currentTab = this.tabs[0].label;
      this.setBGColorOnSelectedTab();
    }
  }

  GetMasterCoverageListByBusinessLineTierAndIndustryWbaId() {
    //   let wbaCarrierList = this._dataCacheService.retrieveData('wba_wbaCarrierList');
    //   let wbaSubmissionTemplateList =this._dataCacheService.retrieveData('wba_wbaEmailSubmission');
    //   let userList = this._dataCacheService.retrieveData('wba_userListLookup');
    //   let acordResponse = this._dataCacheService.retrieveData('wba_AcordResponse');
    //   let wbaReviews = this._dataCacheService.retrieveData('wba_wbaResponse');
    //  let acordReasonList = this._dataCacheService.retrieveData('wba_reasonResponse');
    //  let wbaFiles = this._dataCacheService.retrieveData('wba_wbaFileResponse');
    //   let mrkacordReasonList = this._dataCacheService.retrieveData('wba_marketingResponse');
    //   let wbaBorDetailList = this._dataCacheService.retrieveData('wba_borResponse');
    //   this.CoverageAssessmentData = this._dataCacheService.retrieveData('wba_CoverageAssessmentDetails');
    //   let wbasubmissionList = this._dataCacheService.retrieveData('userSubmissionInfo');

    let tabRefresh = this._dataCacheService.retrieveData('detailstabRefresh');

    //this.masterCoverageListByLineTierAndIndustry = this._dataCacheService.retrieveData('wba_checkList_coverageList');
    // if (this.masterCoverageListByLineTierAndIndustry.length == 0) this.GetMasterCoverageListByBusinessLineTierAndIndustry();
    if (tabRefresh.refresh) this.GetAllMasterCoverageListByBusinessLineTierAndIndustryWbaId();
    else this.mapWbaDetailsData()
  }

  mapWbaDetailsData() {
    this.CoverageAssessmentData = this._dataCacheService.retrieveData('wba_CoverageAssessmentDetails');
    this.checkUncheckSelectedCoveragesAndParameters();
    let wbasubmissionList = this._dataCacheService.retrieveData('userSubmissionInfo');
    if (wbasubmissionList?.length == 0 || wbasubmissionList == undefined || wbasubmissionList == null) {
      this.handleDetailsTabErrorIcon('Submissions', true);
    }
    else {
      this.handleDetailsTabErrorIcon('Submissions', false);
    }
    if (this.CoverageAssessmentData?.coverageAssessmentDetail.length) {
      this.isAssesmentPopUp = true;
      this.handleDetailsTabErrorIcon('Coverage Checklist', false);
    }
    if (this.wbaView?.transType == 'NB') this.checkRatingBasisHasRequiredValues();
  }

  checkRatingBasisHasRequiredValues() {
    if (this.CoverageAssessmentData.coverageAssessmentDetail[0]?.coverageName == "Workers Compensation") {
      if (this.CoverageAssessmentData.coverageAssessmentDetail[0]?.workercompRating?.length == 0) {
        this.handleDetailsTabErrorIcon('Coverage Assessment', true);
        this.handleDetailsTabErrorIcon('Coverage Assessment ', true);
      }
      else {
        this.handleDetailsTabErrorIcon('Coverage Assessment', false);
        this.handleDetailsTabErrorIcon('Coverage Assessment ', false);
      }
    }
    if (this.CoverageAssessmentData.coverageAssessmentDetail[0]?.coverageName == "General Liability") {
      if (this.CoverageAssessmentData.coverageAssessmentDetail[0]?.ratingBasis?.length == 0) {
        this.handleDetailsTabErrorIcon('Coverage Assessment', true);
        this.handleDetailsTabErrorIcon('Coverage Assessment ', true);
      }
      else {
        this.handleDetailsTabErrorIcon('Coverage Assessment', false);
        this.handleDetailsTabErrorIcon('Coverage Assessment ', false);
      }
    }
    if (this.CoverageAssessmentData.coverageAssessmentDetail[0]?.coverageName == "Cyber") {
      if (this.CoverageAssessmentData.coverageAssessmentDetail[0]?.ratingBasis?.length == 0) {
        this.handleDetailsTabErrorIcon('Coverage Assessment', true);
        this.handleDetailsTabErrorIcon('Coverage Assessment ', true);
      }
      else {
        this.handleDetailsTabErrorIcon('Coverage Assessment', false);
        this.handleDetailsTabErrorIcon('Coverage Assessment ', false);
      }
    }
  }

  GetMasterCoverageListByBusinessLineTierAndIndustry() {
    var masterCoverageListByBusinessLineTierAndIndustryWbaId = this._addWBAService.GetMasterCoverageListByBusinessLineTierAndIndustryWbaId(this.emittedbusinessLineId, parseInt(this.selectedIndustryValue), this.tierId, this.WBAid); //to get masters coverages
    forkJoin([masterCoverageListByBusinessLineTierAndIndustryWbaId]).subscribe(resp => {
      this.masterCoverageListByLineTierAndIndustry = resp[0].coverageList;
      this._dataCacheService.compressAndCacheData('wba_checkList_coverageList', this.masterCoverageListByLineTierAndIndustry);
      this.checkUncheckSelectedCoveragesAndParameters();
    },
      (err: any) => {
        this.errorList = []
        this.showError = true;
        this.isBtnDisabled = false;
        if (err.status == 403) {
          this.showError = true;
          this.errorList.push(this.unAthorizesd)
          return
        }
        if (err.status == 400) {
          this.errorMessage = "Error : " + err.error.errors.ProspectId[0];
          this.errorList.push(this.errorMessage);
        }
        if (err.status == 500) {
          this.errorMessage = "Error : " + err.message + ", Status: " + err.status;;
          this.errorList.push(this.errorMessage);
        }
      });

  }

  GetAllMasterCoverageListByBusinessLineTierAndIndustryWbaId() {
    let requestBody = {
      wbaId: this.WBAid,
      isRenewal: this.wbaView?.transType == 'NB' ? false : true
    }
    let data = {
      "lookUpType": 'WBASubmisiionUser',
      "rowCount": 10
    }
    let datarsm = {
      acordReasonType: "rsm"
    }
    console.log("bsl", this.businessLine)
    let businesslineValue = this.businessLine.find((el: any) => el?.businessLineId == this.selectedValue)
    let BusinessLineId = businesslineValue?.businessLineId;
    let blrequestBody = {
      wbaId: this.WBAid,
      lineId: BusinessLineId
    }
    let mrktrequest = {
      acordReasonType: "marketing"
    }

    let PolyRnpayload = {
      policyId: this.wbaView?.epicUniquePolicyId,
      wbaId: this.WBAid
    }

    let dataAE = {
      acordReasonType: "AE"
    }

    var wbaAcarrierList = this._addWBAService.getWbaSubmissionCarrierListById(this.WBAid);
    // var wbaActivityList = this._addWBAService.getWBAById(this.WBAid);
    var carrierList = this._addWBAService.getWbaCarrierListById(this.WBAid);
    var emailTemplateList = this._addWBAService.getWbaEmailTemplateByWbaId(this.WBAid);
    var wbalookup = this._addWBAService.getWhiteBoardActivityLookUp(data);
    // var businessLineList = this._addWBAService.getBusinessLineList();
    var accessReviewList = this._addWBAService.getAccessReviewList(requestBody);
    var AccordReasonList = this._addWBAService.getAccordReasonList(datarsm);
    // var CoverageAssessmentDetails = this._addWBAService.getCoverageAssessmentDetails(this.WBAid);
    var wbafilebyid = this._addWBAService.getWBAFileById(blrequestBody);
    var AccordReasonListMrkt = this._addWBAService.getAccordReasonList(mrktrequest);
    var wbaBorDetails = this._addWBAService.getWbaBorDetailList(requestBody);
    var coverageAssessmentDetails = this._addWBAService.getCoverageAssessmentDetails(this.WBAid);
    var masterCoverageListByBusinessLineTierAndIndustryWbaId = this._addWBAService.GetMasterCoverageListByBusinessLineTierAndIndustryWbaId(this.emittedbusinessLineId, parseInt(this.selectedIndustryValue), this.tierId, this.WBAid); //to get masters coverages
    // var WbaPolicyRenewDetails = this._addWBAService.getWbaPolicyRenewDetails(PolyRnpayload);
    var AccordReasonListAE = this._addWBAService.getAccordReasonList(dataAE);
    forkJoin([wbaAcarrierList, carrierList, emailTemplateList, wbalookup,
      accessReviewList, AccordReasonList, wbafilebyid, AccordReasonListMrkt, wbaBorDetails, coverageAssessmentDetails, masterCoverageListByBusinessLineTierAndIndustryWbaId, AccordReasonListAE]).subscribe(resp => {
        this._dataCacheService.compressAndCacheData('WbaSubmissionCarrierList', resp[0].wbaSubmissionCarrierList);
        this._dataCacheService.compressAndCacheData('wba_wbaCarrierList', resp[1].wbaCarrierList);
        this._dataCacheService.compressAndCacheData('wba_wbaEmailSubmission', resp[2].wbaSubmissionTemplateList[0]);
        this._dataCacheService.compressAndCacheData('wba_userListLookup', resp[3].userList);
        this._dataCacheService.compressAndCacheData('wba_AcordResponse_reviewList', resp[4]);
        this._dataCacheService.compressAndCacheData('wba_AcordResponse', resp[4].acordResponse);
        this._dataCacheService.compressAndCacheData('wba_wbaResponse', resp[4].wbaReviews);
        this._dataCacheService.compressAndCacheData('wba_reasonResponse', resp[5].acordReasonList);
        this._dataCacheService.compressAndCacheData('wba_wbaFileResponse', resp[6].wbaFiles);
        this._dataCacheService.compressAndCacheData('wba_marketingResponse', resp[7].acordReasonList);
        this._dataCacheService.compressAndCacheData('wba_borResponse', resp[8].wbaBorDetailList);
        this.CoverageAssessmentData = resp[9];
        this.masterCoverageListByLineTierAndIndustry = resp[10].coverageList;
        this._dataCacheService.compressAndCacheData('reasonResponseAe', resp[11].acordReasonList);
        this._dataCacheService.compressAndCacheData('wba_checkList_coverageList', this.masterCoverageListByLineTierAndIndustry);
        this._dataCacheService.compressAndCacheData('wba_CoverageAssessmentDetails', this.CoverageAssessmentData);
        this.checkUncheckSelectedCoveragesAndParameters();
        let wbasubmissionList: any = resp[1].userSubmissionInfo;
        this._dataCacheService.compressAndCacheData('userSubmissionInfo', wbasubmissionList);

        let tabRefresh = this._dataCacheService.retrieveData('detailstabRefresh');
        tabRefresh.refresh = false;
        this._dataCacheService.compressAndCacheData('detailstabRefresh', tabRefresh);
        if (wbasubmissionList?.length == 0 || wbasubmissionList == undefined || wbasubmissionList == null) {
          this.handleDetailsTabErrorIcon('Submissions', true);
        }
        else {
          this.handleDetailsTabErrorIcon('Submissions', false);
        }
        if (this.CoverageAssessmentData?.coverageAssessmentDetail.length) {
          this.isAssesmentPopUp = true;
          this.handleDetailsTabErrorIcon('Coverage Checklist', false);
        }
        if (this.wbaView?.transType == 'NB') this.checkRatingBasisHasRequiredValues();
        if (this.wbaView?.transType == 'RN' && this.wbaView?.epicUniquePolicyId != 0) {
          this.getWbaPolicyRenewDetails();
        }
      },
        (err: any) => {
          this.errorList = []
          this.showError = true;
          this.isBtnDisabled = false;
          if (err.status == 403) {
            this.showError = true;
            this.errorList.push(this.unAthorizesd)
            return
          }
          if (err.status == 400) {
            this.errorMessage = "Error : " + err.error.errors.ProspectId[0];
            this.errorList.push(this.errorMessage);
          }
          if (err.status == 500) {
            this.errorMessage = "Error : " + err.message + ", Status: " + err.status;;
            this.errorList.push(this.errorMessage);
          }
        });

  }

  getWbaPolicyRenewDetails() {
    let PolyRnpayload = {
      policyId: this.wbaView?.epicUniquePolicyId,
      wbaId: this.WBAid
    }
    this._addWBAService.getWbaPolicyRenewDetails(PolyRnpayload).subscribe((resp: any) => {
      this._dataCacheService.compressAndCacheData('renewPolicyDetails', resp.policyDetails);
    }
      ,
      (err: any) => {
        this.errorList = []
        this.showError = true;
        this.isBtnDisabled = false;
        if (err.status == 403) {
          this.showError = true;
          this.errorList.push(this.unAthorizesd)
          return
        }
        if (err.status == 400) {
          this.errorMessage = "Error : " + err.error.errors.ProspectId[0];
          this.errorList.push(this.errorMessage);
        }
        if (err.status == 500) {
          this.errorMessage = "Error : " + err.message + ", Status: " + err.status;;
          this.errorList.push(this.errorMessage);
        }
      });
  }

  checkUncheckSelectedCoveragesAndParameters() {
    this.CoverageCheckList.Coverages = [];
    if (this.CoverageAssessmentData?.coverageAssessmentDetail.length) {
      this.isAssesmentPopUp = true;
      this.CoverageAssessmentData.coverageAssessmentDetail.forEach((element: coverageModel) => {
        let coverageParamemter: any[] = [];

        // finding the coverages and checking them
        let slectedCoverage = this.masterCoverageListByLineTierAndIndustry.find((cov: any) => cov.id === element.coverageId);
        if (slectedCoverage) {
          // checking only selected coverage
          slectedCoverage.isChecked = true;

          // finding the parameters of the coverages and checking them
          slectedCoverage.coverageParameters.filter((param: any) => element.coverages.some((obj: coverageParameterModel) => obj.coverageParameterId === param.id)).map((param: any) => {
            param.isChecked = true;

            let existingAssessmentParam = element.coverages.find((item: any) => param.id === item.coverageParameterId);
            if (existingAssessmentParam) {
              param.expiringValue = existingAssessmentParam.expiringValue;
              param.recommendedValue = existingAssessmentParam.recommendedValue;
              param.offeringGuide = existingAssessmentParam.offeringGuide;
              param.expiringConditionDescription = existingAssessmentParam.expiringConditionDescription;
              param.recommendedConditionDescription = existingAssessmentParam.recommendedConditionDescription;
              param.expiringAssessmentId = existingAssessmentParam.expiringAssessmentId;
              param.recommendedAssessmentId = existingAssessmentParam.recommendedAssessmentId;
            }

            coverageParamemter.push(param);
          });


          let CoverageToSend = { ...slectedCoverage };
          // CoverageToSend.coverageParameters.sort((a: { sequence: number; }, b: { sequence: number; }) => a.sequence - b.sequence);
          // CoverageToSend.coverageParameters = coverageParamemter;
          this.CoverageCheckList.Coverages.push(CoverageToSend);
        }

      });
      if (this.checkRequiredExpiringOrRecommendedCoverageHasValues() && this.wbaView?.transType == 'NB') {
        this.handleDetailsTabErrorIcon('Coverage Assessment', false);
        this.handleDetailsTabErrorIcon('Coverage Assessment ', false);
      }

      if (this.wbaView?.transType == 'RN') {
        this.handleDetailsTabErrorIcon('Coverage Assessment', false);
      }
    }
    else {
      //cleared storage because wba is fresh now
      //but it should be a fresh WBA when lob, policytype, industry and tier changes
      sessionStorage.setItem(environment.storageVariables.CoverageCheckListStorage, '[]')
      this.isAssesmentPopUp = false
    }

    if (this.CoverageCheckList && this.CoverageCheckList?.Coverages && this.CoverageCheckList?.Coverages.length) {
      // storing the coverages in local storage
      sessionStorage.setItem(environment.storageVariables.CoverageCheckListStorage, JSON.stringify(this.CoverageCheckList.Coverages));
    }
  }

  checkRequiredExpiringOrRecommendedCoverageHasValues() {
    let result: boolean = true
    if (!this.CoverageCheckList?.Coverages?.length) {
      result = false
    } else if (this.CoverageCheckList?.Coverages) {
      for (const element of this.CoverageCheckList?.Coverages) {
        if (element?.coverageParameters) {
          for (const param of element.coverageParameters) {
            if (param.isRequired && param.displayName != "Named Insured" && (param.recommendedValue == "" || param.recommendedValue == "undefined")) {
              result = false
              break;
            }
          }
        }
      }
    }
    return result;
  }

  detailsTabChange(item: any) {
    if (this.currentTab === "Policy Detail & Loss Runs") {
      if ((!this.policyDescriptionValue || (this.policyDescriptionValue != this.policyDescriptionchangeValue)) && (!this.isWbaClosed)) {
        this.PolicydetailComponent.isSubmitted = true;
        this._confirmBoxForClone.isVisible = true;
        this._confirmBoxForClone.message = "Policy Description is Mandatory."
        return
      }
    }
    sessionStorage.setItem(environment.storageVariables.wbaId, this.WBAid);
    this.detailsTabChanges = true
    this.clickOnTabName = item.label;
    this.checkUnSaveDataforOpenTab();

    if (this.isMoveToNextTab) {
      this.shouldMoveToNextTab = false;
      const tab = this.tabs.find(t => t.label == item.label);
      this.checkNoMasterCovData();
      if (tab?.required && this.noMasterCov) {
        this.showNoAuditWarningBox();
        this.currentTab = item.label;
        this.setBGColorOnSelectedTab();
      }
      else if (!this.isAssesmentPopUp && tab?.required && this.wbaView.transType == 'NB') {
        this.showWarningBox();
      }
      else if (!this.checkRequiredExpiringOrRecommendedCoverageHasValues() && (item.tb > 4 && item.tb < 11) &&
        !this.doNotCheckRequiredAssessment && !this.acordProgressSubmitted && ((this.wbaView.outcome != 'Bound' && this.wbaView.outcome != 'Lost') && (this.wbaView.wbaStatusDescription != 'Won' && this.wbaView.wbaStatusDescription != 'Lost')) && this.wbaView?.transType == 'NB'
      ) {
        this.isAssesmentParamsHasMandatoryValues = true;
        if (!this.CoverageCheckList?.Coverages.length && !this.coverageCheckListPayload?.coverageAssessmentDetail.length) {
          this._warningBox.message = this._userMessages.wbaRequiredDetailsWarningMessage;
          this._warningBox.isVisible = true;
        } else {
          this.isAssesmentParamsHasMandatoryValuesPopup = true;
        }
        this._notifierService._wbaIsSubmittedOnTabChange.next('true');
        //this.currentTab = item.label;
        //this.setBGColorOnSelectedTab();
      }
      else if ((item.tb > 4 && item.tb < 11) && this.doNotCheckRequiredAssessment && this.isAssesmentParamsHasMandatoryValues && !this.acordProgressSubmitted && ((this.wbaView.outcome != 'Bound' && this.wbaView.outcome != 'Lost') && (this.wbaView.wbaStatusDescription != 'Won' && this.wbaView.wbaStatusDescription != 'Lost')) && this.wbaView?.transType == 'NB') {
        if (!this.CoverageCheckList?.Coverages.length && !this.coverageCheckListPayload?.coverageAssessmentDetail.length) {
          this._warningBox.message = this._userMessages.wbaRequiredDetailsWarningMessage;
          this._warningBox.isVisible = true;
        } else {
          this.isAssesmentParamsHasMandatoryValuesPopup = true;
        }
        this._notifierService._wbaIsSubmittedOnTabChange.next('true');
        //this.currentTab = item.label;
        //this.setBGColorOnSelectedTab();
      }
      else if (tab.label === "Submissions") {
        this.submissionTab = tab;
        let AcordResponse = this._dataCacheService.retrieveData('wba_AcordResponse_reviewList');
        if (!AcordResponse || AcordResponse == undefined || AcordResponse != null) this.mapAccessReviewList(tab.label);
        else this.getAccessReviewList(tab.label);
      }
      else {
        this.submissionTab = {}
        this.currentTab = item.label;
        this.setBGColorOnSelectedTab();
      }
    }

    document.getElementById('scrollUp')?.scrollIntoView({ behavior: "smooth" });
    window.scroll(0, 0);
  }

  checkNoMasterCovData() {
    if (this.masterCoverageListByLineTierAndIndustry && !this.doNotCheckMasterCovData) {
      for (const param of this.masterCoverageListByLineTierAndIndustry) {
        if (param.coverageParameters.length == 0) {
          this.noMasterCov = true;
        }
        else {
          this.noMasterCov = false;
          break;
        }
      }
    }
  }

  showNoAuditWarningBox() {
    this._warningBox.message = this._userMessages.noMasterCoverages;
    this._warningBox.isVisible = true;
  }

  checkUnSaveDataforOpenTab() {
    if (!this.shouldMoveToNextTab && this.activeTab == 'Insured Details') {
      if (this.selectedData) {
        this.whiteBoardForm.markAsDirty();
      }
      if (this.whiteBoardForm.dirty) {
        this._confirmBoxForApplication.message = this._userMessages.cancelButtonMessage;
        this._confirmBoxForApplication.isVisible = true;
        this.isMoveToNextTab = false
      } else {
        this.isMoveToNextTab = true
      }
    } else if (!this.shouldMoveToNextTab && this.currentTab === "Proposals") {
      if (this.ProposalComponent && this.ProposalComponent.proposalForm.dirty) {
        this._confirmBoxForApplication.message = this._userMessages.cancelButtonMessage;
        this._confirmBoxForApplication.isVisible = true;
        this.isMoveToNextTab = false;
      }
      else {
        this.isMoveToNextTab = true;
      }
    } else if (!this.shouldMoveToNextTab && this.currentTab === "Policy Detail & Loss Runs") {

      if (this.PolicydetailComponent && JSON.stringify(this.PolicydetailComponent.originalWhiteBoardForm) && (JSON.stringify(this.PolicydetailComponent.whiteBoardForm) !== JSON.stringify(this.PolicydetailComponent.originalWhiteBoardForm) || this.PolicydetailComponent?.lossDirty)) {
        this._confirmBoxForApplication.message = this._userMessages.cancelButtonMessage;
        this._confirmBoxForApplication.isVisible = true;
        this.isMoveToNextTab = false;
      }
      else {
        this.isMoveToNextTab = true;
      }
    } else if (!this.shouldMoveToNextTab && this.currentTab === "Additional Information") {
      if (this.AdditionalInformationComponent && this.AdditionalInformationComponent.isPageDirty) {
        this._confirmBoxForApplication.message = this._userMessages.cancelButtonMessage;
        this._confirmBoxForApplication.isVisible = true;
        this.isMoveToNextTab = false;
      }
      else {
        this.isMoveToNextTab = true;
      }
    } else if (!this.shouldMoveToNextTab && this.currentTab === "Coverage Assessment") {
      if (this.CoverageAssessmentComponent && this.CoverageAssessmentComponent.isDirty) {
        this._confirmBoxForApplication.message = this._userMessages.cancelButtonMessage;
        this._confirmBoxForApplication.isVisible = true;
        this.isMoveToNextTab = false;
      }
      else {
        this.isMoveToNextTab = true;
      }
    } else if (!this.shouldMoveToNextTab && this.currentTab === "Coverage Checklist") {
      if (this.CoverageChecklistComponent && this.CoverageChecklistComponent.isPageDirty) {
        this._confirmBoxForApplication.message = this._userMessages.cancelButtonMessage;
        this._confirmBoxForApplication.isVisible = true;
        this.isMoveToNextTab = false;
      }
      else {
        this.isMoveToNextTab = true;
      }
    } else if (!this.shouldMoveToNextTab && this.currentTab === "CE Status") {
      if (this.AcordComponent && this.AcordComponent.isDirty == true) {
        this._confirmBoxForApplication.message = this._userMessages.cancelButtonMessage;
        this._confirmBoxForApplication.isVisible = true;
        this.isMoveToNextTab = false;
      }
      else {
        this.isMoveToNextTab = true;
      }
    } else if (!this.shouldMoveToNextTab && this.currentTab === "AE Status") {
      if (this.AccountExecutive && this.AccountExecutive.isDirty == true) {
        this._confirmBoxForApplication.message = this._userMessages.cancelButtonMessage;
        this._confirmBoxForApplication.isVisible = true;
        this.isMoveToNextTab = false;
      }
      else {
        this.isMoveToNextTab = true;
      }
    } else if (!this.shouldMoveToNextTab && this.currentTab === "Marketing Review") {
      if (this.MaketingComponent && this.MaketingComponent.isDirty == true) {
        this._confirmBoxForApplication.message = this._userMessages.cancelButtonMessage;
        this._confirmBoxForApplication.isVisible = true;
        this.isMoveToNextTab = false;
      }
      else {
        this.isMoveToNextTab = true;
      }
    } else if (!this.shouldMoveToNextTab && this.currentTab === "SD Approval") {
      if (this.sdRsmComponent && this.sdRsmComponent.isDirty == true) {
        this._confirmBoxForApplication.message = this._userMessages.cancelButtonMessage;
        this._confirmBoxForApplication.isVisible = true;
        this.isMoveToNextTab = false;
      }
      else {
        this.isMoveToNextTab = true;
      }
    } else if (!this.shouldMoveToNextTab && this.currentTab === "Account Manager") {
      if (this.AccountManager && this.AccountManager.isDirty == true) {
        this._confirmBoxForApplication.message = this._userMessages.cancelButtonMessage;
        this._confirmBoxForApplication.isVisible = true;
        this.isMoveToNextTab = false;
      }
      else {
        this.isMoveToNextTab = true;
      }
    } else if (!this.shouldMoveToNextTab && this.currentTab === "Marketing Summary") {
      if (this.QuoteComponent && this.QuoteComponent.isPageDirty) {
        this._confirmBoxForApplication.message = this._userMessages.cancelButtonMessage;
        this._confirmBoxForApplication.isVisible = true;
        this.isMoveToNextTab = false;
      }
      else {
        this.isMoveToNextTab = true;
      }
    } else if (!this.shouldMoveToNextTab && this.currentTab === "EPIC Policy Details") {
      if (this.EpicpolicydDetailsComponent && this.EpicpolicydDetailsComponent.epicPolicyForm?.dirty) {
        this._confirmBoxForApplication.message = this._userMessages.cancelButtonMessage;
        this._confirmBoxForApplication.isVisible = true;
        this.isMoveToNextTab = false;
      }
      else {
        this.isMoveToNextTab = true;
      }
    } else if (!this.shouldMoveToNextTab && this.currentTab === "Binding") {
      if (this.BindingComponent && this.BindingComponent.bindingForm?.dirty) {
        this._confirmBoxForApplication.message = this._userMessages.cancelButtonMessage;
        this._confirmBoxForApplication.isVisible = true;
        this.isMoveToNextTab = false;
      }
      else {
        this.isMoveToNextTab = true;
      }
    }

  }

  showWarningBox() {
    if (!this.masterCoverageListByLineTierAndIndustry.length) { this.noMasterCov = true; }
    for (const param of this.masterCoverageListByLineTierAndIndustry) {
      if (param.coverageParameters.length == 0) {
        this.noMasterCov = true;
      }
      else {
        this.noMasterCov = false;
        break;
      }
    }

    if ((this.wbaView.outcome == 'Bound' || this.wbaView.outcome == 'Lost' || this.wbaView.wbaStatusDescription == 'Won' || this.wbaView.wbaStatusDescription == 'Lost') && this.isAssesmentPopUp == false) {
      this._warningBox.message = this._userMessages.wbaWithoutChecklistWarningMessage;
      this._warningBox.isVisible = true;
    }
    else if (this.noMasterCov) {
      this._warningBox.message = this._userMessages.noMasterCoverages;
      this._warningBox.isVisible = true;
    }
    else {
      this._warningBox.message = this._userMessages.wbaRequiredDetailsWarningMessage;
      this._warningBox.isVisible = true;
    }

  }

  showWarningBoxTabChange() {
    this._warningBoxTabChange.message = this._userMessages.wbaRequiredDetailsWarningTabChangeMessage;
    this._warningBoxTabChange.isVisible = true;
  }

  warningBoxCancel() {
    this._warningBox.isVisible = false;
    // unchecking the coverage and removing it from managed array
  }

  warningBoxTabChangeCancel() {
    this.activeTab = this.whTabsList[0].tabName;
    this._warningBoxTabChange.isVisible = false;
    this.isSubmitted = true;
  }

  getCoverageAssementInfo($event: any) {
    this.isAssesmentPopUp = $event;
  }

  whTabChange(tabName: string) {
    this.currentTab = tabName;
  }


  selectItem(item: any, type: any) {
    this.showError = false;
    this.pId = item?.id;
    if (type == 'CompanyName') {
      if (item.prospectInformation.ein) {
        this.whiteBoardForm.get('ein').setValue(item.prospectInformation.ein);
      }
      else {
        this.whiteBoardForm.get('ein').setValue('');
      }
      this.getProspectDetail(this.pId);
      this.changeWhiteboardName(type);
    } else if (type == 'businessStatusList') {
      this.whiteBoardForm.controls['businessStatusId'].setValue({
        id: item.id,
        value: item.userName
      })

    } else if (type == 'businssLine') {
      this.whiteBoardForm.get('businessLineId').setValue(item?.id);
    } else if (type == 'ownerName') {
      this.whiteBoardForm.controls['ownerName'].setValue({
        id: item.userId,
        value: item.userName
      });
      this.ownerDatalookupFilterData = item.userName;
      this.invalidowner = this.whiteBoardForm.get('ownerName').value;
      this.saveInsuredDetails(false);
    } else if (type == 'wbaPriorityList') {
      this.whiteBoardForm.get('wbaPriorityId').setValue(item.target.value);
      // this.SelectedWbaPriorityName = item.target.options[item.target.selectedIndex].text;
    } else if (type == 'leadGenerator') {
      this.whiteBoardForm.controls['leadGeneratorId'].setValue({
        id: item.id,
        value: item.name
      });
      this.LeadGeneratorLookupFilterData = item.name;

      this.invalidLeadGerator = this.whiteBoardForm.get('leadGeneratorId').value
      this.leadGeneratorNotBlankButInvalid = false;
    } else if (type == 'expiringCarrierId') {
      this.whiteBoardForm.controls['expiringCarrierId'].setValue({
        id: item.id,
        value: item.value
      });
      this.ExpiringCarrierLookupFilterData = item.value;
      this.invalidExpiringCarrier = false
    }

    else if (type == 'accountManagerUserId') {
      this.whiteBoardForm.controls['accountManagerUserId'].setValue({
        id: item.userId,
        value: item.userName
      });
      this.AccountManagerLookupFilterData = item.userName;
      this.invalidAccountManager = false;
      this.saveInsuredDetails(false);
    }
    else if (type == 'marketingManager') {
      this.whiteBoardForm.controls['marketingManager'].setValue({
        id: item.userId,
        value: item.userName
      });
      this.MarketingManagerLookupFilterData = item.userName;
      this.invalidMarketingManager = false;
      this.saveInsuredDetails(false);
    }
    else if (type == 'firstName') {
      if (item?.office1Extension) {
        this.office2Ext = parseInt(item?.office1Extension) ? parseInt(item?.office1Extension) : null;
        this.whiteBoardForm.get('contact.extension').setValue(this.office2Ext);

      }
      else {
        this.whiteBoardForm.get('contact.extension').setValue('');
      }
      this.whiteBoardForm.get('contactId').setValue(item?.id)
      this.whiteBoardForm.get('contact').patchValue({
        firstName: item?.firstName,
        lastName: item?.lastName,
        jobTitle: item?.jobTitle,
        phoneNumber: item?.office1PhoneNumber,
        emailAddress: item?.office1Email
      })
    }
    var data = this.whiteBoardForm.value
    this.wbaHeaderValue = {
      effectiveDate: data.effectiveDate,
      accountManager: data.accountManagerUserId.value,
      businessStatus: data?.businessStatus,
      progress: data.progressId,
      lineOfBusiness: this.selectedLineOfBusiness,
    }

  }
  getContactItem(event: any) {

    // let selectedValue = event.target.value;
    let selectedValue = event;
    let contcatId = parseInt(selectedValue)
    if (contcatId) {
      let contactValue: any;
      this.contactList.forEach((x: any) => {
        if (x.id == contcatId) {
          contactValue = x;
          return
        }
      })
      if (contactValue) {

        this.getContactChange(contactValue)
      }
    }
  }
  getContactChange(item: any) {

    // let item = event.target.value;
    if (item?.office1Extension) {
      this.office2Ext = parseInt(item?.office1Extension) ? parseInt(item?.office1Extension) : null;
      this.whiteBoardForm.get('contact.extension').setValue(this.office2Ext);

    }
    else {
      this.whiteBoardForm.get('contact.extension').setValue('');
    }

    this.whiteBoardForm.get('contactId').setValue(item?.id)
    this.whiteBoardForm.get('contact').patchValue({
      firstName: item?.firstName,
      lastName: item?.lastName,
      jobTitle: item?.jobTitle,
      phoneNumber: item?.office1PhoneNumber,
      emailAddress: item?.office1Email
    })
    this.saveInsuredDetails();

  }

  changeWhiteboardName(type: string) {
    if (type) {
      if (type == "companyName") {
        this.selectedCompanyName = this.f.companyName.value.prospectName;
      }
      else if (type == "businssLine") {
        var SelectedLinOfBusiness = this.businessLine.filter((x: any) => x.businessLineId == this.f.businessLineId.value.businessLineId);
        if (SelectedLinOfBusiness && SelectedLinOfBusiness.length > 0) {
          this.selectedLineOfBusiness = SelectedLinOfBusiness[0].businessLineName;
        }
      }
      else if (type == "effectiveDate") {
        this.selectedEffectiveDate = this.f.effectiveDate.value ? this._datepipe.transform(this.f.effectiveDate.value, 'MM/dd/YYYY') : null;
      }

      if (this.selectedCompanyName && this.selectedEffectiveDate && this.selectedLineOfBusiness) {
        this.selectedWhiteboardName = this.selectedCompanyName + " " + this.selectedLineOfBusiness + " - " + this.selectedEffectiveDate;
      }
    }
  }

  //to cancel the process and back to prospect list screen
  cancel(id: any) {
    this.wbaNoteId = id;
    this._confirmBox.message = this._userMessages.deleteWBANotes;
    this._confirmBox.isVisible = true;
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.deleteNote(this.wbaNoteId);
  }

  cancelTabChangePopup() {
    this.calledFromClone = false;
    this._confirmBoxForApplication.isVisible = false;
  }

  confirmBoxModalClose() {
    if (this.closedModalType == 'closeModal') {
      this._confirmBoxForWba.isVisible = false;
      this.isFormClosedfromCross = true
      this._router.navigateByUrl('/' + this._navigationPath.getWhiteboardsUrl());
    }
  }

  //to get business status list
  getBusinessStatus(wbaType: string, masterBusinessStatusId: number) {
    this._addWBAService.getBusinessStatusList().subscribe(resp => {
      if (wbaType == 'NB') {
        // Check if the masterBusinessStatusId is found in businessStatusNewBusinessList
        const foundInNewBusinessList = resp.businessStatusNewBusinessList.some(
          (status: any) => status.businessStatusId === masterBusinessStatusId
        );
        if (foundInNewBusinessList) {
          // return original resp
          this.businessStatus = resp.businessStatusNewBusinessList
        }
        if (!foundInNewBusinessList) {
          // Find the matching item from businessStatusList
          const matchingStatus = resp.businessStatusList.find(
            (fullStatus: any) => fullStatus.businessStatusId === masterBusinessStatusId
          );
          if (matchingStatus) {
            // Push the item into businessStatusNewBusinessList
            resp.businessStatusNewBusinessList.push(matchingStatus);
            this.businessStatus = resp.businessStatusNewBusinessList
          }
        }
      } else {
        //RN case return mater business status full resp
        this.businessStatus = resp.businessStatusList
      }
    })
  }

  //to get user list
  getUserList() {
    this._addWBAService.getUserList().subscribe(resp => {
      this.userList = resp.userList
    })
  }

  // To Add currency format
  addCurrencyFormat(element: any, control?: AbstractControl) {
    var formattedAmount;
    formattedAmount = element.target.value.replace(/,/g, '');
    formattedAmount = this._amountPipe.transform(formattedAmount, 'USD', '', '1.0-0');
    element.target.value = formattedAmount;
    control?.setValue(element.target.value);
  }

  // To remove amount
  removeCurrencyFormat(element: any, val?: any) {
    var formattedAmount;
    formattedAmount = this._removeAmountPipe.transform(element.target.value);
    element.target.value = formattedAmount;
  }

  //To remove white spaces
  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(element.target.value, '');
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }

  getProspectDetail(pId: any) {
    this.whiteBoardForm.get('isExisting')?.reset();
    if (pId) {
      this.errorList = [];
      this._addWBAService.getProspectById(pId).subscribe(resp => {
        if (resp && resp.prospectInfo) {
          this.prospectDetail = resp.prospectInfo;
          var prospect = resp.prospectInfo;
          if (prospect?.prospectInformation?.ein) {
            this.whiteBoardForm.get('ein').setValue(prospect.prospectInformation.ein);
          }
          this.prospectContact = prospect;
          var isExt = resp.prospectInfo;
          if (prospect.contact) {
            this.whiteBoardForm.get('contactId').setValue(prospect.contact.id);
            this.setFormValue(prospect)
          }
          else {
            this.setFormValue(prospect)
          }
        }
      });
    }
    else {
      this.errorList = [];
      this.errorList[0] = { type: '', value: 'Please select any one prospect to view details.' };
    }
  }

  getProspectDetailTemp(pId: any, enableLoader: boolean = true) {
    if (pId) {
      this._addWBAService.getProspectById(pId, enableLoader).subscribe(resp => {
        if (resp && resp.prospectInfo) {
          this.prospectDetail = resp.prospectInfo;
        }
      });
    }
  }
  setFormValue(resp: any) {
    if (resp.contact != null) {
      this.whiteBoardForm.get('isExisting').setValue('true');
      this.whiteBoardForm.get('isExisting').enable();
      this.checkaddressChoice(true)
      if (resp.contact.office1Extension) {
        this.offece1EXT = parseInt(resp.contact?.office1Extension) ? parseInt(resp.contact?.office1Extension) : null;
        this.whiteBoardForm.get('contact.extension').setValue(this.offece1EXT);
      }
      else {
        this.whiteBoardForm.get('contact.extension').setValue('');
      }
      this.whiteBoardForm.get('contact').patchValue({
        firstName: resp.contact.firstName,
        lastName: resp.contact.lastName,
        jobTitle: resp.contact.jobTitle,
        phoneNumber: resp.contact.office1PhoneNumber,
        emailAddress: resp.contact.office1Email
      });
      this.selectedContactId = resp.contact.id;
      this.wbalistcopy.firstName = resp.contact.firstName
      this.wbalistcopy.lastName = resp.contact.lastName
      this.wbalistcopy.jobTitle = resp.contact.jobTitle
      this.wbalistcopy.office1PhoneNumber = resp.contact.office1PhoneNumber
      this.wbalistcopy.office1Email = resp.contact.office1Email
    }
    else {
      this.editIcon = true;
      this.whiteBoardForm.get('isExisting')?.setValue('false');
      this.whiteBoardForm.get('isExisting').disable();
      this.checkaddressChoice(false)
      this.whiteBoardForm.get('contact').reset();
      this.whiteBoardForm.get('contact').patchValue({
        firstName: '',
        lastName: '',
        jobTitle: '',
        phoneNumber: '',
        extension: '',
        emailAddress: '',
        id: 0
      });
      this.whiteBoardForm.get('contact').patchValue({
        prospectId: this.pId
      });


    }
  }



  //to get result list
  getResultList() {
    this._addWBAService.getResultList().subscribe(resp => {
      this.epicStatus = resp;
    })
  }

  //to get progress list
  getprogressList() {
    this._addWBAService.getprogressList().subscribe(resp => {
      this.progress = resp
    })
  }

  //to get Prospect Size  list
  getProspectTierList() {
    this._addWBAService.getProspectTierList().subscribe(resp => {
      this.prospectTierList = resp.prospectTierList
    })
  }

  //to get Renewal Status hide if RenewalStatus="NB"
  getRenewalStatusList() {
    this._addWBAService.getRenewalStatusList().subscribe(resp => {
      this.renewalStatusList = resp
    })
  }

  //to get Prospect Industry list
  getProspectIndustryList() {
    this._addWBAService.getProspectIndustryList().subscribe(resp => {
      this.prospectIndustryList = resp.prospectIndustryList
    })
  }

  //to get WBA Priority list
  getWBAPriorityList() {
    this._addWBAService.getWBAPriorityList().subscribe(resp => {
      this.WBAPriority = resp
    })
  }

  //to get Business Line list
  getBusinessLineList() {
    this._addWBAService.getBusinessLineList().subscribe(resp => {
      this.businessLine = resp;
    })
  }
  lookUpDataFilterChanges(fieldName: any) {
    if (fieldName == 'LeadGenerator') {
      this.LeadGeneratorLookupFilterData = ""
    }
    if (fieldName == 'accountManager') {
      this.AccountManagerLookupFilterData = ""
    }
    if (fieldName == 'marketingManager') {
      this.MarketingManagerLookupFilterData = ""
    }
    if (fieldName == 'carrier') {
      this.ExpiringCarrierLookupFilterData = ""
    }
    if (fieldName == 'Owner') {
      this.ownerDatalookupFilterData = ""
    }
  }
  onExpiringCarrierClear() {
    this.whiteBoardForm.get('expiringCarrierId')?.setValidators(null);
    this.whiteBoardForm.get('expiringCarrierId')?.updateValueAndValidity();
  }

  onExpectedBoundRevuneChange() {
    this.istargetRevenueUpdated = true
  }

  checkExpectedBoundRevuneChange() {
    let checkItem = this.businessLine.find((x: any) => x.businessLineId == this.wbaView.masterBusinessLineId);
    var expValue: any = this.wbaView.targetPremium * checkItem?.businessLineValue;
    this.targetRevenue = this._amountPipe.transform(parseInt(expValue.toFixed()), 'USD', '', '1.0-0')
    let currentTargetRevenue = this.whiteBoardForm.get('targetRevenue').value;
    if (currentTargetRevenue != this.targetRevenue) {
      this.istargetRevenueUpdated = true;
    } else {
      this.istargetRevenueUpdated = false;
    }
  }

  getExpiringRevenueCalculation(item: any, expiringPremium: any, targetPremium: any) {
    this.checkPolicyTypeValidation();
    if (this.businessLine) {
      let checkItem = this.businessLine.find((x: any) => x.businessLineId == item);
      if (expiringPremium && item) {
        var expremium = this._removeAmountPipe.transform(expiringPremium);
        var expValue: any = expremium * checkItem.businessLineValue;
        this.expRevenue = this._amountPipe.transform(parseInt(expValue.toFixed()), 'USD', '', '1.0-0')
        this.whiteBoardForm.get('expiringRevenue').setValue(expValue > 0 ? this.expRevenue : 0)
      }
      if (targetPremium && item) {
        var tarPremium = this._removeAmountPipe.transform(targetPremium);
        var expValue: any = tarPremium * checkItem.businessLineValue;
        this.targetRevenue = this._amountPipe.transform(parseInt(expValue.toFixed()), 'USD', '', '1.0-0')

        if (!this.istargetRevenueUpdated)
          this.whiteBoardForm.get('targetRevenue').setValue(expValue > 0 ? this.targetRevenue : 0)
      }
    }
    if (expiringPremium == 0 || expiringPremium == "") {
      this.whiteBoardForm.get('expiringRevenue').setValue(0)
    }
    if (targetPremium == 0 || targetPremium == "") {
      this.whiteBoardForm.get('targetRevenue').setValue(0)
    }
  }
  closeError() {
    this.showError = false;
  }

  getExtValue(extValue: any) {
    var data = extValue.toString()
    if (data.length <= 5 && data.length > 1) {
      if (data.length == 1) {
        this.finalExpValue = '0000' + data
      } else if (data.length == 2) {
        this.finalExpValue = '000' + data
      } else if (data.length == 3) {
        this.finalExpValue = '00' + data
      } else if (data.length == 4) {
        this.finalExpValue = '0' + data
      } else {
        this.finalExpValue = data
      }
    } else {
      this.finalExpValue = ''
    }
    return this.finalExpValue
  }

  handleEventFromChild(label: string) {
    let currentTabIndex = this.tabs.findIndex((tab: any) => tab.label === label);

    if (currentTabIndex !== -1 && currentTabIndex < (this.tabs.length - 1)) {
      this.currentTab = this.tabs[currentTabIndex + 1].label
      //handling bg color on Save & Next button
      this.setBGColorOnSelectedTab();
    }
  }

  handlePolicyDescriptionValue(value: any) {
    this.policyDescriptionValue = value;
    if (!this.policyDescriptionValue || (this.policyDescriptionValue != this.policyDescriptionchangeValue))
      this.handleDetailsTabErrorIcon('Policy Detail & Loss Runs', true);
    else this.handleDetailsTabErrorIcon('Policy Detail & Loss Runs', false);
  }
  handlePolicyDescriptionchangeValue(value: any) {
    this.policyDescriptionchangeValue = value;
    if (!this.policyDescriptionValue || (this.policyDescriptionValue != this.policyDescriptionchangeValue))
      this.handleDetailsTabErrorIcon('Policy Detail & Loss Runs', true);
    else this.handleDetailsTabErrorIcon('Policy Detail & Loss Runs', false);
  }

  setCreatedAcordId($event: number) {
    this.acordId = $event;
    // this.getWhiteBoardDetailsFromApi(this.WBAid);
  }

  //updating progress dropdown from wba details tab child components
  setUpdatedProgressId($event: number) {
    this.progressIdUpdatedFromAccord = $event;
    this.whiteBoardForm.get('progressId').patchValue(this.progressIdUpdatedFromAccord);
  }

  assessmentResponse($event: string) {
    this.coverageAssessmentResponse = $event
  }

  getAssessmentPayload($event: any) {
    this.assessmentPayload = $event;
  }

  saveAndNextAssessment($event: any) {
    this.isAssesmentParamsHasMandatoryValues = $event;
    this.doNotCheckRequiredAssessment = true;
    if (!this.isAssesmentParamsHasMandatoryValues) {
      this.handleDetailsTabErrorIcon('Coverage Assessment', false);
      this.handleDetailsTabErrorIcon('Coverage Assessment ', false);
    }
  }

  handleDetailsTabErrorIcon(label: string, value: boolean) {
    this.tabs.forEach((x: any) => {
      if (x.label == label) x.showError = value;
    });
  }

  handleAcordIconStatus(event: any) {
    this.handleDetailsTabErrorIcon(event.label, event.value);
  }

  hanldeSendToSelectedFlag(event: any) {
    this.issubmissionRequest = event;
  }
  getnoMasterCovData(event: any) {
    this.noMasterCov = event;
    this.doNotCheckMasterCovData = true;
  }

  getCoverageCheckListPayload($event: any) {
    this.coverageCheckListPayload = $event;
  }

  runAuditWizard() {
    this.auditDone = !this.auditDone;
  }

  //To open 'Add Prospect' Modal
  openeFileModal() {
    const modalRef = this.modalService.open(EFileCabinateComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog1',
    });

    modalRef.componentInstance.url = this.url;
  }

  openNoteModal() {
    const modalRef = this.modalService.open(WbaNoteComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    modalRef.componentInstance.wbaId = this.WBAid;
    modalRef.componentInstance.passEntry.subscribe((resp: any) => {
      if (resp == 'Add WBA Note') {
        this.getWbaNoteList(this.WBAid);
      }
    })
    modalRef.closed.subscribe((resp: any) => {
    });
  }

  editNoteModal(data: any) {
    const modalRef = this.modalService.open(WbaEditNoteComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    modalRef.componentInstance.wbaNoteData = data;
    modalRef.componentInstance.passEntry.subscribe((resp: any) => {
      if (resp == 'Edit WBA Note') {
        this.getWbaNoteList(this.WBAid);
      }
    })
    modalRef.closed.subscribe((resp: any) => {
    });
  }

  deleteNote(id: any) {
    if (id) {
      this._addWBAService.deleteWbaNotes(id).subscribe((resp: any) => {
        this.getWbaNoteList(this.WBAid)
      })
    }
  }

  readMore(ix: any) {
    this.notesList.forEach((x: any, index: any) => {
      this.notesList[index].isSelected = index == ix ? !this.notesList[index].isSelected : false;
    })
  }

  getWbaNoteList(id: any) {
    if (id) {
      this._addWBAService.listWbaNotes(id).subscribe((resp: any) => {
        this.notesList = resp.wbaNoteList
        if (this.notesList) {
          this.notesList.forEach((element: any) => {
            if (element.recordDate) {
              var dt = element.recordDate;
              var newDate = new Date(dt);
              var utcDate = this.utcToLocal(newDate);
              this.NotesUtcTime = utcDate
            }
            element.recordDate = this.NotesUtcTime
            element.isSelected = false;
          })
        }
        this._dataCacheService.compressAndCacheData('wba_tab_notesList', this.notesList);
        this._dataCacheService.emitTabNotification('wba_tab_notesList');
      })
    }
  }

  onChange(event: any) {
    // I want to do something here with the new selectedDevice, but what I
    // get here is always the last selection, not the one I just selected.
  }

  //to open 'Add Dial' Modal
  openAddDialModal(item: any) {
    let dialActive = sessionStorage.getItem('dialActive')!;
    this.isCallOnGoing = dialActive;
    if (dialActive == 'callOnGoing') {
      this._confirmBox.isVisible = true;
      this._confirmBox.message = this._userMessages.callProgress;
      this._confirmBox.header = 'Information'
      return
    }
    this._confirmBox.isVisible = false;
    var rcTokenAccessTokenExp = 0
    var rcTokenRefreshTokenExp = 0
    if (item.toString().length == 10) {
      if ((localStorage.getItem('RC_token') != 'null' && localStorage.getItem('RC_token') != '')) {
        var rDate = localStorage.getItem('RC_tokenExp') || "";
        var tempDate = rDate.split('T')
        var dt = tempDate[0] + ' ' + tempDate[1];
        var newDate = new Date(dt);
        var rcTokenCreateTime = this.utcToLocal(newDate);
        var rcTokenTimeStamp = rcTokenCreateTime.getTime();
        rcTokenAccessTokenExp = rcTokenTimeStamp + (3600 * 1000);
        rcTokenRefreshTokenExp = rcTokenTimeStamp + (604800 * 1000);
      }

      if ((localStorage.getItem('RC_token') != 'null' && localStorage.getItem('RC_token') != '') &&
        (rcTokenAccessTokenExp > new Date().getTime() && rcTokenRefreshTokenExp > new Date().getTime())) {
        this.dialScreen(item)
      }
      else if ((localStorage.getItem('RC_token') != 'null' && localStorage.getItem('RC_token') != '') &&
        (rcTokenAccessTokenExp < new Date().getTime() && rcTokenRefreshTokenExp > new Date().getTime())) {
        // Refresh your token and save into local storage and database. Use new token from the local storage.
        this.refreshRcTokenByUserId(item)
      }
      else if ((localStorage.getItem('RC_token') != 'null' && localStorage.getItem('RC_token') != '') &&
        (rcTokenAccessTokenExp < new Date().getTime() && rcTokenRefreshTokenExp < new Date().getTime())) {
        this.rcLogin(item)
      }
      else {
        this.rcLogin(item)
      }

    }
  }

  dialScreen(item: any) {
    const modalRef = this.modalService.open(DialsComponent, {
      keyboard: false,
      backdropClass: "green-backdrop",
      modalDialogClass: 'customeDialog-d',
    });
    var data = {
      number: item,
      prospectId: this.wbaView.prospectId,
      contactId: this.wbaView.contactId,
      listId: parseInt(this.wbaListId) > 0 ? parseInt(this.wbaListId) : null
    };
    modalRef.componentInstance.dialNumber = data;
    modalRef.closed.subscribe((resp) => {
      this.isDialActive = false;
      this.getNoOfDialCount(this.userId)
    });
  }
  getNoOfDialCount(userId: any) {
    if (userId) {
      let currentDate = new Date();
      let request = {
        userId: userId,
        currentDate: this._datepipe.transform(currentDate, 'yyyy-MM-dd')
      }
      this._dialservices.dialCount(request).subscribe((resp: any) => {
        sessionStorage.setItem('dialCount', resp?.dialCountList?.dialsCount[0]?.noOfDials)
      })
    }
  }

  rcLogin(item: any) {
    sessionStorage.setItem('modalStatus', '')
    localStorage.setItem('RC_tokenExp', '')
    const modalRef = this.modalService.open(RingCentralLoginComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'ringDialog1',
    });
    const timer = setInterval(() => {
      if (sessionStorage.getItem('modalStatus') == 'close') {
        modalRef.close()
        clearInterval(timer)
        this.dialScreen(item)
      } else if (sessionStorage.getItem('modalStatus') == 'open') {
        modalRef.close()
        clearInterval(timer)
      }
    }, 500)
  }

  public show(): void {

    this.notificationService.show({
      content: 'Saved',
      position: { horizontal: "center", vertical: "top" },
      animation: { type: "fade", duration: 800 },
      type: { style: "success", icon: true },
      hideAfter: this.hideAfter,
    });
    setTimeout(() => { this.el.nativeElement.classList.value = 'eMsgDisplay eMsdD'; }, 1000)
    setTimeout(() => { this.el.nativeElement.classList.value = 'eMsgDisplay'; }, 7000)
  }
  //saving insured details tab only
  saveCoverageParameterIdAndValue() {
    var aa = this.CoverageCheckList.Coverages
    if (this.whiteBoardForm && this.whiteBoardForm.valid) {
      let data = this.whiteBoardForm.value;
      let saveData = {
        "parameterId": data.companyName.id,
        "parameterValue": data.businessStatusId,
      }


      this._addWBAService.updateWBActivity(saveData).subscribe(resp => {
        if (resp) {
          this.show();
          this.isExecutiveSummaryClicked = false;
          this.getWhiteBoardDetailsFromApi(saveData.parameterId);

        }
      },
        (err: any) => {
          this.showError = true;
          this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
          this.errorList = []
          this.errorList.push(this.errorMessage);
        });
    }
  }
  //to open 'Run Audit Wizard' model
  openRunAuditWizard() {
    const modalRef = this.modalService.open(AuditReportComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'auditcontent',
    });
    modalRef.componentInstance.CoverageAssessmentData = this.coverageAssessmentResponse;
    modalRef.componentInstance.auditRun = this.isRunAudit
    modalRef.componentInstance.passEntry.subscribe((res: any) => {
      this.assessmentPayload = res;
    });
    this.assessmentPayload = modalRef
    modalRef.closed.subscribe(res => {
      if (res) {
        this.handleEventFromChild('Coverage Assessment')
        this.isRunAudit = true
        this.wbaView.auditRun = true
        this.isAssesmentParamsHasMandatoryValues = false;
        this.doNotCheckRequiredAssessment = true;
      }
    })

  }
  //To hide column filters wbaEmaiList
  // columnFilter(value: boolean) {
  //   this.isFilter = !value;
  // }
  //  filters wbaEmaiList
  public filterwbaEmailList(filter: CompositeFilterDescriptor): void {
    this.skip = 0;
    // filter.filters.forEach((x: any) => {
    //   if (x.field == 'emailDate') {
    //     x.value = this._datepipe.transform(x.value, 'yyyy-MM-dd')
    //   }
    // })
    this.gridFilter = { ...filter };
    let gridfilterValueCopy = JSON.parse(JSON.stringify(filter));
    if (gridfilterValueCopy) this.convertDateStringInFormatting(gridfilterValueCopy);

    if (filter.filters.length !== 0) {
      this.filterEmailFlag = true;
      // this.gridFilter = filter;
      this.gridData = this.wbaEmailList;
      this.gridData = filterBy(this.gridData, gridfilterValueCopy);
      this.wbaEmailListFilterData = this.gridData
      this.pageChangeEmailList();
    }
    else {
      this.wbaEmailListFilterData = this.wbaEmailList
      this.pageChangeEmailList();
    }
  }

  convertDateStringInFormatting(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertDateStringInFormatting(x)
        }
        else {
          if (x.field == 'emailDate') {
            x.value = this._datepipe.transform(x.value, 'yyyy-MM-dd');
          }
        }
      })
    }
    return json;
  }
  // sorting wbaEmaiList
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.sortChangeFlag = true;
    if (this.sort[0].field == "emailDate" && this.sort.length == 1)
      this.sort.push({ dir: this.sort[0].dir, field: 'emailTime' })
    this.gridData = orderBy(this.wbaEmailListFilterData, this.sort)
    this.wbaEmailListFilterData = this.gridData
    this.sort.splice(1, 1)
    this.pageChangeEmailList()
  }
  // pagination wbaEmailList
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.wbaEmailListSize = event.take;
    this.pageChangeEmailFlag = true;
    this.pageChangeEmailList();

  }
  pageChangeEmailList() {
    this.skip = this.skip;
    this.wbaEmailListSize = this.wbaEmailListSize;
    if (this.sortChangeFlag || this.filterEmailFlag || this.pageChangeEmailFlag) {
      this.sortChangeFlag = false;
      this.filterEmailFlag = false;
      this.pageChangeEmailFlag = false;
      this.gridData = {
        data: this.wbaEmailListFilterData,
        total: this.wbaEmailListFilterData.length,
      };
    }
    else {
      this.gridData = {
        data: this.wbaEmailList,
        total: this.wbaEmailList.length,
      };
    }

    if (this.searchGrid != "" && this.searchGrid != undefined) {
      let _array = this.search(this.gridData.data, this.searchGrid)

      this.gridData = {
        data: _array.slice(this.skip, this.skip + this.wbaEmailListSize),
        total: _array.length,
      };
    }
    else {
      this.gridData = {
        data: this.gridData.data.slice(this.skip, this.skip + this.wbaEmailListSize),
        total: this.gridData.data.length,
      };
    }
  }

  searchValue(inputValue: any) {
    this.searchGrid = inputValue;
  }

  //searching on all columns
  public onFilter(): void {
    this.skip = 0;

    if ((this.searchGrid == "" || this.searchGrid == undefined)) {
      this.gridData = {
        data: this.gridMasterData.data,
        total: this.gridMasterData.length
      };
    }

    else {
      this.FilterBlankcheck = true;
      let _array = this.search(this.gridMasterData.data, this.searchGrid)

      this.gridData = {
        data: _array,
        total: _array.length,
      };
    }

    if (this.gridFilter.filters.length !== 0) {
      this.gridFilter.filters.forEach((x: any) => {
        if (x.field == 'emailDate') {
          x.value = this._datepipe.transform(x.value, 'yyyy-MM-dd')
        }
      })

      let filterList = filterBy(this.gridData.data, this.gridFilter);
      this.wbaEmailListFilterData = filterList;
      this.gridData = {
        data: filterList.slice(this.skip, this.skip + this.wbaEmailListSize),
        total: filterList.length,
      };

    }
    else {
      this.gridData = {
        data: this.gridData.data.slice(this.skip, this.skip + this.wbaEmailListSize),
        total: this.gridData.data.length,
      };
    }

  }

  search(array: any, value: any) {
    let _array: any = [];
    value = value.toLowerCase();
    _array = array.filter((val: any) =>
      (val.from.toLowerCase().includes(value) || val.to.toLowerCase().includes(value) || val.subject.toLowerCase().includes(value))

    );
    return _array
  }

  getTabDetails() {
    this.getWBAEmailList(this.WBAid);
    this.getWbaNoteList(this.WBAid);
    this.getWbaSubmissionCarrierListById(this.WBAid);
    this.getMarketingSummaryList(this.WBAid);
    this.GetMasterCoverageList();
    //this.getPolicyDetails(this.WBAid);
  }

  // getPolicyDetails(id: any) {
  //   if (id) {
  //     this._addWBAService.getPolicyDetails(this.WBAid).subscribe((resp: any) => {
  //       this.policydetailswba = resp;
  //       this._dataCacheService.compressAndCacheData('wba_policy_DetailsInfo', this.policydetailswba);
  //       this._dataCacheService.emitTabNotification('wba_policy_DetailsInfo');
  //     })
  //   }
  // }

  GetMasterCoverageList() {
    this._addWBAService.getMasterCovList().subscribe((res: any) => {
      this._dataCacheService.compressAndCacheData('wba_Mrktsmry_masterCovList', res);
    })
  }

  //to get wba carriers list
  getWbaSubmissionCarrierListById(wbaId: any) {
    this._addWBAService.getWbaSubmissionCarrierListById(wbaId).subscribe(resp => {
      this._dataCacheService.compressAndCacheData('wba_Mrktsmry_carrierList', resp.wbaSubmissionCarrierList);
    })
  }

  getMarketingSummaryList(wbaId: any) {
    if (wbaId) {
      this._addWBAService.getMarketingSummaryList(wbaId).subscribe((res: any) => {
        if (res) {
          this._dataCacheService.compressAndCacheData('wba_Mrktsmry_marketingSummary', res.marketingSummary);
        }
      })
    }
  }

  // email list api
  getWBAEmailList(id: any) {
    this._addWBAService.getWBAEmailListByEntity('wba', id).subscribe(
      (resp: any) => {
        if (resp) {
          this._loaderService.hide();
          this.wbaEmailList = resp.caffeineEmailList;
          this.gridFilter = { logic: 'and', filters: [], };
          console.log('wba_tab_emailList', resp.caffeineEmailList);
          if (this.wbaEmailList) {
            this.wbaEmailList.forEach((element: any) => {
              if (element.dateTimeCreated) {
                var rDate = element.dateTimeCreated.split('T');
                var rtime = rDate[1].split('Z')
                var dt = rDate[0] + ' ' + rtime[0];
                var newDate = new Date(dt);
                //var newDate = new Date(element.dateTimeCreated);
                var utcDate = this.utcToLocal(newDate);
                element.emailDate = this._datepipe.transform(utcDate, 'yyyy-MM-dd')
                element.emailTime = this._datepipe.transform(utcDate, 'h:mm a')

              }
              if (element.to) {
                element.toArray = element.to.split(',');
              }

            })
          }
          this.gridData = {
            data: this.wbaEmailList,
            total: this.wbaEmailList.length,
          };

          this.gridMasterData = {
            data: this.wbaEmailList,
            total: this.wbaEmailList.length,
          };

          this.wbaEmailListFilterData = this.wbaEmailList;
          this._dataCacheService.compressAndCacheData('wba_tab_emailList', this.wbaEmailList);
          this._dataCacheService.emitTabNotification('wba_tab_emailList');
          this.pageChangeEmailList()
          this.sort = [{ field: "emailDate", dir: "desc" }, { field: 'emailTime', dir: "desc" }];
          this.sortChange(this.sort);
          console.log('wba_tab_emailList11', this.wbaEmailList);
          console.log('wba_tab_emailList12', this.gridData);
        }
      },
      (error: any) => {
        this._loaderService.hide();
      }
    );
  }

  // modal for email
  getWBAEmailListClick() {
    //if (environment.production === true) {
    this.getWBAEmailOutlook();   //for Email Outlook Refresh
    // } else {
    //   this.getWBAEmailList(this.WBAid);   //for Wba Email Tab Refresh
    // }
  }

  //for Email outlook
  getWBAEmailOutlook() {
    this._addWBAService.getWBAEmailOutlookRefresh().subscribe(
      (resp: any) => {
        if (resp) {
          this.getWBAEmailList(this.WBAid);   //for Wba Email Tab Refresh
          this._loaderService.hide();
        }
      },
      (error: any) => {
        this._loaderService.hide();
      }
    );
  }

  openSendEmailModal() {
    const modalRef = this.modalService.open(AcmeEmailSendComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    modalRef.componentInstance.WBAid = this.WBAid;
    modalRef.closed.subscribe(resp => {
      this._loaderService.hide();
    })
  }

  openEmailModal(item: any) {
    if (environment.production) {
      this.getCaffeineEmailDetail(item);
    }
    else {
      const modalRef = this.modalService.open(CaffeineEmailDetailComponent, {
        keyboard: false,
        backdrop: 'static',
        modalDialogClass: 'customeDialog',
      });
      modalRef.componentInstance.fromEmailList = item;
      modalRef.closed.subscribe(resp => {
        this._loaderService.hide();
      })
    }
  }

  getCaffeineEmailDetail(item: any) {
    this.uniqueId = encodeURIComponent(item.itemId);
    this._addWBAService.getCaffeineEmailDetail(this.uniqueId).subscribe(
      (resp: any) => {
        var data = JSON.parse(JSON.stringify(resp));
        if (data) {
          window.open(data.outlookURL, "_blank");
        }
      },
      (err: any) => {
        this.showError = true;
        if (err.status !== 200) {
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorList = [];
            this.errorMessage = arr[0].value[0];
            this.errorList.push(this.errorMessage);
          } else {

            this.showError = true;
            this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
            this.errorList.push(this.errorMessage);
          }
        }
      });
  };

  // BOR Modal
  openBORModal() {
    const modalRef = this.modalService.open(BorComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    modalRef.componentInstance.passEntry.subscribe((resp: any) => {
      if (resp == 'BOR') {
        this.isExecutiveSummaryClicked = false;
        this.getWhiteBoardDetailsFromApi(this.WBAid);
      }
    })
    modalRef.closed.subscribe(resp => {

    })
  }

  setIsAssesmentPopup($event: boolean) {
    this.isAssesmentPopUp = $event;
    if (this.isAssesmentPopUp) this.handleDetailsTabErrorIcon('Coverage Checklist', false);
  }

  openBorFromAcord(event: any) {
    if (event) this.openBORModal();
  }

  enableBor(event: any) {
    this.selectedBor = event;
  }

  CreatePolicyInEPIC($event: boolean) {
    this.policyInEPIC = $event
  }

  mapAccessReviewList(detailsTab: string) {
    let AcordResponse = this._dataCacheService.retrieveData('wba_AcordResponse_reviewList');
    if (AcordResponse?.acordResponse?.length) {
      this.acordProgressSubmitted = true;
    }
    let progressName = this.wbaView?.progressName;
    let outcome = this.wbaView?.outcome;
    if (progressName == 'Marketed to Carrier' || progressName == 'Quoted' ||
      outcome == 'Bind Request' || outcome == 'Bound' || outcome == 'Lost') {
      this.wbaAcordProgress = true;
    } else {
      this.wbaAcordProgress = false;
    }
    if (outcome == 'Bind Request' || outcome == 'Bound') {
      this.marketingSummaryProgress = true;
    } else {
      this.marketingSummaryProgress = false;
    }
    this._notifierService._marketingSummaryProgress.next(this.marketingSummaryProgress)
    if (AcordResponse || this.wbaAcordProgress) {
      this.wbaReviewStatusArray = AcordResponse.wbaReviews?.find((e: { wbaReviewStatus: string; }) => e?.wbaReviewStatus == 'Complete');
      this._notifierService._wbaReviewStatus.next(this.wbaReviewStatusArray?.wbaReviewStatus)
      if (this.wbaView.transType == 'NB' &&
        ((!this.wbaReviewStatusArray || this.wbaReviewStatusArray?.wbaReviewStatus !== 'Complete') && !this.wbaAcordProgress)) {
        if (detailsTab === 'Submissions') {
          this.showSubmissionWarningBox();
        }
      }
      else {
        this.currentTab = detailsTab;
        if (this.submissionTab?.label == 'Submissions' && this.detailsTabChanges) {
          this.tabs.forEach((element: any) => {
            element.isActive = false;
          });
          this.submissionTab.isActive = true;
        }
      }
    }
    else {
      if (detailsTab === 'Submissions')
        this.showSubmissionWarningBox();
    }
    if (AcordResponse?.acordResponse?.length) {
      let rsmArray = AcordResponse.acordResponse?.find((a: any) => a?.requestType == "SD Approval");
      let marketingReviewArray = AcordResponse.acordResponse?.find((e: any) => e?.requestType == 'Marketing Review');
      let wbaReviewStatusArray = AcordResponse.wbaReviews?.find((e: { wbaReviewStatus: string; }) => e?.wbaReviewStatus == 'Complete')
      if (rsmArray?.requestStatus == 'Pending' || rsmArray?.requestStatus == "Approved") {
        this.handleDetailsTabErrorIcon('CE Status', false);
      }
      if (wbaReviewStatusArray) {
        this.handleDetailsTabErrorIcon('Marketing Review', false);
      }
      if (rsmArray?.requestStatus) {
        if ((rsmArray?.requestStatus == "Not Needed" && rsmArray?.requestStatus == "Approved")
          && AcordResponse.wbaReviews[0]?.wbaReviewStatus != null) this.handleDetailsTabErrorIcon('SD Approval', false);
        else if (rsmArray?.requestStatus != "Not Needed" && rsmArray?.requestStatus == "Approved")
          this.handleDetailsTabErrorIcon('SD Approval', false);
        else this.handleDetailsTabErrorIcon('SD Approval', true);
      }
    }
    // handling RN case validation Error Icon on left tab
    if (this.wbaView?.transType == 'RN') {

      let mrktStsRsp = this._dataCacheService.retrieveData('wba_AcordResponse_reviewList');
      if (mrktStsRsp?.reMarketingStatus == "Remarketing by AM" || mrktStsRsp?.reMarketingStatus == "Remarket by Marketing team") {
        this.handleDetailsTabErrorIcon('AE Status', false);
      } else {
        this.handleDetailsTabErrorIcon('AE Status', true);
      }

      if (AcordResponse?.acordResponse?.length) {
        let marketingReviewArray = AcordResponse?.acordResponse?.find((e: any) => e?.requestType == 'Marketing Review');
        if (marketingReviewArray?.requestStatus == "Completed") {
          this.handleDetailsTabErrorIcon('Marketing Review', false);
        }

        // if (AcordResponse?.wbaReviews[0]?.wbaReviewStatus != null) {
        //   this.handleDetailsTabErrorIcon('Account Manager', false);
        // }
      }
    }
  }

  getAccessReviewList(detailsTab: string) {
    let requestBody = {
      wbaId: this.WBAid,
      isRenewal: this.wbaView?.transType == 'NB' ? false : true
    };
    this._addWBAService.getAccessReviewList(requestBody).subscribe(res => {
      let AcordResponse = res;
      this._dataCacheService.compressAndCacheData('wba_wbaResponse', res?.wbaReviews);
      this._dataCacheService.compressAndCacheData('wba_AcordResponse_reviewList', res);
      if (res.acordResponse?.length) {
        this.acordProgressSubmitted = true;
      }
      let progressName = this.wbaView?.progressName;
      let outcome = this.wbaView?.outcome;
      if (progressName == 'Marketed to Carrier' || progressName == 'Quoted' ||
        outcome == 'Bind Request' || outcome == 'Bound' || outcome == 'Lost') {
        this.wbaAcordProgress = true;
      } else {
        this.wbaAcordProgress = false;
      }
      if (outcome == 'Bind Request' || outcome == 'Bound') {
        this.marketingSummaryProgress = true;
      } else {
        this.marketingSummaryProgress = false;
      }
      this._notifierService._marketingSummaryProgress.next(this.marketingSummaryProgress)
      if (AcordResponse || this.wbaAcordProgress) {
        this.wbaReviewStatusArray = AcordResponse.wbaReviews?.find((e: { wbaReviewStatus: string; }) => e?.wbaReviewStatus == 'Complete');
        this._notifierService._wbaReviewStatus.next(this.wbaReviewStatusArray?.wbaReviewStatus)
        if ((!this.wbaReviewStatusArray || this.wbaReviewStatusArray?.wbaReviewStatus !== 'Complete') && !this.wbaAcordProgress) {
          if (detailsTab === 'Submissions') {
            this.showSubmissionWarningBox();
          }
        }
        else {
          this.currentTab = detailsTab;
          if (this.submissionTab?.label == 'Submissions' && this.detailsTabChanges) {
            this.tabs.forEach((element: any) => {
              element.isActive = false;
            });
            this.submissionTab.isActive = true;
          }
        }
      }
      else {
        if (detailsTab === 'Submissions')
          this.showSubmissionWarningBox();
      }
      if (res?.acordResponse?.length) {
        let rsmArray = AcordResponse?.acordResponse?.find((a: any) => a?.requestType == "SD Approval");
        let marketingReviewArray = AcordResponse?.acordResponse?.find((e: any) => e?.requestType == 'Marketing Review');
        let wbaReviewStatusArray = AcordResponse?.wbaReviews?.find((e: { wbaReviewStatus: string; }) => e?.wbaReviewStatus == 'Complete')

        if (rsmArray?.requestStatus == 'Pending' || rsmArray?.requestStatus == "Approved") {
          this.handleDetailsTabErrorIcon('CE Status', false);
        }
        if (wbaReviewStatusArray) {
          this.handleDetailsTabErrorIcon('Marketing Review', false);
        }
        if (rsmArray?.requestStatus) {
          if ((rsmArray?.requestStatus == "Not Needed" && rsmArray?.requestStatus == "Approved")
            && AcordResponse?.wbaReviews[0]?.wbaReviewStatus != null) this.handleDetailsTabErrorIcon('SD Approval', false);
          else if (rsmArray?.requestStatus != "Not Needed" && rsmArray?.requestStatus == "Approved")
            this.handleDetailsTabErrorIcon('SD Approval', false);
          else this.handleDetailsTabErrorIcon('SD Approval', true);
        }
      }
      // handling RN case validation Error Icon on left tab
      if (this.wbaView?.transType == 'RN') {
        let mrktStsRsp = this._dataCacheService.retrieveData('wba_AcordResponse_reviewList');
        if (mrktStsRsp?.reMarketingStatus == "Remarketing by AM" || mrktStsRsp?.reMarketingStatus == "Remarket by Marketing team") {
          this.handleDetailsTabErrorIcon('AE Status', false);
        } else {
          this.handleDetailsTabErrorIcon('AE Status', true);
        }
        if (res?.acordResponse?.length) {
          let marketingReviewArray = AcordResponse?.acordResponse?.find((e: any) => e?.requestType == 'Marketing Review');
          if (marketingReviewArray?.requestStatus == "Completed") {
            this.handleDetailsTabErrorIcon('Marketing Review', false);
          }

          // if (AcordResponse?.wbaReviews[0]?.wbaReviewStatus != null) {
          //   this.handleDetailsTabErrorIcon('Account Manager', false);
          // }
        }
      }
    });
  }

  showSubmissionWarningBox() {
    this._warningBox.message = this._userMessages.wbaSumissionRequiredDetailsWarningMessage;
    this._warningBox.isVisible = true;
  }

  //to get policy Type list for speciality
  getPolicyTypeList() {
    this._addWBAService.getPolicyTypeList(5).subscribe(resp => {
      this.policyTypeList = resp.businessLineList
      //getting policy name
      if (this.selectedPolicyTypeId > 0) {
        this.policyTypeName = '(' + this.policyTypeList.find((x: any) => x.businessLineId == this.selectedPolicyTypeId).businessLineName + ')'
      }
      else {
        this.policyTypeName = ''
      }
    })
  }

  checkPolicyTypeValidation() {
    if (this.f.businessLineId.value == 5) {
      // this.whiteBoardForm.get('policyTypeId').enable();
      // this.whiteBoardForm.get('policyTypeId')?.setValidators([Validators.required]);
      this.whiteBoardForm.get('policyTypeId').disable();
      this.whiteBoardForm.get('policyTypeId')?.setValidators(null);
    }
    else {
      this.whiteBoardForm.get('policyTypeId').disable();
      this.whiteBoardForm.get('policyTypeId')?.setValidators(null);
      this.whiteBoardForm.get('policyTypeId').setValue("");
    }
    this.whiteBoardForm.get('policyTypeId')?.updateValueAndValidity({ emitEvent: false });
  }

  openOutcomeForms(formaname: any) {

    if (formaname == 'BOR') {
      this.openBORModal()
    } else if (formaname == 'Bound') {
      this.openAddBoundDetailofWba();
    } else if (formaname == 'Bind Request') {
      this.openAddBindRequest();
    } else if (formaname == 'Lost')
      this.openAddLostDetailofWba();
  }

  //To open 'Bound' Modal
  openAddLostDetailofWba() {
    const addmodalRef = this.modalService.open(LostDetailComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    addmodalRef.componentInstance.passEntry.subscribe((resp: any) => {
      if (resp == 'Add WbaLost') {
        this.isExecutiveSummaryClicked = false;
        this.getWhiteBoardDetailsFromApi(this.WBAid);
      }
    })
    addmodalRef.closed.subscribe((resp: any) => {


    });
    // this.WBAid = sessionStorage.getItem(environment.storageVariables.wbaId);
    sessionStorage.setItem('wbaforResult', JSON.stringify(this.whiteBoardForm.controls.epicStatusId.value))
  }

  //To open 'Bind Request' Modal
  openAddBindRequest() {
    const addmodalRef = this.modalService.open(WbaBindRequestComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    addmodalRef.componentInstance.progresStatus = this.progressStatusChange;
    addmodalRef.componentInstance.passEntry.subscribe((resp: any) => {
      if (resp == 'Add BindRequest') {
        this.isExecutiveSummaryClicked = false;
        this.getWhiteBoardDetailsFromApi(this.WBAid);
      }
    })
    addmodalRef.closed.subscribe((resp: any) => {


    });

  }

  openAddBoundDetailofWba() {
    const addmodalRef = this.modalService.open(WbaBoundComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    addmodalRef.componentInstance.progresStatus = this.progressStatusChange;
    addmodalRef.componentInstance.passEntry.subscribe((resp: any) => {
      if (resp == 'Bound') {
        this.isExecutiveSummaryClicked = false;
        this.getWhiteBoardDetailsFromApi(this.WBAid);
      }
    })
    addmodalRef.closed.subscribe((resp: any) => {


    });

    // this.WBAid = sessionStorage.getItem(environment.storageVariables.wbaId);
    sessionStorage.setItem('wbaforResult', JSON.parse(this.wbaView.expectedBoundRevenue));
  }

  showExecutiveSummaryWarningBox() {
    this._warningBox.message = this._userMessages.wbaExecutiveSummaryWarningMessage;
    this._warningBox.isVisible = true;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.action !== '') {
      this.setPolicyDetailsDirty();
    }
    if (!this.isFormClosedfromCross) {
      if (this.selectedData) {
        this.whiteBoardForm.markAsDirty();
      }
      if ((this.whiteBoardForm.dirty || this.isDirty == true) && sessionStorage.getItem('isFormDirty') != 'true') {
        this._confirmBoxForApplication.message = this._userMessages.cancelButtonMessage;
        this._confirmBoxForApplication.isVisible = true;
        this.isMoveToNextTab = true;
        return false
      }
      return true
    }
    return true
  }

  navigateToRoute() {
    if (this.calledFromClone) {
      this._confirmBoxForApplication.isVisible = false;
      this.openCloneBorModal(this.wbaView);
      return;
    }
    if (this.isMoveToNextTab) {
      sessionStorage.setItem("isFormDirty", 'true')
      this.isDirty = false;
      this._router.navigateByUrl('/' + sessionStorage.getItem('setUrlOnClick'));
      sessionStorage.removeItem('setUrlOnClick');
    }
    else {
      this._confirmBoxForApplication.isVisible = false;
      this.shouldMoveToNextTab = true;
      this.isMoveToNextTab = true;
      if (this.detailsTabChanges == true) {
        //finding tab which is clicked and isActive=true
        const clickedTabName = this.tabs.find(t => t.label == this.clickOnTabName);
        let item = {
          label: clickedTabName.label,
          labelImg: clickedTabName.labelImg,
          tb: clickedTabName.tb,
          isActive: false
        }

        this.detailsTabChange(item);
      } else {
        const clickedTabName = this.whTabsList.find(t => t.tabName == this.clickTabName);
        let item = {
          tabName: clickedTabName.tabName,
          tabIcon: clickedTabName.tabIcon,
          isActive: false
        }
        if (this.activeTab == 'Insured Details') {
          this.getWhiteBoardDetailsFromApi(this.WBAid)
        }
        this.tabChange(item);

      }
    }
  }

  setBGColorOnSelectedTab() {
    //making active false for all
    this.tabs.forEach((element: any) => {
      element.isActive = false;
    });
    this.whTabsList.forEach((element: any) => {
      element.isActive = false;
    });
    //finding next/clicked tab and setting isActive=true
    const clickedTabName = this.tabs.find(t => t.label == this.currentTab);
    clickedTabName.isActive = true;
    const clickedOnTabName = this.whTabsList.find(t => t.tabName == this.activeTab);
    clickedOnTabName.isActive = true;
  }


  setPolicyDetailsDirty() {
    if (this.PolicydetailComponent && (JSON.stringify(this.PolicydetailComponent.whiteBoardForm) !== JSON.stringify(this.PolicydetailComponent.originalWhiteBoardForm) || this.PolicydetailComponent?.lossDirty)) {
      this.isDirty = true;
    } else {
      this.isDirty = false;
      this.setAdditionalInformationDirty()
    }
  }

  setAdditionalInformationDirty() {
    if (this.AdditionalInformationComponent && this.AdditionalInformationComponent.isPageDirty) {
      this.isDirty = true;
    } else {
      this.isDirty = false;
      this.setChecklistDirty();
    }
  }

  setChecklistDirty() {
    if (this.CoverageChecklistComponent && this.CoverageChecklistComponent.isPageDirty) {
      this.isDirty = true;
    } else {
      this.isDirty = false;
      this.setAssessmentDirty();
    }
  }

  setAssessmentDirty() {
    if (this.CoverageAssessmentComponent && this.CoverageAssessmentComponent.isDirty) {
      this.isDirty = true;
    } else {
      this.isDirty = false;
      this.setAcordDirty();
    }
  }

  setAcordDirty() {
    if (this.AcordComponent && this.AcordComponent.isDirty == true) {
      this.isDirty = true;
    } else {
      this.isDirty = false;
      this.setAccountExecutiveDirty();
    }
  }

  setAccountExecutiveDirty() {
    if (this.AccountExecutive && this.AccountExecutive.isDirty == true) {
      this.isDirty = true;
    } else {
      this.isDirty = false;
      this.setMarketingReviewDirty();
    }
  }

  setMarketingReviewDirty() {
    if (this.MaketingComponent && this.MaketingComponent.isDirty == true) {
      this.isDirty = true;
    } else {
      this.isDirty = false;
      this.setRsmApprovalDirty();
    }
  }

  setRsmApprovalDirty() {
    if (this.sdRsmComponent && this.sdRsmComponent.isDirty == true) {
      this.isDirty = true;
    } else {
      this.isDirty = false;
      this.setAccountManagerDirty();
    }
  }

  setAccountManagerDirty() {
    if (this.AccountManager && this.AccountManager.isDirty == true) {
      this.isDirty = true;
    } else {
      this.isDirty = false;
      this.setMarketingSummaryDirty();
    }
  }

  setMarketingSummaryDirty() {
    if (this.QuoteComponent && this.QuoteComponent.isPageDirty) {
      this.isDirty = true;
    } else {
      this.isDirty = false;
      this.setProposalDirty();
    }
  }

  setProposalDirty() {
    if (this.ProposalComponent && this.ProposalComponent.proposalForm.dirty) {
      this.isDirty = true;
    } else {
      this.isDirty = false;
      this.setBindingDirty();
    }
  }

  setBindingDirty() {
    if (this.BindingComponent && this.BindingComponent.bindingForm?.dirty) {
      this.isDirty = true;
    } else {
      this.isDirty = false;
      this.setEpicPolicyDetailsDirty();
    }
  }

  setEpicPolicyDetailsDirty() {
    if (this.EpicpolicydDetailsComponent && this.EpicpolicydDetailsComponent.epicPolicyForm?.dirty) {
      this.isDirty = true;
    } else {
      this.isDirty = false;
    }
  }

  //Navigate to 'view prospect' screen
  navigate(id: any, prospectid: any) {
    sessionStorage.setItem('prospectId', id)
    let viewDetailsData = {
      prospectId: prospectid,
      listId: this.selectedListId,
      listTitle: this.selectedListId == 0 ? this.listTitle = 'All Prospects' : this.listTitle
    }
    sessionStorage.setItem('viewDetailsData', JSON.stringify(viewDetailsData));
    sessionStorage.setItem('prospectId', prospectid);
    this._router.navigateByUrl('/' + this._navigationPath.getProspectsUrl() + '/' + this._navigationPath.getProspectsViewUrl() + '?prospectId=' + prospectid + '&listId=' + this.selectedListId);
  }

  handlePriority() {
    this.whiteBoardForm.markAsDirty();
  }

  onProposalButtonClick() {
    let url = '/' + this._navigationPath.getWhiteboardsUrl() + '/' + this._navigationPath.getWhiteboardProposalDetails();
    this.router.navigate([url], { queryParams: { wbaId: this.WBAid, listId: this.wbaListId, prospectId: this.wbaView.prospectId } });
    sessionStorage.setItem('setUrlOnClick', url + '?wbaId=' + this.WBAid);
  }

  public utcToLocal(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  }

  refreshRcTokenByUserId(item: any) {
    let payload = {
      UserId: this.userId
    }
    this.userManagementService.refreshRCAuthToken(payload).subscribe((response: any) => {
      if (response) {
        localStorage.setItem('RC_token', response.ringCentralToken);
        localStorage.setItem('RC_tokenExp', JSON.stringify(response.rcTokenDateTime));
        this.dialScreen(item);
      }
    });
  }

  clickOnProspect() {
    sessionStorage.setItem('setUrlOnClick', '/' + this._navigationPath.getProspectsUrl() + '/' + this._navigationPath.getProspectsViewUrl() + '?prospectId=' + this.wbaView.prospectId + '&listId=' + this.wbaListId + '&initiated=' + 'whiteboard');
  }
  changeBorInDiscussion() {
    let data = this.whiteBoardForm.value;
    if (data.borInDiscussion) {
      this.progressIdAfterBORinDiscuss = this.progress.find((prog: any) => prog.progressName === "BOR in Discussion");
    }
    else {
      this.progressIdAfterBORinDiscuss = this.progress.find((prog: any) => prog.progressName === "Creating Submission");

    }
  }

  // getacordResponse() {
  //   let requestBody = {
  //     wbaId: this.WBAid
  //   };
  //   this._addWBAService.getAccessReviewList(requestBody).subscribe(res => {
  //     let acordResponse = res.acordResponse;
  //     acordResponse?.forEach((x: any) => {
  //       if (x.requestType == "SD Approval" && x.requestStatus == "Approved") {
  //         this.whiteBoardForm.get('profitCenter').disable();
  //       }
  //     })
  //   })
  // }

  getacordResponse(res: any) {
    // let requestBody = {
    //   wbaId: this.WBAid,
    //   isRenewal: this.wbaView?.transType == 'NB' ? false : true
    // };
    // this._addWBAService.getAccessReviewList(requestBody).subscribe(res => {
    let acordResponse = res.acordResponse;
    acordResponse?.forEach((x: any) => {
      if (x.requestType == "SD Approval" && x.requestStatus == "Approved") {
        this.whiteBoardForm.get('profitCenter').disable();
      }
    })
    // })
  }

  //to open 'Clone Wba' model
  openCloneBorModal(item: any) {
    if (this.whiteBoardForm.dirty && !this.calledFromClone) {
      this.calledFromClone = true;
      this._confirmBoxForApplication.message = this._userMessages.cancelButtonMessage;
      this._confirmBoxForApplication.isVisible = true;
      return;
    }
    this.calledFromClone = false;
    const modalRef = this.modalService.open(CloneBorComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    let data = item;
    modalRef.componentInstance.fromCloneData = data;
    modalRef.componentInstance.fromCloneDataListId = this.wbaListId;
    modalRef.componentInstance.messageEvent.subscribe((resp: any) => {
      if (resp) {
        this.getWhiteBoardDetailsFromApi(resp);
      }
    })
    modalRef.closed.subscribe((resp) => {

    });
  }
  //Clone wba
  CreateClone() {
    let saveData = {
      wbaId: this.WBAid
    }
    this._addWBAService.saveCloneWBA(saveData).subscribe((res: any) => {
      if (res) {
        if (res.message) {
          this._confirmBoxForClone.message = res.message;
          this._confirmBoxForClone.isVisible = true;
        } else {
          this._confirmBoxForClone.message = 'The clone is successfully created with WBA ID ' + " - " + "[" + res.wbaId + "]";
          this._confirmBoxForClone.isVisible = true;
        }
        //this._router.navigate(['/' + this._navigationPath.getWhiteboardsUrl() + '/' + this._navigationPath.getwhiteboardsApplicationUrl(),], { queryParams: { wbaId: res } });
      }
    },
      (error: any) => {
        if (error) {
        }
      })
  }



  toggle(i: any) {
    this.isExpand1 = !this.isExpand1;
    this.anchor = this.isExpand1 ? 'Show less' : 'Read more';
  }

  //view proposal Modal
  openViewProposalModal() {
    const modalRef = this.modalService.open(ViewProposalComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    modalRef.componentInstance.passEntry.subscribe((resp: any) => {

    })
    modalRef.closed.subscribe(resp => {

    })
  }

  getAccordPermissionByWbaId() {
    let requestBody = {
      wbaId: this.WBAid
    };
    this._addWBAService.getAccordPermissionByWbaId(requestBody).subscribe((res: any) => {
      this.AcordPermissionResponse = res.acordPermissions;
      this._dataCacheService.compressAndCacheData('wba_AcordPermissionResponse', this.AcordPermissionResponse);//setting resp in cache

    });
  }

  setActiveTabOnNotificationEmail() {
    // getAllMastersDropDown
    let epicStatus = this._addWBAService.getResultList(); //to get state list
    let progress = this._addWBAService.getprogressList(); //to get progress status list
    let prospectIndustryList = this._addWBAService.getProspectIndustryList();
    let businessLine = this._addWBAService.getBusinessLineList(); // to get business line details
    let policyTypeList = this._addWBAService.getPolicyTypeList(5); // to get policy list details
    let stateList = this._AddprospectService.getProspectStates();

    // getAllMasterNotInWbaList
    let prospectTierList = this._addWBAService.getProspectTierList(); // to get prospect details
    let renewalStatusList = this._addWBAService.getRenewalStatusList(); // to get prospect details
    let outComeList = this._addWBAService.getOutcomeList(this.userRoleName); // to get outcome detail
    let masterCVlist = this._addWBAService.getMasterCovList();
    let WBAPriority = this._addWBAService.getWBAPriorityList();

    // getAllMasters
    let requestBody = {
      wbaId: this.WBAid
    }
    let wbaView = this._addWBAService.getWBAById(this.WBAid);
    let policydetails = this._addWBAService.getPolicyDetails(this.WBAid);
    let WbaSubmissionCarrierListById = this._addWBAService.getWbaSubmissionCarrierListById(this.WBAid);
    let marketingSummaryList = this._addWBAService.getMarketingSummaryList(this.WBAid);
    let WbaBindingList = this._addWBAService.getWbaBindingList(requestBody);
    let epicPolicydata = this._addWBAService.GetEpicPolicyDetails(this.WBAid);
    let getAccordPermissionByWbaId = this._addWBAService.getAccordPermissionByWbaId(requestBody);

    // GetAllMasterCoverageListByBusinessLineTierAndIndustryWbaId
    let requestBody2 = {
      wbaId: this.WBAid,
      isRenewal: true
    }
    let data = {
      "lookUpType": 'WBASubmisiionUser',
      "rowCount": 10
    }
    let datarsm = {
      acordReasonType: "rsm"
    }
    let mrktrequest = {
      acordReasonType: "marketing"
    }
    let PolyRnpayload = {
      policyId: this.wbaView?.epicUniquePolicyId,
      wbaId: this.WBAid
    }
    let dataAE = {
      acordReasonType: "AE"
    }
    let wbaAcarrierList = this._addWBAService.getWbaSubmissionCarrierListById(this.WBAid);
    let carrierList = this._addWBAService.getWbaCarrierListById(this.WBAid);
    let emailTemplateList = this._addWBAService.getWbaEmailTemplateByWbaId(this.WBAid);
    let wbalookup = this._addWBAService.getWhiteBoardActivityLookUp(data);
    let accessReviewList = this._addWBAService.getAccessReviewList(requestBody2);
    let AccordReasonList = this._addWBAService.getAccordReasonList(datarsm);
    let AccordReasonListMrkt = this._addWBAService.getAccordReasonList(mrktrequest);
    let wbaBorDetails = this._addWBAService.getWbaBorDetailList(requestBody2);
    let coverageAssessmentDetails = this._addWBAService.getCoverageAssessmentDetails(this.WBAid);
    let AccordReasonListAE = this._addWBAService.getAccordReasonList(dataAE);

    forkJoin([epicStatus, progress, prospectIndustryList,
      businessLine, policyTypeList, stateList, prospectTierList, renewalStatusList, outComeList,
      masterCVlist, WBAPriority, wbaView, policydetails, WbaSubmissionCarrierListById,
      marketingSummaryList, WbaBindingList, epicPolicydata, getAccordPermissionByWbaId,
      wbaAcarrierList, carrierList, emailTemplateList, wbalookup, accessReviewList,
      AccordReasonList, AccordReasonListMrkt, wbaBorDetails,
      coverageAssessmentDetails, AccordReasonListAE
    ]).subscribe((resp) => {
      if (resp) {
        localStorage.removeItem('NotificationEmail');

        // getAllMastersDropDown
        this.epicStatus = resp[0]?.epicStatusList;
        this.progress = resp[1].progressList;
        this.prospectIndustryList = resp[2].prospectIndustryList;
        this.businessLine = resp[3].businessLineList;
        this.policyTypeList = resp[4].businessLineList;
        this._dataCacheService.compressAndCacheData('wba_statesList', resp[5].statesList);
        this._dataCacheService.compressAndCacheData('wba_prospectIndustryList', this.prospectIndustryList);
        this._dataCacheService.compressAndCacheData('wba_businessLine', this.businessLine);
        this._dataCacheService.compressAndCacheData('wba_policyTypeList', this.policyTypeList);
        this._dataCacheService.compressAndCacheData('wba_progress', this.progress);
        this._dataCacheService.compressAndCacheData('wba_resultList', this.epicStatus);

        // getAllMasterNotInWbaList
        this.prospectTierList = resp[6].prospectTierList;
        this.renewalStatusList = resp[7];
        this.valuesForOutcome = resp[8].masteroutComeList;
        this._dataCacheService.compressAndCacheData('wba_Mrktsmry_masterCovList', resp[9]);
        this.WBAPriority = resp[10];
        this._dataCacheService.compressAndCacheData('wba_detail_WBAPriority', this.WBAPriority);
        this._dataCacheService.compressAndCacheData('wba_detail_renewalStatusList', this.renewalStatusList);
        this._dataCacheService.compressAndCacheData('wba_detail_prospectTierList', this.prospectTierList);
        this._dataCacheService.compressAndCacheData('wba_detail_valuesForOutcome', this.valuesForOutcome);
        this.getoutcomeList();

        // getAllMasters
        this.wbaView = resp[11].whiteBoardActivityInfo;
        this._dataCacheService.compressAndCacheData('wba_policy_DetailsInfo', resp[12]);
        this._dataCacheService.compressAndCacheData('wba_Mrktsmry_carrierList', resp[13].wbaSubmissionCarrierList);
        this._dataCacheService.compressAndCacheData('wba_Mrktsmry_marketingSummary', resp[14].marketingSummary);
        this._dataCacheService.compressAndCacheData('WbaSubmissionCarrierList', resp[14].wbaSubmissionCarrierList);
        this._dataCacheService.compressAndCacheData('wba_bindingResponse', resp[15].wbaOutcomeDetails);
        this._dataCacheService.compressAndCacheData('wba_epicPolicyDetails', resp[16].wbaEpicPolicyDetailList[0]);
        this.AcordPermissionResponse = resp[17].acordPermissions;
        this._dataCacheService.compressAndCacheData('wba_AcordPermissionResponse', this.AcordPermissionResponse);//setting resp in cache
        let tabRefresh = {
          refresh: true
        }
        this._dataCacheService.compressAndCacheData('detailstabRefresh', tabRefresh);
        if (this.CoverageAssessmentData?.coverageAssessmentDetail.length) {
          this.isAssesmentPopUp = true;
          this.handleDetailsTabErrorIcon('Coverage Checklist', false);
        }
        this.getWhiteBoardDetails(resp[11]);
        this.getWBAEmailList(this.WBAid);
        this.getWbaNoteList(this.WBAid);
        this.setDataInCache();
        this.getAccessReviewList(this.currentTab);
        this.getBusinessStatus(this.wbaView?.transType, this.wbaView?.masterBusinessStatusId);

        // GetAllMasterCoverageListByBusinessLineTierAndIndustryWbaId
        this._dataCacheService.compressAndCacheData('WbaSubmissionCarrierList', resp[18].wbaSubmissionCarrierList);
        this._dataCacheService.compressAndCacheData('wba_wbaCarrierList', resp[19].wbaCarrierList);
        this._dataCacheService.compressAndCacheData('wba_wbaEmailSubmission', resp[20].wbaSubmissionTemplateList[0]);
        this._dataCacheService.compressAndCacheData('wba_userListLookup', resp[21].userList);
        this._dataCacheService.compressAndCacheData('wba_AcordResponse_reviewList', resp[22]);
        this._dataCacheService.compressAndCacheData('wba_AcordResponse', resp[22].acordResponse);
        this._dataCacheService.compressAndCacheData('wba_wbaResponse', resp[22].wbaReviews);
        this._dataCacheService.compressAndCacheData('wba_reasonResponse', resp[23].acordReasonList);
        this._dataCacheService.compressAndCacheData('wba_marketingResponse', resp[24].acordReasonList);
        this._dataCacheService.compressAndCacheData('wba_borResponse', resp[25].wbaBorDetailList);
        this.CoverageAssessmentData = resp[26];
        this._dataCacheService.compressAndCacheData('reasonResponseAe', resp[27].acordReasonList);
        this._dataCacheService.compressAndCacheData('wba_CoverageAssessmentDetails', this.CoverageAssessmentData);
        this.GetMasterCoverageListByBusinessLineTierAndIndustry();
        let wbasubmissionList: any = resp[19].userSubmissionInfo;
        this._dataCacheService.compressAndCacheData('userSubmissionInfo', wbasubmissionList);

        let tabRefresh2 = this._dataCacheService.retrieveData('detailstabRefresh');
        tabRefresh2.refresh = false;
        this._dataCacheService.compressAndCacheData('detailstabRefresh', tabRefresh2);
        if (wbasubmissionList?.length == 0 || wbasubmissionList == undefined || wbasubmissionList == null) {
          this.handleDetailsTabErrorIcon('Submissions', true);
        }
        else {
          this.handleDetailsTabErrorIcon('Submissions', false);
        }
        if (this.CoverageAssessmentData?.coverageAssessmentDetail.length) {
          this.isAssesmentPopUp = true;
          this.handleDetailsTabErrorIcon('Coverage Checklist', false);
        }
        if (this.wbaView?.transType == 'NB') this.checkRatingBasisHasRequiredValues();
        if (this.wbaView?.transType == 'RN' && this.wbaView?.epicUniquePolicyId != 0) {
          this.getWbaPolicyRenewDetails();
        }
        this.handleDetailsTabErrorIcon('Policy Detail & Loss Runs', false);

        //routing to AE status tab from notification email
        let AcordResponse = this._dataCacheService.retrieveData('wba_AcordResponse_reviewList');
        if (!AcordResponse || AcordResponse == undefined || AcordResponse != null) this.mapAccessReviewList(this.currentTab);
        this.AcordPermissionResponse = this._dataCacheService.retrieveData('wba_AcordPermissionResponse');
        if (!this.AcordPermissionResponse.length) this.getAccordPermissionByWbaId();
        this._notifierService._accordPermissionNotifier.next(this.AcordPermissionResponse);//notifying data to AE status tab
      }
    },

      (err: any) => {
        this.errorList = []
        this.showError = true;
        this.isBtnDisabled = false;
        if (err.status == 403) {
          this.showError = true;
          this.errorList.push(this.unAthorizesd)
          return
        }
        if (err.status == 400) {
          this.errorMessage = "Error : " + err.error.errors.ProspectId[0];
          this.errorList.push(this.errorMessage);
        }
        if (err.status == 500) {
          this.errorMessage = "Error : " + err.message + ", Status: " + err.status;;
          this.errorList.push(this.errorMessage);
        }
      });
  }
}
