import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { environment } from 'src/environments/environment';
import { AddWBAScreen } from '../../../error-message/addprospect-screen';
import { WBANotifierService } from '../../../services/wba-notifier.service';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { ActivatedRoute } from '@angular/router';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';

@Component({
  selector: 'app-bor',
  templateUrl: './bor.component.html',
  styleUrls: ['./bor.component.css']
})

export class BorComponent implements OnInit {

  public defaultItem: { reason: string; id: any } = {
    reason: "Select Reason",
    id: null,
  }

  claimBusinessGroups = [
    { id: 1, groupType: 'Claims', name: 'Claims Review', businessGroup: '' },
    { id: 2, groupType: 'Claims', name: 'Large Claim Special Project', businessGroup: '' },
    { id: 3, groupType: 'Claims', name: 'Update/Status report on open claim', businessGroup: '' },
    { id: 4, groupType: 'Claims', name: 'Special Service account management', businessGroup: '' },
    { id: 5, groupType: 'Claims', name: 'Marketing - Specific to replace coverage execution strategy', businessGroup: '' }
  ]
  lossBusinessGroups = [
    { id: 1, groupType: 'Loss', name: 'Workers compensation', businessGroup: '' },
    { id: 2, groupType: 'Loss', name: 'Auto', businessGroup: '' },
    { id: 3, groupType: 'Loss', name: 'Other', businessGroup: '' }
  ]

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  addWbaScreen: AddWBAScreen;
  borForm: any;
  submitted: boolean = false;
  initiateBorResponse: any;
  selectInitiateBor: any;
  selectExecutionStrategy: any;
  selectRenewalPolicy: any;
  borStrategiesResponse: any;
  borRenewalResponse: any;
  wbaId: any;
  selectedInitiateBorResponse: any;
  selectedInitiateBorResponseId: any;
  selectedborStrategiesResponse: any;
  selectedborStrategiesResponseId: any;
  selectedborRenewalResponse: any;
  selectedborRenewalResponseId: any;
  claimReviewName: any;
  workerName: any;
  claimReviewId: any;
  workerId: any;
  autoId: any;
  autoName: any;
  otherId: any;
  otherName: any;
  largeClaimName: any;
  largeClaimId: any;
  updateClaimId: any;
  updateClaimName: any;
  specialClaimId: any;
  specialClaimName: any;
  marketingClaimId: any;
  marketingClaimName: any;
  borResponse: any;
  getInitiateBorResponse: any;
  getCommentsResponse: any;
  getStrategyBorResponse: any;
  getRenewalBorResponse: any;
  getBusinessGroups: any;
  getAllBusinessGroupsResponse: any;
  selectedItem: any;
  selectSecviceIds: any = [];
  isActive: boolean = false;
  wbaProgressChange: any;
  wbaDetails: any;

  constructor(
    public activeModal: NgbActiveModal,
    private formbuilder: UntypedFormBuilder,
    private wbaservice: WhiteBoardActivityService,
    private _notifierService: WBANotifierService,
    private activeRouter: ActivatedRoute,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    private _wbaService: WhiteBoardActivityService,
  ) {
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
    this.addWbaScreen = new AddWBAScreen();
  }

  ngOnInit(): void {
    this.activeRouter.queryParams
      .subscribe(params => {
        if (params)
          this.wbaId = params.wbaId
      });
    this.getFormControls();
    this.setFormValueforOutcome();
    this.getWbaBorReasonList();
    this.getStrategyAndRenewalList();
    this.getWbaBorDetailList();
    this.getWhiteBoardDetailsFromApi(this.wbaId);
  }

  // Form Controls
  getFormControls() {
    this.borForm = this.formbuilder.group({
      outcome: [''],
      initializeBOR: [this.defaultItem, [Validators.required]],
      executionStrategy: ['', [Validators.required]],
      renewalPlan: ['', [Validators.required]],
      comments: [''],
      claimBusinessGroups: [''],
      lossBusinessGroups: [''],
      specialServiceNotes: ['']
    });
    this.borForm.get('outcome').disable();
  }

  setFormValueforOutcome() {
    this.borForm.patchValue({
      outcome: 'BOR'
    })
  }

  setFormValue(result: any) {
    if (this.borResponse != null) {
      this.selectedInitiateBorResponseId = result.reasonId;
      this.selectedborStrategiesResponseId = result.strategyId;
      this.selectedborRenewalResponseId = result.renewalId;
      this.borForm.get('executionStrategy').setValue(this.getStrategyBorResponse);
      this.borForm.get('renewalPlan').setValue(this.getRenewalBorResponse);
      this.borForm.get('comments').patchValue(this.getCommentsResponse);
      this.borForm.get('specialServiceNotes').patchValue(result?.specialServiceNotes);
      this.selectedItem = this.selectedInitiateBorResponseId
    }
  }

  get f() {
    return this.borForm.controls;
  }

  //to cancel the process and back to Acme Product list screen
  cancel() {
    if (!this.borForm.dirty) {
      this.closeModal();
    } else {
      this._confirmBox.message = this._userMessages.cancelButtonMessage;
      this._confirmBox.isVisible = true;
    }
  }

  //confirm box cancel method
  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }
  closeModal() {
    this.activeModal.close();
  }

  // Change events
  setInitiateBor(item: any) {
    this.selectInitiateBor = item;
    this.selectedInitiateBorResponse = this.initiateBorResponse?.find((el: any) => el?.reason == this.selectInitiateBor);
    this.selectedInitiateBorResponseId = this.selectedInitiateBorResponse.id;
  }

  public selectionChange(item: any): void {
    this.selectInitiateBor = item;
    this.selectedInitiateBorResponse = this.initiateBorResponse?.find((el: any) => el?.reason == this.selectInitiateBor.reason);
    this.selectedInitiateBorResponseId = this.selectedInitiateBorResponse.id;
  }

  setExecutionStrategy(item: any) {
    this.selectExecutionStrategy = item;
    this.selectedborStrategiesResponse = this.borStrategiesResponse?.find((el: any) => el?.strategy == this.selectExecutionStrategy);
    this.selectedborStrategiesResponseId = this.selectedborStrategiesResponse.id;
  }

  setRenewalPolicy(item: any) {
    this.selectRenewalPolicy = item;
    this.selectedborRenewalResponse = this.borRenewalResponse?.find((el: any) => el?.renewal == this.selectRenewalPolicy);
    this.selectedborRenewalResponseId = this.selectedborRenewalResponse.id;
  }

  setClaims(claimChekbox: HTMLInputElement, item: any) {
    item.businessGroup = claimChekbox.checked ? item.name : '';
    if (claimChekbox.checked == true) {
      this.selectSecviceIds.push(item.id);
      this.selectSecviceIds.forEach((x: any) => {
        if (x == 4) {
          this.isActive = true;
        }
      })
    }
    if (claimChekbox.checked == false) {
      if (this.claimBusinessGroups.length > 0) {
        for (var i = 0; i < this.claimBusinessGroups.length; i++) {
          if (this.selectSecviceIds[i] == item.id) {
            this.selectSecviceIds.splice(i, 1);
            if (item.id == 4) {
              this.isActive = false;
            }
          }
        }
      }
      if (item.name == 'Special Service account management') {
        this.isActive = false;
      }
    }

  }

  setLoss(lossChekbox: HTMLInputElement, item: any) {
    item.businessGroup = lossChekbox.checked ? item.name : '';
  }

  getWhiteBoardDetails(wbaId: any) {
    this.wbaservice.getWBAById(wbaId).subscribe(resp => {
      this.wbaProgressChange = resp.whiteBoardActivityInfo.progressName;
      this._notifierService._isProgressStatusChange.next(this.wbaProgressChange);
    });
  }

  //Get Reason for BOR initiate
  getWbaBorReasonList() {
    this.wbaservice.getWbaBorReasonList().subscribe(res => {
      this.initiateBorResponse = res.wbaReasonList;
    })
  }

  // Get Strategy And Renewal List
  getStrategyAndRenewalList() {
    this.wbaservice.getStrategyAndRenewalList().subscribe(res => {
      this.borStrategiesResponse = res.wbaBorStrategiesList;
      this.borRenewalResponse = res.wbaBorRenewalList;
    })
  }

  // BOR checkboxes
  createWbaBor(item: any) {
    this.submitted = true;
    if (this.isActive && this.borForm.get('specialServiceNotes').value == '') {
      return
    }
    if (!this.borForm.valid) {
      return;
    } else {
      if (this.borResponse == null) {
        let requestBody = {
          "id": 0,
          "wbaId": parseInt(this.wbaId),
          "outcome": "BOR",
          "reasonId": this.selectedInitiateBorResponseId,
          "strategyId": this.selectedborStrategiesResponseId,
          "renewalId": this.selectedborRenewalResponseId,
          "comments": item,
          "wbaBusinessGroup": [
            ...this.claimBusinessGroups,
            ...this.lossBusinessGroups
          ],
          "specialServiceNotes": this.borForm.get('specialServiceNotes').value
        }

        requestBody.wbaBusinessGroup.forEach((ele: any) => {
          ele.wbaId = this.wbaId;
        });
        this.wbaservice.createWbaBor(requestBody).subscribe(res => {
          if (res) {
            this._notifierService._isBORFormSaved.next(true);
            this.getWbaBorDetailList();
            this.activeModal.close();
            this.passEntry.emit('BOR');
            this.getWhiteBoardDetails(this.wbaId)
          }
        })
      }
      else {
        let requestBody = {
          "wbaId": this.wbaId,
          "reasonId": this.selectedInitiateBorResponseId,
          "strategyId": this.selectedborStrategiesResponseId,
          "renewalId": this.selectedborRenewalResponseId,
          "comments": item,
          "wbaBusinessGroup": [
            ...this.claimBusinessGroups,
            ...this.lossBusinessGroups
          ],
          "specialServiceNotes": this.borForm.get('specialServiceNotes').value
        }

        requestBody.wbaBusinessGroup.forEach((ele: any) => {
          ele.wbaId = this.wbaId;
        });
        this.wbaservice.updateWbaBorDetailList(requestBody).subscribe(res => {
          if (res) {
            this.getWbaBorDetailList();
            this.activeModal.close();
            this._notifierService._isBORFormSaved.next(true);
          }
        })
      }
    }
  }

  getWbaBorDetailList() {
    let requestBody = {
      "wbaId": this.wbaId
    }
    this.wbaservice.getWbaBorDetailList(requestBody).subscribe(res => {
      this.borResponse = res.wbaBorDetailList;
      this.getInitiateBorResponse = this.borResponse?.reason;
      this.getStrategyBorResponse = this.borResponse?.strategy;
      this.getRenewalBorResponse = this.borResponse?.renewal;
      this.getCommentsResponse = this.borResponse?.comments;

      this.borResponse?.wbaBorBusinesses.forEach((ele: any) => {
        let claimItem: any = this.claimBusinessGroups.find(cbg => cbg.name == ele.businessGroup);
        if (claimItem) {
          claimItem.businessGroup = ele.businessGroup;
        }
        if (claimItem?.businessGroup == 'Special Service account management') {
          this.isActive = true;
        }

        let lossItem = this.lossBusinessGroups.find(cbg => cbg.name == ele.businessGroup);
        if (lossItem) {
          lossItem.businessGroup = ele.businessGroup;
        }
      });
      this.setFormValue(this.borResponse);
    })
  }
  // Remove white spaces from every input fileds
  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(element.target.value, '');
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }

  //wba detail api
  getWhiteBoardDetailsFromApi(Id: any) {
    this._wbaService.getWBAById(Id).subscribe(resp => {
      if (resp && resp.whiteBoardActivityInfo) {
        this.wbaDetails = resp.whiteBoardActivityInfo;
      }
    })
  }
}